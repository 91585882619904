.setting-control-group__container {
  div.row:first-child {
    height: 38px;
    padding: 0 8px;
  }

  & > div {
    height: auto !important;
  }

  .select-value-container {
    height: 33px;
    align-items: end;

    .vochub-select-control__input input {
      height: 16px;
    }
  }

  .sms-senders__list {
    list-style: none;
    margin-bottom: 0;

    .sms-senders__list-item {
      color: #5c5c61;
      font-family: "Helvetica Neue", sans-serif;
      font-size: 14px;
      font-weight: 700;

      .settings-field__input-label {
        margin-bottom: 6px;
      }

      .settings-field__input-label + div.row {
        height: 38px;
      }

      .vochub-select-control__single-value,
      .vochub-select-control__placeholder,
      .vochub-select-control__menu {
        font-weight: 400;
      }

      &:first-child {
        margin-top: 18px;
      }

      &:not(:last-child) {
        margin-bottom: 18px;
      }
    }

    .vochub-select-control {
      width: 50%;

      .select-value-container {
        height: 33px;
        align-items: baseline;
      }
    }
  }

  .voc-setting-field__select {
    margin-bottom: 0;
  }
}

.greeting {
  position: relative;

  &__time {
    font-size: 173px;
    color: #ffffff;
    line-height: 207px;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.50);
    @media (min-width: 320px) and (max-width: 768px) {
      font-size: 104px;
    }

  }

  &__text {
    font-size: 36px;
    color: #ffffff;
    line-height: 43px;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.50);
    letter-spacing: 1px;
  }

}

.query-details-header {
  position: relative;
  padding: 24px 0;
  border-bottom: 1px solid var(--new-gray);
  margin-bottom: 12px;

  .query-details-header__button-container {

    &_right {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .button-dropdown__menu {
        right: 0 !important;
        left: auto !important;
        transform: translate3d(0, 32px, 0px) !important;

        .button-dropdown__menu-item {
          padding: 8px 15px;
        }
      }

      .btn,
      .btn-secondary,
      .btn-secondary.disabled,
      .btn-secondary:disabled {
        border: none;
        color: var(--blue);
        background-color: transparent;
        margin-right: 0;

        &:focus {
          box-shadow: none;
        }
      }
    }

  }
}



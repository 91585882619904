.pageControlsWrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 6px;
  width: 49px; // PAGE_CONTROLS width
  transform: translateX(100%);
  overflow: hidden;
  background-color: #ECF0F6;
  border-left: 2px solid #fff;
  border-radius: 5px;
  transition: transform 500ms ease-in-out;

  & .actionButton {
    padding: 0.375rem 0.25rem;
    width: 100%;
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  & .pageNumber {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 12px;
    border-radius: 5px;
    width: 100%;
    writing-mode: vertical-lr;
    max-height: 84px;

    & span {
      font-size: 14px;
      line-height: 24px;
      color: #212529;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

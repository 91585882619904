.markup-editor__pane-header {
  cursor: pointer;
  color: #555;
}

.markup-editor__tab {
  height: 300px;
}

.markup-editor__pane-icon {
  margin-right: 4px;
}

.markup-editor__tabs.collapsed {
  height: 80px;
  overflow: hidden;
}
.markup-editor__pane {
  height: 300px;
}

.virtual-source_reorder-modal {
  .modal-dialog {
    height: 100%;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding: 20px 0;
  }

  .modal-body {
    max-height: calc(100vh - 175px) !important;
  }

  .modal-content {
    height: 100%;
  }
}


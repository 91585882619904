.table-action__container.table-action__case_alerts-list {

  .table-action__case_alerts-list-icon {
    display: inline-block;
    cursor: pointer;
    margin-right: 25px;
    color: #337ab7;
    width: 14px;

    &.disabled {
      opacity: .5;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

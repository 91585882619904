.axisX {
  bottom: 25px;
  left: 50%;
  transform: translate(-50%, 50%);
}

.axisLeftY {
  left: 0;
}

.axisRightY {
  right: 0;
}

.axisLeftY,
.axisRightY {
  writing-mode: vertical-lr;
  transform: translate(0%, calc(-50% - 30px)) rotate(180deg);
  top: 50%;
}

.axisX,
.axisLeftY,
.axisRightY {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;

  &:hover {
    svg {
      opacity: 1;
    }
  }

  .axisWrapper {
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    max-width: 100%;
    max-height: 100%;

    span {
      font-size: 12px;
    }
  }

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    margin: 0 12px;
  }

  svg {
    cursor: pointer;
    opacity: 0;
  }
}

.container {
  display: flex;
  flex-direction: row;
  align-items: start;

  & .axis {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    max-width: 100%;
    max-height: 100%;

    & .axisInner {
      max-width: 100%;
      max-height: 100%;
      cursor: pointer;

      &:hover {
        svg {
          opacity: 1;
        }
      }

      span {
        font-size: 12px;
        display: inline-block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      // y axis
      &.count_axis,
      &.score_axis {
        height: 100%;

        & span {
          height: 100%;
        }
      }

      // x axis
      &.group_axis {
        width: 100%;

        & span {
          width: 100%;
        }
      }

      svg {
        opacity: 0;
      }
    }
  }

  & .axisLeftYColumn,
  & .axisRightYColumn {
    writing-mode: vertical-lr;
    transform: rotate(180deg);
  }

  & .axisLeftYColumn {
    padding: 0 12px 0 6px;
  }

  & .axisRightYColumn {
    padding: 0 6px 0 12px;
  }

  & .axisXColumn {
    padding-top: 12px;
  }

  .chartColumn {
    display: flex;
    flex-direction: column;
    align-items: end;
  }

  & .axisRightYColumn {

  }
}

.insightContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
  background: #F4F4F4;
  overflow: hidden;
  position: relative;

  & .warningBlock.withRightOffset {
    margin-right: 12px;
  }

  & .insightContent {
    overflow-y: auto;
    margin-bottom: 0;
    padding: 0 12px 18px;
    flex: 1;

    /* COLLAPSE SECTION */
    & .collapseSection {
      background-color: inherit;

      &.withError {
        & :global(.collapse__header-title-addon) {
          svg path {
            fill: var(--danger);
          }
        }
      }

      &.hideHeader {
        & :global(.card-header) {
          display: none;
        }

        & :global(.card-body) {
          padding-top: 0;
        }
      }

      &:not(:last-child) {
        margin-bottom: 12px;
      }

      & .emptyData {
        display: flex;

        &.centered {
          justify-content: center;
        }

        & .emptyDataTitle {
          margin-bottom: 0;
          color: #53565A;
        }

        & .emptyDataInfo {
          display: flex;
          align-items: center;
          margin-left: 6px;

          & svg path {
            fill: #53565A;
          }
        }
      }

      & .content {
        & .timestamp {
          position: absolute;
          bottom: 0;
          right: 0;
          font-size: 12px;
          color: var(--secondary);
        }
      }

      & :global(.card-header) {
        background-color: var(--new-gray);
        border-radius: 4px;

        & :global(.collapse__header) {
          display: flex;
          flex: 1;
          justify-content: space-between;
          align-items: center;
          max-width: calc(100% - 22px); // arrow width equal to 22px
        }
      }

      & .insightPartLoader {
        & path {
          fill: var(--primary);
        }
      }

      & .contentMark {
        width: 0.5em;

        & path {
          fill: var(--gray);
        }

        &.exist {
          & path {
            fill: var(--green);
          }
        }
      }
    }
  }

  &:hover {
    & .copyButton {
      opacity: 0.5;
    }
  }

  & .copyButton {
    position: absolute;
    bottom: 18px;
    right: 20px;
    height: 38px;
    width: 38px;
    background-color: #337AB7;
    color: #FFFFFF;
    border-radius: 50%;
    border: none;
    opacity: 0;
    flex-shrink: 0;
    transition: .25s;

    &:hover {
      opacity: 1;
    }
  }
}

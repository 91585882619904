.setting-dynamic-group__container {
  padding: 18px 10px;
  flex: 100%;

  > .row {
    margin-bottom: 20px;
  }

  .voc-setting-field {
    min-height: 36px;
    padding: 0;
  }

  .app-form-control__container.with-error {
    height: auto;
  }

  .vochub-select-control__control:hover,
  .vochub-select-control__control,
  .app-form-control {
    min-height: 36px;
    border: 1px solid var(--gray);
  }

  .vochub-select-control {
    margin: 0;
  }

  .setting-control-group_button {
    height: 36px;
    width: 36px;
    border-radius: 4px;
    border-color: transparent;
    background-color: transparent;
    padding: 7px;
    text-align: center;
    cursor: pointer;

    img {
      width: 13px;
    }
  }

  .setting-dynamic-group_button {
    height: 36px;
    border-radius: 4px;
    border: 1px solid #007aff;
    background-color: #ffffff;
    padding: 7px 27px 5px 43px;
    position: relative;
    margin-bottom: 17px;
    cursor: pointer;

    img {
      position: absolute;
      width: 13px;
      top: 10px;
      left: 20px;
    }
  }

  .app-form-control__error {
    right: 14px;
    bottom: -14px;
  }

  .app-form-control__container {
    position: relative;
  }

  .label-star {
    color: var(--red);
  }

  .row-label__dynamic-group {
    margin-bottom: 10px;
  }
}

.tableChart {
  max-height: 252px;
  display: flex;
  flex-direction: column;
  min-height: 100%;

  &:hover {
    .copyButton {
      opacity: .5;
      transition: .25s;
    }
  }
}

.copyButton {
  position: sticky;
  margin-bottom: 20px;
  right: 20px;
  margin-left: auto;
  background-color: #337AB7;
  color: #FFFFFF;
  border-radius: 50%;
  cursor: pointer;
  border: none;
  padding: 0;
  height: 38px;
  width: 38px;
  opacity: 0;
  transition: .25s;
  flex-shrink: 0;

  &:hover {
    opacity: 1 !important;
    transition: .25s;
  }
}

@mixin toolbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  margin: 0;

  .collapse-section-toolbar__button {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--primary);
    cursor: pointer;
    margin: 0 7px;

    .label {
      margin-left: 4px;
    }
  }
}

.mine-filter-collapse.collapse-section__info-section.card {
  border-bottom: 1px solid #E5E5E5;

  &:last-child {
    border-bottom: none;
  }

  .card-body {
    padding: 0;
  }

  .card-header {
    padding: 0;
    background-color: transparent;

    h5 {
      .collapse__header-title {
        margin-left: 17px;
      }

      img {
        left: 0;
        top: 18px;
        height: 7px;
        width: 12px;
      }
    }
  }

  .collapse__header {
    padding: 12px 5px;
    font-weight: normal;
    color: #53565A;
    position: relative;
  }

  .collapse-filter__body {
    margin-left: 24px;
    margin-bottom: 15px;

    .collapse-attributes__place,
    .collapse-advanced__place {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      .collapse__attribute {
        display: flex;
        flex-direction: row;

        .collapse-attributes__values {
          padding-left: 8px;
          word-break: break-all;

          .blue-label {
            color: #A0A0A2;
          }

          span {
            margin-right: 8px;
            opacity: .7;
            overflow: auto;
            word-break: break-all;
          }
        }
      }
    }

    .collapse__toolbar {
      position: absolute;
      right: 0;
      margin-top: auto;
      margin-bottom: auto;
      top: 0;
      bottom: 0;
      @include toolbar;
    }
  }

  .collapse-tags__place {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
  }

  .collapse-filter-condition {
    position: relative;
    padding-right: 56px;

    .icon {
      opacity: 0;
    }

    .label {
      display: none;
    }

    &:hover {
      .icon {
        opacity: 1;
      }
    }
  }

  .collapse-filter-condition__info {
    position: absolute;
    text-align: center;
    padding-top: 10px;
    top: 0;
    left: 0;
    color: #bf1f25;
    background: rgba(255, 255, 255, .7);
    width: 80%;
    height: 100%;
    cursor: pointer;
  }

  .collapse-advanced__place {
    .collapse-filter-condition__info {
      top: -8px;
      height: 30px;
    }
  }
}

.collapse-section-header__toolbar {
  display: flex;
  flex-direction: row;
  align-items: center;

  .panel {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;
    margin: 0;

    .radio_input {
      cursor: pointer;
      position: relative;
      margin: 0 0.3rem 0 0;
    }

    .radio_label {
      display: flex;
      flex-direction: row;
      align-items: center;
      align-self: center;
      margin: 0 8px;
      cursor: pointer;
      font-size: 13px;
    }

    .button_handler {
      @include toolbar;
    }
  }
}

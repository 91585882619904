.tagsWrapper {
  width: auto;
  display: flex;

  .tagWrapper {
    margin-right: 5px;
    padding: 4px 8px;
    background: #FDD37D;
    display: flex;
    border-radius: 2px;
    width: auto;
  }

  :global(.table-tag__label) {
    padding: 0;
  }
}

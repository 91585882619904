.vochub-select-control {
  margin-bottom: 15px;
}

.vochub-select-control.is-focused:not(.is-open) > .Select-control {
  box-shadow: none;
  border-color: #d9d9d9 #ccc #b3b3b3;
  border: 1px solid #ccc;
}

.select-with-label {
  .component-value-label_container {
    width: calc(100% - 60px) !important;
  }
}

.Select-option {
  padding: 4px 10px;
}

div.Select-menu-outer {
  z-index: 9;
  overflow-wrap: break-word;
}

.col-md-3.select-control-label {
  text-align: right;
  vertical-align: middle;
  padding-left: 10px;
  padding-right: 10px;
  min-height: 34px;
  line-height: 34px;
}

.col-md-3.select-control-label label {
  line-height: normal;
}

.select-control-label {
  & .select-control-label-text {
    line-height: 1;
  }

  & .star {
    line-height: 1;
    color: var(--red);
  }
}

.vochub-select-control {
  position: relative;

  .vochub-select-control__single-value {
    color: #636369;
  }

  .Select-multi-value-wrapper {
    position: relative;
    display: block;
    z-index: 1;

    &::after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      top: 0;
      width: 25px;
      cursor: pointer;
    }
  }
}


.vochub-select-control__control--is-disabled {
  background-color: rgba(0,0,0,0.08) !important;

  .vochub-select-control__single-value {
    color: #495057;
  }

  //.vochub-select-control__indicators {
  //  opacity: 0;
  //}
}

.vochub-select-control.is-open {
  .Select-multi-value-wrapper {

    &::after {
      z-index: -1;
    }
  }
}

.vochub-select-control.multiselect {
  .Select-value {
    color: #333;
    font-size: 14px;
    margin-top: 3px;
    border-color: #ccc;
  }

  .Select-value-icon {
    font-weight: 700;
    padding: 2px 8px;
    font-size: 20px;
    line-height: 18px;
  }

  .Select-value-label {
    background: #fff;
    border-color: #ccc;
    padding: 3px 8px;
  }

  .Select-placeholder {
    margin-top: 2px;
  }
}

.vochub-select-control__menu {
  .vochub-select-control__option {
    overflow-wrap: break-word;
    color: #636369;

    &:hover {
      background: #DEEBFF;
    }

    &--is-focused {
      background: none;
    }

    &--is-selected {
      background-color: #DEEBFF;
    }

    &.Select-menu {
      &__small {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }
}

.vochub-select-control {
  &.no-margin {
    margin: 0;
  }

  .vochub-select-control__indicators {
    svg {
      width: 16px;
      height: 16px;
    }

    .vochub-select-control__clear-indicator {
      padding: 4px;
    }

    .vochub-select-control__dropdown-indicator {
      padding: 9px;
    }
  }

  .vochub-select-control__control {
    background-color: #fff;
    border: 1px solid #dddddd;

    &.vochub-select-control__control--is-focused {
      border-color: #66afe9;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
    }
  }

  .vochub-select-control__multi-value {
    color: #333;
    font-size: 14px;
    margin-top: 3px;
    background-color: rgba(0, 126, 255, 0.08);
    border-radius: 2px;
    border: 1px solid rgba(0, 126, 255, 0.24);

    & .vochub-select-control__multi-value__remove {
      cursor: pointer;
      border-bottom-left-radius: 2px;
      border-top-left-radius: 2px;
      background-color: rgba(0, 126, 255, 0.08);
      font-weight: bold;
      padding: 2px 8px;
      font-size: 20px;
      line-height: 18px;

      &:hover {
        color: var(--red);
        background: #fff;
      }
    }

    & .vochub-select-control__multi-value__label {
      background: #fff;
      border-color: #ccc;
      padding: 3px 8px;
      white-space: normal;
    }
  }
}

.custom-option-dot {
  position: relative;
  padding-left: 16px;

  &:before {
    content: '';
    position: absolute;
    display: block;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    top: 50%;
    left: 7px;
    transform: translate(-7px, -50%);
  }

  &_active:before {
    background: #5cb85c;
  }

  &_paused:before {
    background: #f0ad4e;
  }

  &_stopped:before {
    background: #5bc0de;
  }

  &_deleted:before {
    background: #d9534f;
  }
}

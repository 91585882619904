/*
 * @NOTE:
 *
 * This styles related from 'scenes/Surveys/SurveysList.css'
 *
 */

.cjm_button__toggle {
  width: 264px;
  margin: 2px;
  height: 34px;
  text-align: left !important;

  &::after {
    vertical-align: middle;
    width: 0;
    height: 0;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 8px;
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
  }
}

.CJMView {
  $lineSize: 72px;
  $separatorColor: #ddd;
  $border: 1px solid rgba(0,0,0,.125);

  .page__wrapper.card {
    border-right: none;
    border-left: none;
  }

  .surveys-list-modern,
  .panel__header--buttons {
    border-left: $border;
    border-right: $border;
  }

  .panel__header--buttons {
    border-bottom: none;
  }

  .surveys-list-modern.noselect {
    border: none;
  }

  .btn-copySurvey {
    // This action must be hidden for CJMView (and show for the SurveyList)
    display: none !important;
  }

  .panel {
    margin: 0;
    border-radius: 4px 4px 0 0;
    .panel-heading {
      border: none;
      h5.pull-left {
        padding-right: 15px;
      }
    }
    .panel-body {
      padding: 0;
    }
  }

  .touchpoints-list {

    h4 {
      line-height: 50px;
      margin: 0;
    }


    .panel {
      border: none;
      border-radius: 0;
      background: none;
      transition: all .3s ease-out;

      .toggle-panel-icon {
        position: relative;
        bottom: -2px;
        color: #888;
        font-size: 16px;
        margin-right: 5px;
        float: left;
      }

      .toggle-panel-title {
        max-width: 400px;
        font-size: 18px;
        display: table-cell;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }


      .panel-heading {
        padding: 10px 15px;
        height: $lineSize;
        background: #f5f5f5;
        border: 1px solid $separatorColor;
        border-bottom: 0;

        &:hover {
          box-shadow: inset 4px 4px 5px -2px rgba(255, 255, 255, 1);
          background: rgba(245, 245, 245, 0.85);
          h4 .toggle-panel-title {
            color: #333;
          }
        }
        &:active {
          box-shadow: inset 2px 5px 5px -2px rgba(0, 0, 0, 0.1);
          background: #ececec;
        }
      }
      .panel-body {
        border: 1px solid $separatorColor;
        border-top: 0;
        border-bottom: 0;
      }
      &:last-child {
        .panel-heading {
          border-bottom: 1px solid $separatorColor;
        }
      }
    }
  }

  .panel-body {
    overflow-x: hidden;
    table tbody {
      td {
        height: $lineSize;
      }
    }
  }

  .panel-collapse {
    padding-right: 0;
    padding-left: 0;
  }

  .status-counter-wrapper {
    $borderSize: 3px;
    padding-top: 7px;
    margin-left: 17px;

    .status-counter {
      position: relative;
      display: inline-block;
      text-align: center;
      background: #fff;
      width: 44px;
      font-size: 17px;
      border-radius: 50%;
      padding: 7px 0 6px;
      margin-right: 5px;
      border: 3px solid #fff;
      font-weight: bold;
      top: -3px;

      &-active {
        $color: #5cb85c;
        $colorText: #3c793c;
        color: $colorText;
        border: $borderSize solid $color;
      }
      &-paused {
        $color: #f0ad4e;
        $colorText: #ae7429;
        color: $colorText;
        border: $borderSize solid $color;
      }
      &-deleted {
        $color: #d9534f;
        $colorText: #9a3b38;
        color: $colorText;
        border: $borderSize solid $color;
      }
      &-stopped {
        $color: #5bc0de;
        $colorText: #3a748a;
        color: $colorText;
        border: $borderSize solid $color;
      }
    }
  }

  .touchpoint-actions {
    margin-top: 5px;

    > button, a {
      padding: 0;
      width: 35px;
      height: 35px;
      line-height: 35px;
    }

    .btn {
      margin-left: 6px;
      float: right;
    }
    .touchpoint-actions-add {
      .glyphicon {
        font-size: 15px;
        color: #008000;
      }
    }
    .touchpoint-actions-link {
      .glyphicon {
        color: #0020be;
      }
      &.disabled {
        .glyphicon {
          color: #999;
        }
      }
    }
    .touchpoint-actions-settings {
      // ...
    }
  }

  .project-empty {
    color: #81411a;
    border: $border;
    height: 72px;
    padding: 25px;
    font-size: 16px;
  }

  .touchpoint-empty,
  .touchpoint-loader {
    height: 72px;
    font-size: 16px;
    padding: 26px;
  }

  .touchpoint-empty {
    background: #fdffe8;
    box-shadow: inset 0 3px 10px rgba(0, 0, 0, 0.1);
    color: #81411a;
    border-top: 1px solid #e3dacd;
  }

  .touchpoint-loader {
    overflow: hidden;
    font-weight: bold;
    color: #666;
    background: #fff;
    box-shadow: inset 0 3px 9px rgba(0,0,0,0.1);
  }

  .openManageProjects {
    margin: 12px;
    top: 10px;
    cursor: pointer;
  }

  .selectedProjectWrapper {
    float: left;

    ul.dropdown-menu {
      overflow-y: auto;
      max-height: 67vh;
    }
    .wrap {
      max-width: 220px;
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .selectedProject {
      position: relative;
      display: inline-block;
      padding: 5px 12px;
      margin-top: 2px;
      max-width: 250px;
      width: 250px;
      text-align: left;
      font-size: 14px;
      font-weight: 400;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      .caret {
        position: absolute;
        top: 14px;
        right: 5px;
      }
    }
  }

  .project-dropdown-loader {
    padding: 9px;
  }

  .btn.btn-default {
    background: #fff;
    border: 1px solid #ccc;
  }

}

.cjm__dropdown-menu {
  max-height: 290px;
  overflow-y: auto;
  width: 100%;
}

.cjm__dropdown-menu--item {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.cjm__table-header {
  z-index: 1;
  background: #fff;

  &--sticky {
    position: relative;
    background-color: #fff !important;

    thead {
      border-bottom: 1px solid rgba(0,0,0,.125);
    }
  }
}

.touchpoint-actions {
  user-select: none;
}

.touchpoint-actions-add,
.touchpoint-actions-setting,
.touchpoint-actions-link {
  &.disabled {
    color: var(--gray);
    opacity: .5;
    pointer-events: none;

    svg {
      color: var(--gray);
    }
  }
}

.table-coll__icon-primary {
  cursor: pointer;
}

.shared-popover__body.popover-body {
  padding: 16px;
  overflow-y: auto;
  max-height: 340px;

  .shared-popover__wrapper {
    max-width: 290px;
    overflow: hidden;

    .loader {
      margin: 0 auto;
    }
  }

  .shared-popover-role__item {
    display: flex;
    flex-direction: row;
    padding-bottom: 16px;

    &:last-child {
      padding-bottom: 0;
    }

    .shared-popover-role__column {
      display: flex;
      flex-direction: column;
      align-items: self-start;
      align-self: center;
      margin-block-start: 0;
      margin-block-end: 0;
      padding-inline-start: 0;
      list-style-type: none;
      font-size: 16px;
      color: #000000;

      li {
        display: flex;

        div {
          width: 100%;
          display: inline-block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        flex-direction: row;
        align-items: center;

        svg {
          color: #53565A;
          height: 12px;
          width: 24px;
        }
      }
    }

    .shared-popover-role-column_title {
      color: #53565A;
      width: 75px;
      align-self: start;
    }
  }
}

.nameFormatterWrapper {
  display: flex;
  flex-direction: column;

  .description {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-transform: lowercase;
    cursor: pointer;
  }

  .name {
    display: flex;
    width: fit-content;
  }
}

$fixed-table-header-height: 106px;
$fixed-table-col-width: 250px;
$sidebar-width: 335px;

.virtual-source-form {
  position: relative;
  padding-top: 40px;
  margin-bottom: 20px;
  height: calc(100vh - 260px);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.fixed-table {
  width: 100%;
  height: 100%;

  .Select-clear {
    vertical-align: text-top;
  }
}

.fixed-table__scrollable-sidebar {
  height: calc(100% - #{$fixed-table-header-height});
  top: $fixed-table-header-height;
}

.fixed-table__sidebar {
  width: $sidebar-width;
  height: 100%;
  z-index: 1;
  background: #fff;
}

.fixed-table__sidebar .fixed-table__col {
  width: 100%;
}

.fixed-table__body {
  position: relative;
  margin-left: $sidebar-width;
  max-width: calc(100% - #{$fixed-table-col-width});
  height: 100%;
}

.fixed-table__body .fixed-table__list {
  top: $fixed-table-header-height;
  height: calc(100% - #{$fixed-table-header-height});
  overflow: auto;
}

.fixed-table__list {
  position: relative;
  height: 100%;
}

.fixed-table__list--fixed {
  height: auto;
  overflow: visible;
}

.fixed-table__th {
  width: $fixed-table-col-width;
  padding: 10px;
  border-right: 1px solid #fff;
  border-bottom: 1px solid #44b1d2;
  background: #337ab7;
  height: $fixed-table-header-height;
  border-radius: 4px;

  .Select-input input {
    max-width: 165px;
  }
}

.fixed-table__row {
  width: 100%;
}

.fixed-table__col {
  width: $fixed-table-col-width;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-bottom: 1px solid #ddd;
  padding: 5px 10px 5px 10px;
  border-right: 1px solid #ddd;
  border-radius: 4px;

  .Select-input input {
    max-width: 165px;
  }

  &--sidebar {
    background: #f5f5f5;
    width: 100%;

    .Select-input input {
      max-width: 200px;
    }
  }

  &--invalid {
    .vochub-select-control__placeholder {
      color: #dd5352;
    }
  }
}

.virtual-sources-form__header-hint {
  color: #dd5352;
}

.virtual-sources-form__header-input {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  height: 38px;

  &.invalid {
    color: #dd5352;

    &::placeholder {
      color: #dd5352;
    }
  }
}

.virtual-sources-form__header-select {
  display: inline-block;
  vertical-align: top;
  margin-top: 10px;
  width: 100%;

  &.invalid {
    .vochub-select-control__placeholder {
      color: #dd5352;
    }
  }
}

.virtual-sources-form__button-text {
  display: inline-block;
  vertical-align: middle;
}

.virtual-sources-form__button-icon {
  display: inline-block;
  vertical-align: text-bottom;
  font-size: 18px;
}

.virtual-table__header .virtual-sources-form__button {
  margin-top: 10px;
  padding: .62rem .75rem .45rem .75rem;

  & > .virtual-sources-form__button-text {
    margin-left: 5px;
  }
}

.virtual-sources-form__button--last {
  & > .virtual-sources-form__button-text {
    margin-right: 5px;
  }
}

.fixed-table__th--first {
  width: $sidebar-width;

  .form-control {
    height: 38px;
  }

  .svg-inline--fa {
    margin-top: -3px;
  }

  input::placeholder {
    color: #d9534f;
  }
}

#popover-contained {
  z-index: 1050;
  min-width: 335px;
  background: #f7f7f7;

  .arrow:after {
    border-bottom-color: #f7f7f7;
  }

  .popover__input-container {
    width: 210px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 20px;
  }

  .popover__input--invalid {
    border-color: #dd5352;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgb(221, 83, 82);
  }

  .popover__input-description {
    display: block;
    font-size: 13px;
    margin-top: 4px;
    text-align: left;

    &.invalid {
      color: #d9534f;
    }
  }

  .popover__input-controls {
    display: inline-block;
    vertical-align: top;
    font-size: 18px;
  }

  .popover__input-button {
    margin-right: 5px;
    height: 34px;
    width: 34px;
    box-sizing: border-box;
    text-align: center;
    padding: 0;
    line-height: 34px;

    &:focus {
      color: #333;
      background-color: #e6e6e6;
      border-color: #8c8c8c;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.fixed-table__col-icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  color: #fff;
  background: #d9534f;
  text-align: center;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  margin-right: 5px;
  cursor: pointer;
  line-height: 27px;

  &--fixed {
    position: absolute;
    right: -4px;
    top: -4px;
    z-index: 1;
  }

  &.vs-container-cross {
    top: 1px;
    left: -2px;
  }

}

.fixed-table__col-input {
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 30px);
}

.select_option-groups {
  .Select-option {
    padding-left: 2em;
  }

  .Select-option.is-disabled {
    cursor: default;
    padding-left: 1em;
    font-weight: bold;
  }
}

.Select-value {
  width: calc(100% - 80px);
}

.Select-value__title--inline {
  display: contents;
  vertical-align: middle;
  width: calc(100% - 10px);
}

.Select-value__title--labeled {
  width: calc(100% - 60px);
}

.shadowed-popover.popover {
  max-width: min-content;
  border: 1px solid rgba(0, 0, 0, .2);
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
}

.popover-inner {
  border-radius: 6px;
}

.bs-popover-auto[x-placement^=bottom] .arrow::after,
.bs-popover-bottom .arrow::after {
  border-bottom-color: #f7f7f7;
}

.popover-content {
  padding: 9px 14px;

  .outside-alerter {
    padding: 0;
    margin: 0;
  }
}

.vochub-select-control__menu {

  .vochub-select-control__menu-list {
    padding-bottom: 0;
    padding-top: 0;
  }
}

.multi_grid__wrapper-left {
  & > div > div {
    &:last-child {
      &:not([class]) {
        display: none;
      }
    }
  }
}

.col-md-3.text-field-control-label {
  text-align: right;
  vertical-align: middle;
  padding-left: 10px;
  padding-right: 10px;
  min-height: 34px;
  line-height: 34px;
}

.col-md-3.text-field-control-label label {
  line-height: normal;
}

.question-form__validation-error {
  display: inline-block;
  color: #d9534f;
  font-weight: bold;
  line-height: normal;
  padding: 0;
  margin: 5px 0 0 0;
  font-size: 13px;
}

.question__section {
  margin-bottom: 20px;
}

.question__section-body {
  position: relative;
  transition: background .5s, border .5s;

  .form-group:last-child {
    margin-bottom: 0;
  }
}

.question__section--loading {
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    background: #f5f5f5;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 5;
  }

  &::before {
    position: absolute;
    top: -30%;
    bottom: 0;
    left: -75%;
    z-index: 6;
    display: block;
    content: '';
    width: 50%;
    height: 160%;
    animation: languagesLoader 1s infinite;
    background: -moz-radial-gradient(center, ellipse cover, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%); /* FF3.6-15 */
    background: -webkit-radial-gradient(center, ellipse cover, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: radial-gradient(ellipse at center, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  }
}

.question__form {
  padding-top: 10px;

  .question__controls {
    justify-content: center;
    margin-bottom: 20px;

    div {
      display: flex;
      margin-left: 0 !important;
      max-width: unset;

      button {
        min-width: 100px;
      }
    }
  }

  .text-field-control-label {
    line-height: 36px;
    flex: 0 0 28%;
    max-width: 28%;
    text-align: left !important;
  }
}

.questionFlowConfirmationParagraph {
  hyphens: manual;
}

.blacklist-form__create {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;

  > span {
    padding: 0 10px;
  }
}

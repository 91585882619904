.statistic_result__block {
  width: 168px;
  height: 113px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 4px;
  padding: 5px;
  border: 1px solid #d1d1d1;
  text-align: center;
  margin-bottom: 20px;

  .statistic_result__block-value {
    text-align: center;
    color: #000000;
    font-size: 28px;
    letter-spacing: 0.88px;
    line-height: 40px;
    font-weight: bold;
  }

  .statistic_result__block-label {
    display: inline-block;
    color: #8e8e93;
    font-size: 14px;
    line-height: 18px;
    position: relative;
    text-align: center;

    .statistic_result__block-label_information {
      display: inline-block;
      margin-left: 5px;

      img {
        width: 12px;
        height: 12px;
      }
    }
  }
}

.switch {
  position: relative;
  border-radius: 20px;
  transition: .2s ease;
  display: inline-block;
  height: 30px;
  width: 51px;
  box-shadow: inset 0 0 0 2px #e4e4e4;
  cursor: pointer;
  margin-bottom: 0;

  &-wrap {
    position: relative;
    display: inline-block;
    vertical-align: middle;

    &.disabled {

      .switch {
        background: #ebebeb;
      }
    }
  }

  &::before {
    content: "";
    position: absolute;
    display: block;
    height: 30px;
    width: 30px;
    top: 0;
    left: 0;
    border-radius: 15px;
    background: rgba(76, 217, 100, 0);
    transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
  }

  &::after {
    content: "";
    position: absolute;
    display: block;
    height: 28px;
    width: 28px;
    top: 50%;
    margin-top: -14px;
    left: 1px;
    border-radius: 60px;
    background: #fff;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 0 0 rgba(0, 0, 0, 0.04),
    0 4px 9px rgba(0, 0, 0, 0.13), 0 3px 3px rgba(0, 0, 0, 0.05);
    transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
  }

  &__small {
    width: 41px;
    height: 24px;

    &::before {
      width: 20px;
      height: 24px;
    }

    &::after {
      width: 22px;
      height: 22px;
      top: 63%;
    }
  }

  &__checkbox {
    position: absolute;
    visibility: hidden;
  }

  &__checkbox:checked + .switch::before {
    width: 41px;
    background: #007aff;
    transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
  }

  &__checkbox:checked + .switch::after {
    left: 22px;
  }

  &__checkbox:checked + .switch__small::before {
    //width: 35.7px;
    background: #007aff;
    transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
  }

  &__checkbox:checked + .switch__small::after {
    left: 18px;
  }
}

.switchWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

  :global(.switch-wrap) {
    display: flex;
  }
}

.emptyLabel {
  margin-bottom: 0;
  text-align: center;
  color: #A0A0A2;
}

.selectWrapper {
  margin-top: 14px;
}

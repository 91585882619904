.staticAreaWrapper {
  height: inherit;
  overflow: hidden;

  .iframe {
    border: none;
    width: 100%;
    height: 100%;
  }

  &.staticEdit {
    .iframe {
      pointer-events: none;
    }
  }

  :global(.outside-alerter) {
    height: inherit;
  }

  :global(.nav-tabs){
    position: fixed;
    top: -74px;
    border: 1px solid #E5E5E5;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  :global(.tox-editor-header) {
    position: fixed;
    top: -38px;
    border: 1px solid #E5E5E5;
    border-bottom: none;
    left: 1px;
    width: max-content;
  }

  :global(.tox-tinymce) {
    border: none !important;
  }

  :global(.markup-editor__tabs) {
    height: inherit;
  }

  :global(.layout-editor__tab-size) {
    height: inherit;
  }

  :global(.tox-statusbar) {
    padding: 0 !important;
    padding-right: 20px !important;

    :global(.tox-statusbar__wordcount) {
      display: none !important;
    }

    :global(.tox-statusbar__branding) {
      a {
        color: white;
        max-width: 28px;
        display: flex;
        cursor: pointer;
        margin-right: -20px;

        &:hover {
          &:before {
            text-decoration: underline;
          }
        }

        &:before {
          content: 'Tiny';
          color: rgba(0, 0, 0, 0.4);
          pointer-events: none;
          position: absolute;
          top: 3.5px;
          cursor: pointer;
          font-size: 9px;
          font-weight: 500;
        }
      }
    }
  }
}

:global(.tox-pop__dialog) {
  width: max-content !important;
}

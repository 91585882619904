.pieChart {
  display: flex;
  flex: 1;
  overflow: hidden;
  flex-direction: column;
  margin: 1rem 1rem 0 1rem;

  :global(.arc) {
    transition: 0.3s;

    & :global(.transparentLabel) {
      visibility: hidden;
      pointer-events: none;
    }

    & :global(.segmentShapeCenter) {
      fill: transparent;
      text-anchor: middle;
      width: 1px;
      height: 1px;
    }
  }

  :global(.arc.interactive) {
    cursor: pointer;

    &:focus-visible {
      outline: auto 2px var(--primary-hover) !important;
    }
  }

  :global(.label-line) {
    stroke-width: 1;
    stroke: #393939;
  }

  :global(.label-text) {
    font-size: 16px;
    alignment-baseline: middle;
    fill: #393939;
  }

  :global(.transparent) {
    opacity: 0.3;
    transition: 0.3s;
  }

  :global(.mathFunctionText) {
    fill: white;
    stroke: #000000;
    stroke-width: 1.2px;
    pointer-events: none;
  }

  :global(.textWrapper) {
    user-select: none;
    pointer-events: none;

    & tspan {
      font-weight: bold;
    }
  }

  :global(.centerCircle) {
    fill: transparent;
    cursor: pointer;
  }
}

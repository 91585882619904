.mine-table__btn-controls-wrapper {
  display: flex;

  .mine-table__btn-dictionary {
    display: inherit;
    align-items: center;

    background: transparent;
    border: transparent;
    color: var(--primary);
    padding: 0.375rem 0.75rem;

    .dictionaries-icon {
      color: var(--primary);
    }

    &:hover {
      opacity: .8;
      color: var(--primary);
      background: transparent;
    }
  }
}

.queries-table-filter {
  width: 500px;

  button {
    height: 38px;
  }

  &__label {
    padding: 4px 0 0;
  }

  .vochub-select-control {
    margin: 0;
  }
}

.queries-table-filter__preloader {
  background: #fff;
  z-index: 9999;
  overflow: hidden;

  .loader {
    margin: 0 auto;
  }

  .loader {
    font-size: 7px;
    width: 8em;
    height: 8em;
  }

  .loader:after {
    left: 4.1em;
  }

  .loader:before {
    left: -0.6em;
    top: -0.5em;
  }
}

.select-popover {
  max-width: 600px !important;
  width: 600px !important;
  height: 125px;

  @media (max-width: 829px) {
    max-width: 100% !important;
    width: 100% !important;

    .voc-select-control-wrap__btn {
      margin: 0;
      button {
        width: 100%;
      }
    }
  }
}

.select-popover-outer {
  .popover {
    @media (max-width: 575px) {
      width: calc(100% - 25px) !important;
    }
  }
}

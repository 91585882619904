.table-action__customers {
  color: #337ab7;
  display: flex;
  justify-content: center;

  &.disabled {
    color: var(--gray);
    opacity: .5;
    pointer-events: none;

    svg {
      color: var(--gray);
    }
  }

  span {
    padding: 0 3px;
    margin-right: 8px;
  }
}

.dashBoardsSliderWrapper {
  display: flex;
  position: relative;
  overflow: auto;
  overflow-y: hidden;

  @media print {
    display: none;
  }

  .loader {
    position: relative;
    width: 100%;
    height: 108px;
  }

  &.dashBoardsSliderWrapperIsFluidContainer {
    width: calc(100vw - 15px); // 100vw - right inner offset
    margin-left: -75px; // sidebar width + left inner offset
    padding: 0 15px 0 75px;
  }

  &.dashboardsSliderHasItems {
    margin-top: 10px;
  }

  .buttonWrapper {
    position: relative;

    .dashboardIcon {
      position: absolute;
      top: 10.5px;
      right: 18px;
      opacity: 0;
      transition: .25s;

      path {
        fill: #5a5a5a;
      }

      &.dashboardIconActive {
        opacity: 1;

        path {
          fill: orange;
        }

        &.dashboardIconDisabled {
          opacity: 0.6;
        }
      }
    }

    &:hover {
      .dashboardIcon {
        opacity: 0.8;
        transition: .25s;
        &:hover {
          cursor: pointer;
          opacity: 1;
          transition: .25s;
        }

        &.dashboardIconDisabled {
          opacity: 0.6;
          pointer-events: none;
        }
      }
    }

    &:last-child {
      .dashboardIcon {
        right: 8px;
      }

      .dashboardItem {
        margin-right: 0;
      }
    }
  }

  .dashboardItem {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: #ECF0F6;
    color: black;
    transition: .25s;
    font-size: 14px;
    line-height: 24px;
    border-radius: 8px;
    margin-right: 10px;
    padding: 0 25px;
    height: 35px;
    min-width: 140px;
    max-width: 140px;
    border: none;

    &.dashboardItemActive {
      background-color: #D6E1F1;
      transition: .25s;
    }

    span {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &:hover {
      opacity: .8;
      transition: .25s;
    }
  }
}

.layouts-modal__preloader {
  height: 476px;

  .loader {
    margin-top: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
}

.layouts-modal__validation__text {
  display: inline-block;
  color: var(--danger);
  padding-right: 10px;
}

.layout-modal {
  display: flex !important;
  align-items: center;
}

.fixed__select-value {
  width: calc(100% - 59px);
  display: flex;
  align-items: center;
}

.fixed__select-value-title {
  flex-grow: 1;
  width: 80%;
}

.fixed__select-value-label {
  padding: .2em .6em .3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  border-radius: .25em;
  margin-left: 2px;
  height: 15px;
  background-color: #5bc0de;
}

.virtual-source-tooltip__container {
  width: inherit;
}

.Select-options__title {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  margin-bottom: 0;
  text-overflow: ellipsis;
}

.cell__autofill {
  background-color: #E5E5E5 !important;
}

details.upload-to-delete-details {
  margin-bottom: 5px;
}

details.upload-to-delete-details ul li {
  word-break: break-all;
}

details.upload-to-delete-details summary {
  cursor: pointer;
  display: inline;
}

.uploads-list {
  justify-content: center;

  .table-main-action-btn-wrapper {
    color: var(--primary);
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    svg {
      pointer-events: none;
    }
  }
}

.uploads-list-popover-body {
  display: flex;
  flex-direction: column;

  .table-action__btn-share {
    margin: 0;
  }

  .upload-action-link {
    width: 100%;
    text-align: left;
    display: flex;
    align-items: center;
    height: 23px;
  }
}

.uploadListPopover {
  .table-action-btn-wrapper, .table-action-btn-wrapper button {
    text-align: left;
  }
  svg {
    margin-right: 5px;
  }
  .arrow {
    pointer-events: none;
  }
  .bs-popover-auto {
    left: -5px !important;
    z-index: 100 !important;
  }
}

.table-action-btn-wrapper {

  &.disabled {
    color: var(--gray);
    opacity: .5;
    pointer-events: none;

    svg {
      color: var(--gray);
    }
  }

  button {
    padding: 0;
    width: 100%;
    text-align: center;
    text-decoration: none !important;

    svg {
      width: 17px !important;
    }

    &.target-followed-btn {
      color: #52A451;

      &:hover {
        color: #346d33;
      }
    }
  }
}

.table-reset-filter {
  height: 34px;
}

.table-reset-filter__box {
  display: inline-block;

  .table-reset-filter__item {
    padding: 2px 20px 2px 8px;
    display: inline-block;
    margin-right: 10px;
    background: #F2F6FA;
    border: 1px solid #E5E5E5;
    border-radius: 4px;
    font-size: 12px;
    text-transform: capitalize;
    position: relative;

    svg {
      position: absolute;
      top: 5px;
      right: 6px;
      color: #53565A;
      cursor: pointer;
    }
  }
}

.exporter-form {
  position: relative;
  padding-top: 25px;
  margin-bottom: 20px;
  min-height: 400px;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.exporter-form-action-buttons {
  text-align: right;

  .btn:first-child {
    margin-right: 10px;
  }
}

.edit-export__select.app-form-control__container {
  height: auto !important;
}

.exporter-conditions__header {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  margin-top: 20px;
  padding: 10px;
}

.exporter-fields-table {
  margin-bottom: 20px;
}

.exporter_dynamic-form_col svg {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
}

.exporter-condition__remove {
  cursor: pointer;
}
.exporter-condition__remove img {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
}

.exporter-fields-table .vochub-select-control {
  margin-bottom: 0;
}

.exporter-sortable__item > .container {
  margin-bottom: 20px;
}

.exporter-switch-control {
  margin: 10px 20px 10px 0;
}

.exporter-form {
  .exporter-fields-table .app-form-control__container.with-error {
    margin-bottom: 0;
  }

  .app-form-control__container.with-error {
    height: 38px;
    margin-bottom: 12px;
  }

  .edit-export__select {
    margin-bottom: 0;
  }

  .dynamic-form_box-empty {
    text-align: center;
    color: #a94442;
    margin: 10px;
  }
}


.modalText {
  margin-bottom: 0;
}
.title {
  margin-bottom: 0;
  font-weight: bold;
}
.textarea {
  resize: none;
}

.time-date-grid__date-picker {
  position: relative;
  margin-left: 10px;

  .react-datepicker__navigation--next {
    right: -65px;
  }

  .react-datepicker__navigation--previous {
    left: -65px;
  }

  .react-datepicker__day--disabled {
    pointer-events: none;
    color: #ccc;
  }

  .disabled-date {
    pointer-events: none;
    color: #ccc;
    border-radius: 0.3rem;
  }

  .react-datepicker-wrapper {
    display: none;
  }

  .react-datepicker-popper {
    transform: unset !important;
    top: 35px !important;
    left: -18px !important;

    .react-datepicker__triangle {
      left: 65px !important;
    }

    .react-datepicker__day:hover {
      background: #216ba5;
      color: #fff;
    }

    .react-datepicker__day--keyboard-selected, .react-datepicker__day--selected {
      background: transparent;
      color: #000;
      font-weight: 400;
      border: 1px solid transparent;

      &.disabled-date {
        color: #ccc;
      }
    }

    .react-datepicker__day--today {
      border: 1px solid var(--red);
    }
  }
}

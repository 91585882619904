.panel__header-button {

  &.disabled {
    color: var(--gray);
    opacity: .5;
    pointer-events: none;

    svg {
      color: var(--gray);
    }
  }
}

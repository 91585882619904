//  CLEARFIX   @extend %clfix;
%clfix {
  *zoom: 1;
  &:before, &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

.dynamic-form {

  &_select {
    margin-bottom: 0;

    .vochub-select-control__control {
      min-height: 35px;
    }
  }

  &_row {
    border: 1px solid #dddddd;
    border-bottom-width: 0px;
    padding: 5px;
    z-index: 9999 !important;
    background: #fff;

    .select-control-label,
    .text-field-control-label {
      display: none;
    }

    .form-group {
      margin: 0;
    }

    .col-md-12 {
      padding: 0;
    }

    .row {
      margin: 0;
    }

    .form-control {
      height: 36px;
    }
  }

  &_col {
    padding-left: 2px;
    padding-right: 2px;
  }

  &_box-empty {
    text-align: center;
    color: #a94442;
    margin: 10px;
  }

  &_container {
    @extend %clfix;
  }

  &_box-list {
    background: #d6d6d6;

    > div {
      .dynamic-form_row:last-child {
        border-bottom-width: 1px;
      }
    }
  }

  &_box-title,
  &_box-list,
  &_row {
    @extend %clfix;
  }

  &_title {
    float: left;
  }

  &_btn {
    float: right;
    padding: 5px;
    margin-top: 10px;
  }

  &_title {
    padding-left: 7px;
    margin-bottom: 4px;
    font-weight: 700;
  }

  &_title {
    &.dynamic-col-0 {
      margin-left: 44px;
    }
  }

  &_title,
  &_col {
    float: left;

    &.col-drag {
      width: 5%;
      height: 36px;
      padding-top: 7px;
      padding-left: 9px;
    }

    &.dynamic-col-0 {
      width: 25%;
    }
    &.dynamic-col-1 {
      width: 40%;
    }
    &.dynamic-col-2 {
      width: 25%;
    }
    &.dynamic-col-3 {
      width: 5%;
    }
  }
}

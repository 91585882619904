*,
*:before,
*:after {
  box-sizing: border-box;
}

pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.color-green {
  color: #369736;
}

.color-blue {
  color: #007bff;
}

.pointer {
  cursor: pointer;
}

.page__wrapper {
  margin-top: 20px;

  .page__wrapper-share-rights {
    padding: 20px;
  }
}

html, body, :root {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}

html {
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
  background-color: #fff;
}

a[disabled] {
  pointer-events: none;
}

.no-margin {
  margin: 0 !important;
}

.route__wrapper {
  padding-top: 52px;
  min-width: 320px;

  @media print {
    padding: 0 !important;
    padding-top: 80px !important;
  }

  &.container-fluid {
    @media print {
      margin-right: auto !important;
      margin-left: auto !important;
    }
  }
}

.link-grey {
  color: #A0A0A2;
}

.opacity-0 {
  opacity: 0;
}

.opacity-6 {
  opacity: .6;
}

.panel .panel__header {
  font-size: 14px;
}

.vochub-card {
  margin-top: 30px;

  .fa-cloud {
    margin: 4px 2px 0 0;
  }

  .container_drop_zone {
    margin-top: 20px;
  }
}

.colorPickerCoords {
  position: absolute;
  z-index: 10;
  visibility: hidden;
  display: flex;
  flex-direction: column;
  background: none;
  border-radius: 4px;
  color: white;
  text-align: center;
}

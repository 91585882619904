.time-date-grid__container-loading {
  opacity: .4;
  pointer-events: none;
}

.timeDateHeaderPanel {
  display: flex;
  align-items: center;

  button {
    width: 95px;
  }

  .onEdit-button {
    margin-left: 12%;
    display: flex;
    justify-content: center;
    align-items: center;

    &--with_checker {
      margin-left: 10px;
    }

    svg {
      margin-right: 5px;
    }
  }

  .time-date-grid__switch-label {
    display: flex;
    margin-bottom: 0;
    margin-left: 10px;

    .switch-wrap {
      height: 30px;
    }

    .settings__label-text {
      display: flex;
      align-items: center;
      margin-left: 10px;
    }
  }

  .timeDateIndicators:not(:last-child) {
    margin-right: 10px;
  }

  .timeDateIndicators {
    display: flex;
    align-items: center;
    margin-left: auto;

    &--blue {
      background: var(--blue);
    }

    &--red {
      background: var(--red);
    }

    span {
      margin-right: 5px;
    }

    div {
      min-width: 34px;
      height: 20px;
    }
  }
}

.timeDateGrid__th {
  font-size: 12px;
}

.timeDateGrid {
  width: 100%;

  th, td {
    text-align: center;
    padding: 0.75rem 0;
  }

  thead, tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;

    td:first-child, th:first-child {
      width: 130px;
    }
  }

  thead {
    width: calc(100% - 1em);
  }

  tbody {
    display: block;
    height: 293px;
    border-top: 2px solid #dee2e6;
    border-bottom: 2px solid #dee2e6;
    overflow: auto;

    tr:nth-child(odd) {
      background-color: rgba(0, 0, 0, 0.05);
    }

    td {
      border-top: 1px solid #dee2e6;
    }

    tr {
      td:not(:first-child) {
        cursor: pointer;
        color: var(--light);
      }

      td:nth-child(n+2) {
        border-right: 1px solid #dee2e6;
      }

      td:last-child {
        border-right: none;
      }
    }
  }

  &.timeDateGrid--no-overflow {
    tbody {
      height: fit-content;
      display: table;
    }

    thead {
      width: 100%;
    }
  }
}

.footerButtons {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;

  &--error {
    color: var(--red);
    display: inline-block;
    padding: 6px 16px;
  }
}

.time-date-grid__info-empty {
  display: block;
  text-align: center;
  width: 100%;
  max-width: none;
  color: #777;
}

.time-date-grid__checkbox-container {
  display: flex;
  align-items: center;
  justify-content: center;

  input {
    cursor: pointer;
    position: unset;
    margin: 0;
  }

  label {
    cursor: pointer;
    padding-left: 10px;
    margin-bottom: 0;
  }

}

.container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;

  h6 {
    text-align: center;
    white-space: pre-line;
    margin-bottom: 15px;
  }

  .button > span {
    padding-left: 6px;
  }

  .loaderWrapper {
    height: 150px;
    width: 150px;
    position: relative;
  }
}

.manage-projects-modal-wrapper {
  .modal-dialog {
    width: 1200px;
    max-width: 1200px;
  }

  .modal-footer {
    display: block;

    .Select {
      flex-grow: 1;
    }
  }
}

body {
  .manage_projects__list-element {
    height: 42px;
    min-height: 42px;
    outline: none;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .wrap {
      max-width: 420px;
      display: inline-block;
      vertical-align: middle;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &.active {
      border-color: #ccc;
      border-left: 4px solid #666;
      background-color: #f5f5f5;
      color: #333;
      font-weight: bold;
    }
  }

  .manage_projects__list-icon {
    position: relative;
    z-index: 5;
    height: 26px;
    width: 26px;
    font-size: 14px;
    padding: 0;

    .delete-icon {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateY(-50%) translateX(-50%);
    }
  }
}


.touchpoint-list-modern-sortable-helper {
  box-sizing: border-box;
  display: block;
  padding: 10px 15px;
  margin-bottom: -1px;
  border: 1px solid #ddd;
  height: 42px;
  z-index: 999999 !important;

  &.active {
    border-color: #ddd;
    border-left: 4px solid #666;
    background-color: #f5f5f5;
    color: #000;
    font-weight: bold;
  }
}

.touchpoint-list-drag-handle {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  min-width: 14px;
}



.manage-projects-modal {

  .manage-projects__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .panel-heading {
    padding: 5px 10px 5px 15px;
    font-weight: bold;

    .touchpoint-title {
      margin-top: 7px;
    }

    span {
      font-size: 14px;
      max-width: 370px;
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .btn span {
      display: inline;
    }
  }

  .panel-body {
    padding: 0;

    .list-group {
      margin-bottom: 0;
    }
  }

  .list-group {
    overflow-y: scroll;
    height: 45vh;

    .list-group-item {
      outline: none;
      user-select: none;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .tooltip-cap {
        display: inline;
      }

      .wrap {
        max-width: 420px;
        display: inline-block;
        vertical-align: middle;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      &.active {
        border-color: #ccc;
        border-left: 4px solid #666;
        background-color: #f5f5f5;
        color: #333;
        font-weight: bold;
      }
    }
  }

  .list-group-item.active {
    background-color: #d9edf7;
    color: #31708f;
  }

  .Select--single {
    .Select-value {
      text-align: left;
    }
  }

  .input-group-addon {
    color: #000;
  }
}

.manage-projects-modal {
  .manage_projects__panel-body {
    padding: 0;
  }
}

.manage-modal-preloader-wrapper {
  margin: -120px;
  .loader {
    margin: 251px auto;
  }
}

.modal-note-create-project {
  color: #666;
  text-align: left;
  border-top: 1px dashed #cccc;
  padding: 13px 0 0;
}

.move-to-project-dropdown {
  .Select .Select-control {
    border-radius: 0;
  }
  .input-group-btn {
    color: #000;
    .btn {
      padding: 7px 12px;
    }
  }
}

.move-to-project-select {
  width: calc(100% - 240px);

  > div {
    border-radius: 0;
    border-color: hsl(0,0%,80%);
    box-shadow: none;

    &:hover {
      border-color: hsl(0,0%,70%);
    }
  }
}

.create-project-wrapper {
  text-align: left;
  margin-top: 20px;
}

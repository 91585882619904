.actionMenuWrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  & .actionButton {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
    color: var(--primary);
    cursor: pointer;
    transition: 0.25s;

    & svg {
      pointer-events: none;
    }

    &:hover {
      opacity: 0.7;
      transition: 300ms;
    }
  }
}

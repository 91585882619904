.setting-email-badge {
  border-radius: 2px;
  background-color: var(--light);
  position: relative;
  display: inline-flex;
  margin-right: 16px;
  padding: 2px 53px 2px 7px;
  margin-top: 10px;
  word-break: break-all;

  &.invalid {
    border: 1px solid var(--danger);
  }

  span {
    color: #000000;
    font-size: 14px;
  }

  .setting-email-badge_button {
    border: none;
    background: none;
    outline: none;
    position: absolute;
    top: 50%;
    right: 5px;
    cursor: pointer;
    transform: translateY(-50%);

    img {
      width: 8px;
    }
  }
}

.swatch-color-picker {
  display: inline-block;
  cursor: pointer;
  margin: 3px;
  height: 30px;
  width: 30px;
  border: 1px solid #d5d5d5;
}

.color-picker {

  .popover {
    position: absolute;
    z-index: 2;
    top: 34px;
    left: 54px;
  }
}

.actionWrapper {
  display: flex;
  flex-direction: column;
  min-height: 46px;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  right: 7px;
  top: 7px;
  opacity: 0;
  pointer-events: none;
  transition: 0.2s;

  &.actionWrapperHovered {
    opacity: 1;
    pointer-events: all;
  }

  & .actionButton {
    height: 32px;
    width: 32px;
    padding: 2px 5px;

    &:last-child {
      margin-bottom: 0;
    }

    & .cutGadget, .copyGadget, .moveToSave {
      @media (max-width: 1069px) {
        display: none;
      }
    }

    & svg {
      pointer-events: none;
    }
  }

  @media print {
    display: none;
  }
}

.popoverListWrapper {
  :global(.popover) {
    & .cutGadget, .copyGadget, .moveToSave {
      @media (max-width: 1069px) {
        display: none;
      }
    }
  }

  & :global(.popover-inner) {
    width: 200px;
  }
}

:global(.popover) {
  &.popperClass {
    margin-left: -1px !important;
    border-radius: 4px 4px 4px 0 !important;
    z-index: 1 !important;

    & :global(.popover-body) {
      padding: 1rem;
    }
  }
}


.languages-list {
  height: 39px;
  margin: 0 0 20px;
  transition: background .5s, border .5s;
  display: flex;
  justify-content: stretch;

  &__loading {
    height: 39px;
    width: 100%;
    border-radius: 5px;
    background: #f5f5f5;
    overflow: hidden;
    position: relative;

    &::before {
      position: absolute;
      top: -100px;
      left: -75%;
      z-index: 2;
      display: block;
      content: '';
      width: 50%;
      height: 200px;
      animation: languagesLoader .75s infinite;
      background: -moz-radial-gradient(center, ellipse cover, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%); /* FF3.6-15 */
      background: -webkit-radial-gradient(center, ellipse cover, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%); /* Chrome10-25,Safari5.1-6 */
      background: radial-gradient(ellipse at center, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }
  }
}

@keyframes languagesLoader {

  0% {
    transform: skewX(-45deg);
  }
  100% {
    left: 125%;
  }
}


.custom-option-language {
  position: relative;
  padding-left: 16px;

  &__active,
  &__text {
    &:before {
      content: '';
      display: block;
    }
  }

  &__active {
    position: absolute;
    width: 9px;
    height: 8px;
    left: 1px;
    top: 6px;

    &:before {
      border: solid #007aff;
      border-width: 2px 2px 0 0;
      transform: rotate(137deg);
      width: 8px;
      height: 6px;
    }
  }


  &__text {
    position: absolute;
  }

  &__text:before {
    position: absolute;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #2fb947;
    top: 7px;
    left: -15px;
  }
}

.languages-list__loader {
  .form-group {
    margin: 0;
  }

  p {
    width: 106px;
  }
}

.noGadgets {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    font-size: 18px;
    text-align: center;
  }
}

.dashboardBody {
  width: 100%;
  min-height: 970px;
}

.settings-field__input-label {
  color: #5c5c61;
  font-family: "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 6px;
}

textarea.voc-setting-field__input {
  min-height: 80px;
  overflow: auto;
  resize: vertical;
}

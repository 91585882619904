.deviceWrapper {
  display: flex;
  align-items: center;
  margin-left: 18px;

  .label {
    display: none;
    margin-right: 10px;

    @media screen and (min-width: 1440px) {
      display: initial;
    }
  }

  .devices {
    display: flex;
    flex-wrap: nowrap;
    background: #D9D9D996;
    border-radius: 5px;
    height: 38px;

    .deviceButton {
      font-size: 16px;
      width: 38px;
      height: 100%;
      color: black;
      border: none;
      box-shadow: none !important;

      &:hover, &.deviceButtonActive {
        opacity: 0.5;
      }
    }
  }
}

.component-value-label_container {
  align-items: center;
  width: calc(100% - 50px) !important;

  .vochub-select-control__value-container {
     justify-content: space-between !important;
  }
}

.component-value-label_title {
  font-weight: 400;
  display: inline-block;
  float: left;
  flex: 1;
  position: relative;

  input {
    height: auto !important;
    width: auto !important;
  }
}

.component-value-label_badge {
  display: inline-flex;
  justify-content: flex-end;
}

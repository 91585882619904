.popperClass {
  margin-top: 0 !important;
  border-radius: 0 0 4px 4px !important;
  border: 1px solid #E5E5E5 !important;
  box-shadow: unset !important;
  opacity: 1;
  transition-property: opacity;
  transition-timing-function: ease-out;
  transition-delay: 300ms;
  transition-duration: 300ms;
  // less than zIndex sidebar, droppableBox and modals
  z-index: 1009 !important;

  &.fadeOut {
    opacity: 0;
    pointer-events: none;
  }
}

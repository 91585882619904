.advanced-filter__box {
  height: 100%;
  display: flex;
  flex-direction: column;

  .blue-label {
    color: #A0A0A2;
  }

  .queryString {
    margin-top: 1.8rem;
    max-height: 200px;
    overflow-y: auto;
  }
}

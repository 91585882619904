.static-link__wrapper {
  display: flex;

}

.static-link__container {
  width: 167px;
}

.static-link__value {
  width: 78%;
  height: 36px;
  border-radius: 4px;
  border: 1px solid #8e8e93;
  background-color: #ffffff;

  font-size: 14px;
  padding: 7px 17px;
  box-sizing: border-box;

  & > a {
    color: #5c5c61;
    
    &:hover {
      text-decoration: underline;
    }
  }
}

.static-link__actions {
  width: 22%;
  text-align: right;
  font-size: 0;
}

.static-link__button {
  display: inline-block;
  color: #ffffff;
  font-size: 14px;
  height: 36px;

  &--primary {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
    width: 100%;
  }

  &:first-child {
    margin-right: 15px;
  }
}

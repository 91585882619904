.date-picker-custom-input {
  width: 100%;
  height: 34px;
  box-shadow: none;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  background-color: #e9ecef;
  text-align: left;
  user-select: none;
}

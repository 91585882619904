.source-segment-appliance-modal {

  .modal-dialog {
    width: 1075px;
    max-width: 1075px;
  }

  .vochub-select-control__control--is-disabled {
    background: #ececec !important;
  }

  .modal-content {
    position: relative;
  }

  .modal-body {
    height: 70vh;
    overflow-y: auto;
    position: static;
    padding-top: 1rem;
  }

  .modal-header {
    padding-left: 1.8rem;
  }

  .loader {
    margin: 26vh auto;
  }

  .title_select {
    width: 150px;
    margin-left: auto;
  }

  .appliance-name-form-group {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 20px;
    width: fit-content;

    .form-control {
      width: 210px;
    }
  }

  .appliance-content-alert {
    //margin-top: 20px;
    position: absolute;
    bottom: 18px;
    z-index: 2;
    left: 1.8rem;
    font-weight: 600;
    color: #dc3545;
  }

  .segmentator-name {
    margin-left: 90px;
  }

  .recorder-name {
    &-block {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 15px;
      margin-bottom: 20px;
      background-color: #337ab7;
      border: 1px solid #44b1d2;
      border-radius: 3px;
    }

    &-label,
    &-input {
      margin-bottom: 0;
      display: block;
    }

    &-label {
      font-size: 20px;
      width: 200px;
      color: white;
      text-transform: uppercase;
    }

    &-input {
      width: 425px;
      height: 38px;
    }
  }
}

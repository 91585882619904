.form-fields-select-control__option {

  .Select-options--sources {
    max-width: 100%;

    .badge {
      padding: 0.2em 0.4em;
    }
  }
}

.schedule-modal {

  .settings__input-label {
    width: 100%;
    margin-bottom: 0;
    position: relative;
  }

  .select-control-label {
    padding-left: 10px;
  }

  .select-control-label--left {
    padding-left: 0;
    padding-right: 10px;
  }
}

.source-label {
  display: inline-flex;
  justify-content: center;
  align-items: flex-end;
}

.Select-options--sources {
  max-width: 305px;
  display: flex;
  align-items: center;
  cursor: default;
  padding-right: 0px;
}

.manager-schedule_option-container {
  display: flex;
}



.menu-header-item {
  line-height: normal;
  padding: 12px 20px 12px 8px;
  display: flex;
  cursor: pointer;
  user-select: none;

  .app-icon__container {
    margin-right: 8px;
    width: 44px;
    height: 44px;
    text-align: center;
    display: flex;
    align-items: center;
  }

  .app-icon {
    display: block;
    margin: auto;
  }

  .navigation-item-text {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;

    &.navigation-item-text--info-hidden {
      align-items: center;

      .navigation__item-content {
        padding-top: 0;
      }
    }
  }

  .app-name,
  .app-description {
    line-height: normal;
    margin: 0;
    padding: 0;
  }

  .app-name {
    color: #000;
    font-size: 15px;
  }

  .app-description {
    margin-top: 4px;
    color: #7a7a7a;
    font-size: 10px;
  }
}

.menu-header__list {
  background: rgba(#e1e1e1, .5) !important;

  .divider {
    border-top: 1px solid #d7d7d7;
  }

  .menu-header__item {
    padding: 12px 14px 12px 12px;
    font-size: 14px;
    display: block;
    color: #000;
    transition: background-color .2s linear;

    &.active {
      background-color: #dedede;
    }

    &:hover {
      background-color: #dedede;
    }
  }
}

.table-action__container.table-action__export-schedule {
  display: flex;

  > .table-action__export-schedule-icon {
    margin-left: 8px;
    margin-right: 8px;

    &.disabled {
      color: var(--gray);
      opacity: .5;
    }
  }
}

.graph-box {
  background: #ECF0F6;
  min-height: calc(100vh - 52px);
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.container-fluid__new-design.container-fluid {
  width: calc(100% - 60px);
  margin-left: 60px;
}

.route-voc-container {
  @media (max-width: 767px) {
    margin-left: 0px !important;
    width: 100% !important;
    max-width: 100% !important;
  }
}

.container-fluid .offset-menu {
  padding-left: 24px;
}

.temporary_fix_none {
  display: none;
}

.row-wrapper__mine {
  height: calc(100vh - 52px);
}

.checkboxesTitle {
  margin-top: 15px;
  margin-bottom: 10px;
}

.checkboxesWrapper {
  display: flex;
  flex-direction: column;
  padding-left: 17px;
}

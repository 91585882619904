.classifierFieldsSection {
  padding: 0px 24px 0px 10px;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  height: inherit;

  hr {
    width: calc(100% - 20px)
  }
}

.classifierWrapperLoading {
  opacity: .5;
  pointer-events: none;
}

.topicSetPageWrapper {
  height: calc(100vh - 55px);
  margin-right: -15px;

  .visualPane {
    display: flex;
    flex-direction: column;
    overflow: auto;

    @media (max-width: 992px) {
      padding: 0px 24px 0px 10px;
    }
  }

  @media (max-width: 992px) {
    display: flex;
    flex-direction: column;

    :global(.react-split__pane) {
      width: 100% !important;
      position: initial;
    }

    :global(.react-split__sash) {
      display: none;
    }
  }
}

:global(.container-fluid) {
  @media (max-width: 767px) {
    margin-left: 0 !important;
    width: 100% !important;
  }
}

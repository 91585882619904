.radarChart {
  margin: 1rem 1rem 0 1rem;
  position: relative;
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  :global(.transparent) {
    opacity: .2;
  }
}

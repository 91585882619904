.case-alert-condition_container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .case-alert-condition__list-wrap-icon {
    margin-left: 5px;
    margin-right: 5px;
    cursor: pointer;
  }

  .case-alert-condition__list-item {
    display: flex !important;
    color: #337ab7;
    font-size: 0.88rem;
    letter-spacing: normal;
  }

  .case-alert-condition_string {
    text-transform: uppercase;
    color: #777;
    padding: 0 10px;
    min-width: 150px;
  }

  .case_alerts-condition__list-action {
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
  }
}


.table-action__case_alerts-list-icon,
.case-alert-condition__list-wrap-icon {

  &.disabled {
    color: var(--gray);
    opacity: .5;
    pointer-events: none;

    svg {
      color: var(--gray);
    }
  }
}

.mine-info-box__for-visual .tooltip-inner {
  max-width: 300px;
}

.mine-info__wrapper {
  margin-bottom: 24px;

  & .mine-info__row {
    display: flex;

    & .titleWrapper {
      font-size: 18px;
      max-width: calc(100% - 185px);
    }

    & > div {
      max-width: 50%;
      flex: 1;
    }
  }

  .checkbox-input__wrapper {
    text-align: right;
    white-space: nowrap;
    width: 185px;
    align-self: center;

    input {
      position: initial;
      margin: auto 10px;
    }

    label span {
      color: #53565A;
      font-weight: 400;
      user-select: none;
      padding-right: 6px;
    }

    svg path {
      fill: #53565A;
    }

    &.wrapper__disabled {
      input, label {
        opacity: .5;
      }
    }
  }

  & .editField {
    display: flex;
    justify-content: space-between;

    &:hover .editIcon {
      opacity: 1;
    }
  }
}

.mine-info__item {
  padding-bottom: 10px;
  display: flex;
  align-items: center;

  .mine-button__item {
    padding: 5px 16px;
    min-width: 115px;
    align-self: baseline;
  }

  &.editIcon {
    margin: 0 6px;
    color: var(--primary);
    opacity: 0;
    cursor: pointer;
  }

  .badge {
    color: var(--white);
    display: flex;
    align-items: center;
    margin-left: 5px;
  }

  .vochub-select-control {
    margin: 0;
  }

  .container {
    padding: 0;
    display: inline-block;
  }

  .menu-info__value {
    display: inline-block;
    padding-left: 10px;
    margin: 0;


    &.menu-info__value-title {
      max-width: calc(100% - 53px); // ~53px length of "Query" label
    }

    .menu-info__title {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  span {
    color: #53565A;
  }

  .table-tag__label {
    color: #212529;
    padding: 1px 8px;
  }
}

.mine-info__name-language {
  padding-top: 8px;
  align-items: center;

  .mine-info-name-language__label {
    margin-right: 10px;
  }

  .mine-info-name-language__field {
    width: 64px;
  }
}

.dictionaries-info-items-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .dictionaries-info-items {
    width: 100%;

    .menu-info__value {
      width: calc(100% - 130px);
      display: flex;
      flex-wrap: wrap;

      .dictionary-list-item {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .dictionaries-info__dictionary-btn {
    padding: 0;
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    align-self: flex-start;
    flex-shrink: 0;
    border: none;
    color: var(--blue);
    background: transparent;

    &:hover {
      background: transparent;
      color: var(--blue);
      opacity: .5;
    }

    &:active, &:focus {
      box-shadow: none !important;
      background: transparent !important;
      color: var(--blue) !important;
    }

    span {
      margin-left: 5px;
    }
  }
}

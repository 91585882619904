.table {
  position: relative;
  border-spacing: 0;
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  margin-bottom: -58px;

  @media print {
    overflow: hidden !important;
  }

  .rowgroup {
    flex: 1;
    position: relative;
    width: 100%;
  }

  .header {
    position: sticky;
    top: 0;

    z-index: 1;
  }
}

.tr {
  width: 100% !important;
  display: flex;

  &.trEven .td{
    background: #f3f3f3;
  }

  &:last-child {
    .td {
      border-bottom: 0;
    }
  }
}

.Select-options--sources {
  max-width: 305px;
  display: flex;
  align-items: center;
  cursor: default;
  padding: {
    right: 5px;
  }

  .Select-options__title {
    line-height: 31px;
    white-space: nowrap;
    overflow: hidden;
    padding: 0;
    text-overflow: ellipsis;

    &-wrapper {
      flex-grow: 1;
      width: 25%;
      padding: 0 8px;
      vertical-align: top;
    }
  }
}

.Select-options__labels-container {
  display: inline-flex;
  justify-content: flex-end;
  align-items: flex-end;

  .stopped {
    background: #ffcc00;
    color: #212529;
  }

  .active {
    background: #5cb85c;
  }

  .paused {
    background: #f0ad4e;
  }
}

.Select-options__source-label {
  padding: .2em .6em .3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;
  height: 15px;
  display: inline-block;
  margin-right: 2px;
}

.source-select {
  div.vochub-select-control__option {
    background: {
      color: white;
    }
  }
}


.Select-options--sources {
  max-width: 305px;
  display: flex;
  align-items: center;
  cursor: default;
  padding-right: 0px;
}

.Select-options--sources .Select-options__title-wrapper {
  flex-grow: 1;
  width: 25%;
  padding: 0 0px;
  vertical-align: top;
}

.share-rights-permission-table-filter__tableFilter {
  height: auto;

  .voc-select-control-labels {
    margin: 0;
  }
}
.share-rights-permission-table-filter {
  &__wrapper {
    display: flex;
    margin-left: auto;
    width: 50%;
    align-self: end;
    padding-left: 15px;
    transform: translateY(21px);
    z-index: 1;

    &-disabled {
      opacity: .5;
      pointer-events: none;
    }

    @media (max-width: 829px) {
      transform: translateY(102px);
      padding-left: 0;
      width: 100%;
    }
  }

  &__item {
    svg {
      margin: 0 5px;
    }

    button {
      padding: 0;
      background: none;
      cursor: pointer;
      border: none;
    }

    &:first-child {
     flex: 1;
    }

    &:last-child {
      width: 100px;
    }

    .share-rights-filter__active-filter {
      color: var(--primary)
    }
  }
}

.barChartWrapper {
  margin-bottom: 30px;

  :global(.bar) {
    transition: .3s;
    cursor: pointer;
  }
  .barChartLabel {
    margin: 0;
    margin-bottom: 10px;
    font-size: 20px;
  }

  .toolTipWrapper {
    position: fixed;
    z-index: 10;
    visibility: hidden;
    display: flex;
    flex-direction: column;
    background: black;
    border-radius: 4px;
    color: white;
    padding: 2px 6px;
    text-align: center;
  }

  .barChartЫмпWrapper {
    width: 100%;
    height: 400px;
  }
}

.export-results__selected-wrapper {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 9px 0 7px;
  margin: 5px 0;

  .export-results__selected-item {
    display: inline-block;
    position: relative;
    color: #3b73af;
    font-size: 14px;
    height: 24px;
    line-height: 23px;
    padding-left: 5px;
    padding-right: 22px;
    border-radius: 2px;
    border: 1px solid #eee;
    margin-right: 7px;
    margin-bottom: 4px;
    transition: color .1s linear, background .1s linear;

    &:hover {
      background: #d9534f;
      color: #fff;
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
      cursor: pointer;
    }
  }

  .export-results__selected-icon {
    position: absolute;
    top: 4px;
    right: 6px;
    cursor: pointer;
  }

  .export-results__selected-text {
    margin: 6px 0 6px;
    text-align: center;
  }
}

.list {
  padding: 0;
  margin: 0;
  width: 100%;
  overflow: hidden;

  & .listHeaderWrapper {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    justify-content: space-between;
    width: 100%;
    min-height: 42px;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #E5E5E5;
  }

  li {
    list-style: none;
    display: flex;
    align-items: center;
    padding: 7px 5px;
    cursor: pointer;
    transition: .25s;
    color: var(--dark);

    svg {
      margin-right: 11px;
      width: 1em !important;

      path {
        fill: var(--dark);
      }
    }

    &:hover {
      opacity: .6;
      transition: .25s;
    }
  }
}

.tagDisabled {
  opacity: 0.65;
  pointer-events: none;
}

.actionDisabled {
  color: var(--gray);
  pointer-events: none;
  opacity: .7;

  svg {
    pointer-events: none;

    path {
      fill: var(--gray) !important;
    }
  }
}

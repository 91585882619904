.dictionary-form {

  .dictionary-form__loader {
    position: relative;

    .thin-spinner__dictionary-modal {
      width: 100%;
      height: 100%;
      z-index: 99;
      background-color: rgba(254, 254, 254, 0.7);

      &::before {
        border-color: #428bca;
      }
    }
  }

  .field-label {
    display: block;
    font-weight: normal;

  }

  .with-error input + .text-muted {
    position: absolute;
    color: var(--danger) !important;
    font-weight: bold;
    line-height: normal;
    padding: 0;
    font-size: 11px;
    right: 0;
  }


  .field-label--select {
    display: inline-block;
    margin-right: 8px;
  }

  .field-label--select + div {
    width: 80px;
    display: inline-block;
    margin: 24px 0;

    #language {
      margin-bottom: 0;
    }
  }

  #gear-button {
    margin-top: 5px;
    padding: 6px 8px 6px 0;
    background: transparent;
    border: 1px solid transparent;
    color: var(--primary);
    display: flex;
    align-items: flex-end;

    .gears-icon {
      margin-right: .25rem;
    }

    &:focus, &:active {
      border-color: transparent;
      color: var(--primary);
      background-color: transparent;
      box-shadow: none;
    }

    &:hover {
      opacity: .8;
    }

    &:disabled {
      background: transparent;
      border-color: transparent;
      color: var(--primary);
    }
  }
  .dictionary-form__textarea-size {
    height: auto !important;

    & > textarea {
      min-height: 105px;
      max-height: 405px;
    }
  }
}

.add-dictionary-modal {
  .modal-header {
    padding: 16px 24px;

    .modal-title {
      font-weight: bold;
    }
  }

  .modal-content {
    max-width: 500px;
    margin: 0 auto;
  }
}

.modal-body + .modal-footer {
  padding: 16px 24px;
}

.modal-footer {
  .add-dictionary__footer {
    display: flex;

    .add-dictionary__footer-button {
      padding: 8px 16px;
      border: 1px solid #dddddd;
      display: flex;
      align-items: center;
      font-size: 14px;
      background: var(--white);
      color: var(--dark);

      span {
        color: inherit;
      }

      svg {
        margin-right: .25rem;
      }

      &:active {
        outline: none !important;
        box-shadow: 0 0 0 0.2rem rgba(51, 122, 183, 0.25) !important;
        border-color: #dddddd !important;
        background: var(--white) !important;
        color: var(--dark) !important;
      }

      &:hover {
        color: #333;
        background-color: #e6e6e6 !important;
      }

      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(51, 122, 183, 0.25);
      }
    }

    .add-dictionary__footer-button + .add-dictionary__footer-button {
      margin-left: 16px;
      background: var(--primary);
      color: var(--white);
      width: 92px;
      display: flex;
      justify-content: center;

      &:active {
        background: var(--primary) !important;
        color: var(--white) !important;
      }

      &:hover {
        background-color: var(--primary) !important;
      }

      &:focus {
        border-color: #255985 !important;
        background: #285f8f !important;
      }
    }
  }
}

.controlPanelWrapper {
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e9e9e9;
  padding: 0 15px 0 5px;
  flex-shrink: 0;
  z-index: 3;
  overflow-x: auto;

  &.controlPanelWrapperLoading {
    opacity: 0.6;
    pointer-events: none;
  }

  .part {
    display: flex;
    align-items: center;

    :global(.vochub-select-control) {
      margin-bottom: 0;
    }

    &.partLeft {
      flex: 1;
    }
  }

  .backButton {
    white-space: nowrap;
  }

  .previewTitleWrapper {
    max-width: 200px;
    margin-right: 6px;
  }

  .previewTitle {
    font-size: 18px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    white-space: nowrap;
  }

  .saveButton {
    border: none !important;
    color: var(--primary) !important;
    margin-left: 10px;
    background: transparent !important;
    white-space: nowrap;

    &:hover {
      border: none !important;
      color: var(--primary) !important;
      opacity: .8 !important;
      box-shadow: none !important;
    }
  }

  .themeSelector {
    width: 270px;
  }

  .langSelector {
    width: 100px;
  }

  .deviceSelector {
    width: 270px;
    margin-left: 18px;
  }

  :global(.button_link-back_grey), .saveButton {
    box-shadow: none !important;
  }
}

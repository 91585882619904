.segment-group-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;

  &-left {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .btn {
      width: 40px;
    }

    .star {
      margin-right: 40px;
    }

    label {
      width: 95px;
      margin-bottom: 0;
    }

  }
}

.query-string-tooltip {
  width: 400px;

  .tooltip-inner {
    max-width: 400px;
    width: 400px;
  }
}

.appliance-segment-panel {
  margin-bottom: 20px;

  .panel-body {
    background-color: #f5f5f55e;
  }

  .voc-meta-filter {
    margin-bottom: 20px;
  }

  .queryString {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    width: auto;
    max-width: 100%;
    font-weight: bold;
  }

  &.panel > .panel-heading {
    background-color: #f5f5f5;

    .control-label {
      color: #337ab7;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }

    }
  }
}

.appliance-name__validation {
  line-height: 1.4;
  color: #dc3545;
  font-weight: 600;
  padding-left: 20px;
  display: inline-block;
}

.appliance-name__validation_bottom {
  display: block;
  position: absolute;
  bottom: 5px;
  left: 0;
}

.export-results__checkbox {

  &.form-check-input {
    position: initial;
    margin-top: 0;
    margin-left: 0;
  }
}

.export-results-checkbox__wrapper {
  margin-bottom: 20px;

  label span {
    padding-left: 10px;
  }
}

.home {
  &__background {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 0;

    &::before {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 35%;
      top: 0;
      background: linear-gradient(#000000, transparent) left repeat;
      opacity: 0.5;
      z-index: 1;
    }
  }

  &__background-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: bottom center;
    z-index: 0;
  }
}

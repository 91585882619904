.file-preview {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.btn-metadata-show {
  margin-bottom: 10px;
}

.file-preview-text {
  word-break: break-all;
  hyphens: auto;
}

.file-dialog {
  font-size: 48px;
  margin: 10px 0;
  cursor: pointer;
}

.file-upload-icon {
  font-size: 48px;
  margin: 10px 0;
  cursor: pointer;
}

#choosen-files {
  word-break: break-all;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  text-align: center;
}

#store_upload_file {
  min-height: 122px;
}

.alert {
  &-error {
    color: #f57171;
    background: #ff00001c;
  }
}

.container_drop_zone {
  position: relative;
  width: 100%;
  height: 122px;
  border-radius: 3px;
  cursor: pointer;
  border: 1px dashed rgb(51, 51, 51);

  &.alert-info {
    border-color: #333333;
  }
}

.add-to-blacklist-form_row {
  margin-bottom: 5px;
  text-align: right;

  & .app-form-control__container.with-error {
    .app-form-control__error.form-text {
      margin-top: 0;
    }
  }

  & > .col-md-3 {
    padding-top: 6px;
  }
}

.app-form-control__container {
  .form-control {
    height: 38px;
  }

  .edit-button__input {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 4;
    width: 25px;
    height: 25px;
    padding: 2px 0 0 2px;
    background: #4ea3b824;
  }
}

.app-form-control {
  -moz-appearance: textfield;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }

  &__container {
    &.with-error {
      height: 50px;

      .app-form-control__error {
        color: #d9534f !important;
      }

      .app-form-control__warning {
        color: #ffc107 !important;
      }
    }
  }
}

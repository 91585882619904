.layouts-modal__preloader {
  height: 476px;

  .loader {
    margin-top: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
}

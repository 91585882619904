.query-title__wrapper {
  position: relative;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  .query-title__btn {
    position: absolute;
    padding: 0;
    top: 0;
    right: 0;
    height: 21px;
    width: 21px;

    svg {
      font-size: 11px;
    }
  }

  .query-title__input {
    height: 21px;
    font-size: 13px;
    width: calc(100% - 25px);
    box-shadow: none;
    border-color: #dde2e7;
    background-color: #f8f8f8;

    &:focus {
      box-shadow: none;
      border-color: #c5cacf;
      background-color: #fcfcfc;
    }
  }
}

.radarChartWrapper {
  display: flex;
  flex-direction: column;
  height: inherit;

  :global(.circle) {
    cursor: pointer;
  }

  :global(.itemHovered) {}

  :global(.react-split__sash) {
    @media (max-width: 1069px) {
      display: none;
    }
  }
}

.query-filters-modal__advanced {
  .modal-body {
    max-height: unset;
  }

  .modal-dialog {
    max-width: 60vw;
  }

  .voc-meta-filter {
    overflow: auto;
    max-height: 60vh;
  }
}

.query-filters-modal__classification {
  .select-control-label {
    display: none;
  }

  .col-md-9 {
    display: flex;
    flex: 0 0 100%;
    max-width: 100%;
    justify-content: center;
  }
}

  .export_source-select-value {
    width: calc(100% - 45px);
    box-sizing: border-box;
    display: flex;
    align-items: center;
  }

  .exporter-select-value__title {
    width: 100%;
    display: inline-block;
    float: left;
    max-width: 260px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .exporter-select-options-value__labels-container {
    display: inline-flex;
    justify-content: flex-end;
    align-items: flex-end;
  }

  .exporter-select-options__source-label {
    padding: .2em .6em .3em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
    height: 15px;
    display: inline-block;
    margin-right: 2px;
  }


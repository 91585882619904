.answers__error {
  display: block;
  color: #9a3b38;
  margin-bottom: 15px;
  position: relative;
  padding: .75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0,0,0,.125);
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.question__section-icon  {
  margin-right: 10px;
}

.question__answers-list {
  margin-top: 1.5rem;
  padding-left: 0;
}

.language__header-value {
  border: 1px solid #d8d8d8;
  text-align: center;
}

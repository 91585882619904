.gadget {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #E5E5E5;
  border-radius: 4px;
  background-color: white;
  position: relative;

  &.staticAreaGadget {
    background-color: transparent;

    &.isEditableGadget {
      border: 1px solid #afabab;
    }
  }

  &:global(.flesh) {
    animation: flash 500ms 2 linear 1s;
  }

  @keyframes flash {
    from {
      box-shadow: 0 0 10px 1px #ECF0F6;
    }

    50% {
      box-shadow: 0 0 10px 1px var(--secondary);
    }

    100% {
      box-shadow: 0 0 10px 1px #ECF0F6;
    }
  }

  &:global(.highlight) {
    animation: highlight 35s forwards;
  }

  @keyframes highlight {
    0% {
      box-shadow: none;
    }

    10% {
      box-shadow: 0 0 0 3px var(--green);
    }

    90% {
      box-shadow: 0 0 0 3px var(--green);
    }

    100% {
      box-shadow: none;
    }
  }

  &:global(.shortHighlight) {
    animation: shortHighlight 1500ms linear;
  }

  @keyframes shortHighlight {
    0% {
      box-shadow: none;
    }

    25% {
      box-shadow: 0 0 1px 1px var(--green);
    }

    75% {
      box-shadow: 0 0 3px 2px var(--green);
    }

    100% {
      box-shadow: none;
    }
  }

  .chartContainer {
    max-width: 100%;
    width: 100%;
    height: 100%;
    overflow: auto;
    border-radius: inherit;
  }

  .tagContainer {
    width: 100%;
    height: 40%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .lastHealthCheckTime {
    position: absolute;
    right: 10px;
    bottom: 5px;
    color: white;
    margin: 0;
    font-size: 12px;

    @media print {
      display: none;
    }
  }
}

:global(.sketch-picker ) {
  box-shadow: none !important;
  user-select: none;
}

.channels-controls__global {
  .channels-controls {
    border-color: #dddddd;

    svg {
      margin-right: 5px;
    }

    &:disabled {
      opacity: 1;
    }

    &.active {
      color: #fff;
      background: var(--primary);
      border-color: var(--primary);

      &.inactive {
        background: var(--gray);
        border-color: var(--gray);
      }
    }
  }
}

.notification__text {
  color: #4a4a4a;
  word-break: break-all;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  flex-shrink: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  hyphens: auto;
  width: 100px;
  flex-grow: 1;
  margin-right: 5px;
  cursor: pointer;
}

.notification-item__container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-wrap: wrap;
  text-align: left;
  font-size: 14px;
  padding: 0 2px 0 5px;
  margin-bottom: 1rem;

  &:hover {
    & .notification-item__severalItemsContainer {
      border-left: 2px solid var(--primary);
    }
  }

  & .notification-item__messagePartContainer {
    display: flex;
    flex: 1;
    align-items: center;

    &.parent {
      margin-bottom: 0.5rem;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  & .notification-item__severalItemsContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-left: 6px;
    padding-left: 6px;
    border-left: 2px solid var(--new-gray);
    transition: all 300ms ease;
  }
}

.notification-item__value {
  margin-right: 5px;
  font-size: 12px;
  font-weight: 700;
}

.export-results__icon {
  margin-right: 3px;
}

.export-results__labels-container {
  background: #ffffff;
  padding-left: 3px;
  padding-right: 3px;
}

.panel__header-button_showDisabled {
  .switch-wrap {
    pointer-events: none;
    margin-right: 5px;

    label {
      transform: translateY(2px);
    }
  }
}


.app-form-control_password-eye {
  position: absolute;
  top: 10px;
  right: 5px;
  width: 20px;
  height: 20px;
  user-select: none;

  & > svg {
    color: grey;
  }
}

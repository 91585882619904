.Select-value {

  &.source-select-value {
    width: calc(100% - 65px);
    box-sizing: border-box;
    display: flex;
    align-items: center;

    .stopped {
      background: #ffcc00;
      color: #212529;
    }

    .active {
      background: #5cb85c;
    }

    .paused {
      background: #f0ad4e;
    }

    .Select-value__title {
      width: auto;
      display: inline-block;
      float: left;

      > div {
        width: 100%;
        display: inline-block;
        font-size: 14px;
        float: left;
      }
    }

    .Select-value__labels {
      display: inline-block;
      float: right;
      width: auto;
      text-align: right;
    }

    + .vochub-select-control__indicators {
      .vochub-select-control__clear-indicator {
        padding: 8px 8px 8px 0;
      }
    }
  }

  &__labels {
    line-height: 0;
  }

  &__title {
    flex-grow: 1;
    overflow: hidden;
    padding-left: 8px;

    .Select-menu__small {
      padding-left: 0;
    }

    > div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 16px;
      display: block;
    }
  }

  &__labels {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 95px;

    .badge {
      text-transform: uppercase;
      padding: .2em .6em .3em;

      &:last-of-type {
        margin-left: 3px;
      }
    }
  }
}

div.vochub-select-control__loading-indicator {
  position: absolute;
  right: -8px;
  top: 20px;
}

.vochub-select-control__control {

  .badge, .fixed__select-value-label {
    font-size: 70%;
    line-height: 1;
  }
}

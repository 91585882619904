.file-list__item {
  float: left;
  width: 145px;
  height: 180px;
  margin: 8px;
  cursor: pointer;
  box-shadow: 2px 2px 5px rgba(0,0,0,0.3);
  transition: box-shadow .2s linear, opacity .2s linear;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    box-shadow: 1px 1px 5px rgba(0,0,0,0.3);
    opacity: 0.7;
  }

  .file-list__item-folder {
    color: #fbcd44;
    font-size: 105px;
    margin: 22px;
    text-shadow: 1px 2px 1px rgba(0,0,0,0.1);
  }

  figure {
    position: relative;
    margin: 0;


    p {
      margin: 0;
      height: 130px;

      img {
        width: 145px;
        height: 115px;
        object-fit: cover;
      }
    }

    figcaption {
      background: #fff;
      padding: 10px;
      word-break: break-all;
      font-size: 12px;
      height: 50px;
      border-top: 1px solid #eaeaea;
    }
  }
}

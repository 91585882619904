.voc-table_header {
  &-container {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-search.form-control {
    width: 350px;
  }

  &-custom-filters {
    flex-grow: 1;
    line-height: normal;
  }

  &-new-filters {
    line-height: normal;
  }
}

.voc-table_header-container__right {
  display: flex;
  justify-content: flex-end;

  .voc-table_header-search {
    margin-right: 15px;
  }
}

.word-cloud__section--correlation {
  .cloud-place {
    display: flex;
    height: 100%;

    .ranger-place--vertical {
      margin-left: auto;
      margin-top: unset;

      .range-wrapper--vertical {
        width: 25px;
      }
    }
  }
}

.ranger-place:not(.ranger-place--vertical) {
  margin-top: auto;
}

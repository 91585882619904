.customColorSet {
  display: flex;
  flex-wrap: wrap;
  margin-left: -2px;
  margin-bottom: 20px;

  .addColorButton {
    height: 33px;
    margin: 2px;
    width: 158px;
  }

  .colorPickerWindow {
    .sketchPickerWrapper {
      width: fit-content;
      position: absolute;
      border-radius: 2px;
      margin-bottom: 5px;
      -webkit-box-shadow: 0px 0px 19px 0px rgba(0,0,0,0.15);
      box-shadow: 0px 0px 19px 0px rgba(0,0,0,0.15);
      background: white;
      z-index: 1;

      .brightSlider {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5px;
        padding: 0 6px;

        p {
          margin: 0;
          margin-left: 5px;
          flex: 1;
          text-align: center;
          min-width: 33px;
        }
      }

      .controls {
        display: flex;
        width: 163px;
      }

      .sketchPicker {
        width: 158px !important;
        box-shadow: none !important;
        padding: 2px !important;
      }
    }

  }

  .colorBlocks {
    display: flex;
    border: 1px solid;
    padding: 3px;
    width: fit-content;
    border-radius: 2px;
    margin: 2px;
    cursor: pointer;
    position: relative;

    &.active {
      background: #2f2f2f;
    }

    &:hover {
      opacity: .9;
    }

    .colorBlock {
      height: 25px;
      width: 25px;
    }

    .closeButton {
      position: absolute;
      height: 12px;
      width: 12px;
      right: 0;
      top: 0;
      background: #ff6500;
      font-size: 10px;
      text-align: center;
      color: white;
      line-height: 10px;
      border-bottom-left-radius: 2px;
      cursor: pointer;

      &:hover {
        background: red;
      }
    }
  }
}

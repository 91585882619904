.loaderWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .message {
    padding: 0px 20px;
    text-align: center;
  }
}

.input-group-number {
  .voc-number-input {
    text-align: center;
    padding: 6px 9px !important;
    height: 34px;
    -moz-appearance:textfield;


    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0;
    }
  }

  .voc-number-input__button {
    height: 34px;

    &--first {
      border-right: none !important;
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }
    &--last {
      border-left: none !important;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
  }
}
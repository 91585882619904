.form {
  width: 100%;

  div {
    flex-wrap: nowrap;
  }

  @media (max-width: 992px) {
    .formRow {
      flex-direction: column;
      align-items: flex-start;
    }

    .periodSelect {
      width: 100%;
    }

    .centeredContent {
      flex-direction: column;
      width: 100%;
      align-items: flex-start;
    }

    .timeSelect {
      margin-bottom: 5px;
      width: 100%;
    }

    .voc-datepicker {
      width: 100%;
    }

    .dateSelectGroup {
      width: 100% !important;

      div:first-of-type {
        width: 100%;
      }

      input {
        width: 100% !important;
      }
    }

    .dateLabel {
      display: block;
      margin-top: 8px;
    }
  }
}

.formRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.formLabel {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.labelWithMargin {
  margin-top: 8px;
}

.centeredContent {
  display: flex;
  align-items: center;
  justify-content: center;
}

.centeredSwitch {
  margin-left: 15px;
  display: flex;
  align-items: center;
}

.periodSelect {
  width: 239px;
  height: auto !important;
}

.timeSelect {
  width: 150px;
  height: auto !important;
}

.dateSelectGroup {
  width: 239px !important;
  height: auto !important;

  div {
    height: auto !important;
  }

  input {
    width: 204px !important;
  }

}

.dateLabel {
  display: none;
}

.textarea {
  height: auto !important;

  & > textarea {
    min-height: 105px;
    max-height: 540px;
    resize: none;
  }
}

.emailInput {
  width: 89%;
}

.checkbox {
  margin-left: 5px;
}

.buttonIcon {
  margin-right: 10px;
}

.inputGroupText {
  height: 38px;
}

.noRadiusRight {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.noRadiusLeft {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

   input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
   }
}

.noMargin {
  margin: 0;
}

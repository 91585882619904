.radiosWrapper {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;

  :global(.source-select-value) {
    overflow: hidden;
  }
}

.selectWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}

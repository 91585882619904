.p-label {
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
}

.p-textarea {
  height: 55px;
  padding: 0.375rem 0.75rem 0.375rem 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  white-space: pre-line;
  overflow: auto;
}

.info-textarea {
  textarea {
    height: 55px !important;
  }
}

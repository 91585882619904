.query-details {
  .query-details-text {
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 600px;
    height: 100%;
    width: 100%;

    .empty-data {
      padding-bottom: 168px;
    }

    &__item {
      margin-bottom: 16px;

      .maxHeight {
        max-height: 30px;
      }
    }

    &-p {
      font-size: 14px;
      color: #000;
      margin-bottom: 8px;
      word-wrap: break-word;
    }

    &-more {
      padding-bottom: 8px;

      &__error {
        color: var(--red);
      }

      &__title {
        margin-top: 15px;
        font-size: 14px;
        line-height: 16px;
        color: #53565A;

        & + div.query-details-text-more__attributes p {
          margin-top: 0;
        }
      }

      &__attributes {
        font-size: 12px;
        display: flex;
        flex-direction: row;
        line-height: 0.8;

        > p {
          margin-top: 5px;
          margin-bottom: 0;
        }

        .attribute-name {
          color: #53565A;
          margin-right: 5px;
          word-break: break-all;
        }

        .attribute-value {
          line-height: 1.1;
          color: #000000;
          word-break: break-all;
        }
      }

      .collapse__header {
        padding: 0;

        &-title {
          padding-left: 22px;
          font-size: 14px;
          line-height: 16px;
          color: #A0A0A2;
          font-weight: 400;
        }
      }
    }


    .card {
      background: none;
      border-bottom: 1px solid #E5E5E5;

      &-body {
        padding: 0;
      }
    }

    .collapse-section__info-section.card .card-header {
      padding: 0;
      background: none;
    }

    .collapse-section__info-section.card .card-header h5 {
      overflow: hidden;
      height: 16px;
    }

    .collapse-section__info-section.card .card-header h5 img {
      right: auto;
      left: 0;
      top: 5px;
      height: 7px;
      width: 12px;
    }

    .voc-table__footer {
      margin-top: auto;
    }
  }
}

.select-text-popover {
  pointer-events: auto !important;

  &-body {
    border-radius: 4px;
    background: #53565A;
    display: inline-flex;
    width: max-content;

    &__btn {
      height: 24px;
      margin: 0 3px;
      font-size: 12px;
      line-height: 16px;
      color: #FFF;
      border: none;
      border-radius: 4px;
      background-color: #53565A;
      transition: opacity 0.3s ease;
      display: inline-flex;
      width: max-content;
      align-items: center;

      &-title {
        display: inline-flex;
        width: max-content;
        margin-left: 5px;
      }

      &:hover {
        opacity: 0.6;
      }
    }

    .disable {
      opacity: .4;
    }

  }
}

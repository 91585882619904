.range-wrapper {
  position: relative;

  .range-slider {
    display: flex;
    align-items: center;
    height: 40px;
    width: 100%;

    .slider-track {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    .slider-background {
      width: 100%;
      height: 5px;
    }

    .slider-thumb {
      height: 25px;
      width: 25px;
      border-radius: 4px;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 2px 6px #aaa;
      z-index: 50 ! important;

      &:hover {
        cursor: pointer !important;
      }

      &:active {
        cursor: grabbing !important;
      }

      &:focus {
        outline: none;
      }

      .thumb-inner-block {
        background: #ccc;
        width: 2px;
        height: 13px;

        &.thumb-inner-block--dragged {
          background: #548bf4;
        }
      }
    }
  }

  &.range-wrapper--vertical {
    height: 100%;

    .range-label {
      position: absolute;
      top: -5px;
      left: -75px;
    }

    .range-slider {
      flex-direction: column;
      width: 40px;
      height: 100%;

      .slider-track {
        height: 100%;
      }

      .slider-background {
        width: 5px;
        height: 100%;
      }

      .thumb-inner-block {
        width: 13px;
        height: 2px;
      }
    }
  }
}

.thumb-label, .thumb-label-refresh {
  display: block;
  position: absolute;
  top: -35px;
}

.thumb-label {
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  padding: 4px;
  border-radius: 4px;
  background: #548bf4;
  white-space: nowrap;
}

.thumb-label-refresh {
  background: var(--green);
  height: 26px;
  width: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  z-index: 1000;

  .svg-inline--fa {
    color: #fff;
  }
}

.th {
  margin: 0;
  padding: 0.6rem;
  min-width: 100px;
  position: relative;
  cursor: pointer;

  &.disableClick {
    pointer-events: none;
  }

  .innerTh {
    display: flex;
    min-height: 21px;

    span {
      height: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }

    .sortIcon {
      flex-shrink: 0;
      margin-left: 5px;
    }
  }
}

.headerWrapper {
  position: relative;
  background: #f3f3f3;
  border-bottom: 1px solid #a9a9a9;

  .resizer {
    display: inline-block;
    width: 20px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(50%);
    z-index: 1;
    touch-action: none;
    cursor: col-resize;

    @media print {
      display: none;
    }
  }

  &:last-child {
    width: 100%;

    .th {
      width: 100% !important;
    }

    .resizer {
      display: none;
    }
  }
}

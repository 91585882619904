.column-status-marker.glyphicon {
  position: absolute;
  top: 5px;
  left: 75px;
}

.column-buttons-group {
  margin-bottom: 0;
  width: 100%;
  align-items: center;
}

.column-status-marker {
  font-size: 25px;
  margin-right: 5px;

  &__info {
    color: #31708f;
  }

  &__danger {
    color: #a94442;
  }

  &__success {
    color: #3c763d;
  }
}

//todo refactor
.label__column-title {
  word-break: break-all;
  hyphens: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0;
  margin-top: 10px;
}

.column-btn-type_button {
  padding-left: 8px !important;
  padding-right: 8px !important;

  &.disabled {
    color: #BDBDBD;
  }
}

.col-column-btn-group_form-group {
  margin-bottom: 0 !important;
}

.case_alerts-popover {
  min-width: 250px;
}

.case_alerts__body {
  padding:0
}

.case_alerts__item {
  padding-top: 7px;
  padding-bottom: 7px;
}

.case_alerts__label {
  text-transform: capitalize;
  margin-right: 2px;
}

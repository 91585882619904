.copy-survey__input {
  display: inline-block;
  vertical-align: middle;
  margin: 0 !important;

  &-text {
    display: inline-block;
    vertical-align: middle;
    margin-left: 7px;
    padding-top: 3px;
  }

  &-label {
    line-height: 34px;
    margin: 0;
  }
}
.actionItem {
  & :global(.rst__actions) {
    margin-right: 6px;
    color: var(--primary);

    &:global(.warning) {
      & :global(.btn-link) {
        color: var(--warning);
      }
    }

    & :global(.btn-link) {
      box-shadow: none;
      padding: 0;

      & > svg {
        width: 17px;
      }
    }
  }

  &:last-child :global(.rst__actions) {
    margin-right: 0;
  }
}

.export-results__toolbar {
  margin-bottom: -10px;

  .disabled-exports-switch {
    align-items: center;
    display: flex;

    label {
      margin-bottom: 0;
      cursor: pointer;
    }
  }

  .form-group {
    margin-bottom: 0;
  }

  .export-results-toolbar-label {
    display: block;
    text-align: right;
    margin: 0;
    padding-top: 7px;
  }

  .select-control-label {
    padding: 0;
  }

  .datepicker-control-label {
    padding-right: 0;
    &.col-md-0 {
      padding-left: 15px;
    }
  }

  .Select-control,
  .Select-multi-value-wrapper {
    height: 27px;
  }

  .Select--single > .Select-control .Select-value {
    line-height: 30px;
  }

  .col-md-3.select-control-label {
    min-height: 30px;
    line-height: 30px;
  }

  .export-results-toolbar-select {
    .vochub-select-control__control, .vochub-select-control__control--is-focused {
      min-height: 28px;
      height: 30px;
    }
  }

  .export-results__toolbar-search {
    height: 31px;
  }
}

.table-action__quarantine-list-icon {

    &.disabled {
        color: var(--gray);
        opacity: .5 !important;
        pointer-events: none;

        svg {
            color: var(--gray);
        }
    }
}

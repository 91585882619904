.selectWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}

.inputWrapper {
  margin-top: 8px;
  margin-bottom: 12px;
}

.previewPageWrapper {
  margin-left: -15px;
  margin-right: -15px;
  width: calc(100% + 30px);
  height: calc(100vh - 52px);
  display: flex;
  flex-direction: column;
  position: relative;

  &.fullScreenRunner {
    #VocSignalApp .SandSIV_Feedback_Modal {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      height: auto;
      width: auto;
      transform: none;
    }

    #VocSignalApp .SandSIV_Feedback_Modal_Close {
      display: none;
    }

    .supportMessage {
      width: 100%;
      margin-top: 100px;
      text-align: center;
    }
  }

  .preloaderWrapper {
    background: rgba(255, 255, 255, 0.5);
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }

  .iframeWrapper {
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    position: relative;

    .iframeDevice {
      align-self: flex-start;

      .iframe {
        border: none;
      }

      &.iframeDeviceDesktop {
        align-self: center;

        .iframe {
          border: 1px dashed black;
        }

        :global(.deviceFrameDeviceScreen) {
          display: flex;
        }
      }
    }
  }
}

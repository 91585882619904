.breadCrumbContainer {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #ECF0F6;
  margin-bottom: 24px;

  & .breadCrumbItem {
    display: flex;
    border-bottom: 1px dotted #ECF0F6;
    align-items: flex-end;
    justify-content: space-between;
    height: 46px;
    padding: 6px 0;

    &:last-child {
      height: auto;
    }

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      background-color: white;
    }

    & .title {
      display: flex;
      align-items: center;
      outline: none;
      color: #337ab7;
      overflow: hidden;
      width: auto;

      &:hover {
        color: #22527b;
      }

      &.activeTitle {
        color: #212529;
        cursor: default !important;
        user-select: text;
        text-decoration: none;
        box-shadow: none;
      }

      & .titleStr {
        display: block;
        margin: 0;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-size: 14px;
        line-height: 16px;
      }
    }

    & .configureInformation {
      display: flex;
      flex-shrink: 0;
      justify-content: flex-end;
      align-items: flex-end;

      & .actionButton {
        display: flex;
        min-height: 38px;
        align-items: center;
        cursor: pointer;
        margin-left: 12px;
      }

      & .settingsPopupIcon {
        & svg path {
          fill: #337ab7;
        }
      }
    }

    .drilledDown {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 6px;
      width: 32px;
      height: 32px;

      & svg {
        transform: rotate(90deg);
      }
    }

    & .configurationContainer {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      :global(.select-control-label .select-control-label-text) {
        display: inline-block;
        width: 100%;
        font-size: 11px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      :global(.select-control-label .select-control-label-text:has(+ .star)) {
        width: calc(100% - 20px);
      }

      :global(.select-control-wrapper) {
        width: 200px;
        margin-right: 0;
      }

      & .configurationSelect {
        margin-bottom: 0;
      }

      & .configurationBtnWrapper {
        & > button {
          height: 38px;
        }
      }
    }
  }
}

@media print {
  .breadCrumbContainer {
    display: none;
  }
}

:global(.configureInformationTooltip .tooltip-inner) {
  max-width: 300px !important;
}

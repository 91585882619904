.sensitivity-info {
  position: absolute;
  top: 0;
  left: 75px;
  border-radius: 8px;
  z-index: 30;

  strong {
    margin: 0 5px;
  }
}

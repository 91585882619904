.page__wrapper-share-rights {
  &__loader-wrapper {
    position: absolute;
    z-index: 1;
    background: rgba(255, 255, 255, .8);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
  }
  .survey-share-rights{
    &__controls {
      text-align: right;
      margin-top: 25px;
    }
    &__controls-button {
      margin-right: 10px;
    }
  }
  .voc-select-rights {
    width: 75px;
    &__wrapper {
      width: 75px;
    }
  }
  .voc-selected-value-rights {
    width: 105px;
  }
}

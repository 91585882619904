.export_select-component-options {
  display: flex;
  align-items: center;
  cursor: default;
  padding: {
    right: 5px;
  }

  .exporter-select-options__title {
    line-height: 31px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 260px;

    &-wrapper {
      flex-grow: 1;
      width: 25%;
      padding: 0 8px;
      vertical-align: top;
    }
  }
}

.exporter-select-options__labels-container {
  width: 100%;
  display: inline-flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.exporter-select-options__source-label {
  padding: .2em .6em .3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;
  height: 15px;
  display: inline-block;
  margin-right: 2px;
}



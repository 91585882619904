.settings-form__input-wrapper {
  position: relative;
  .app-form-control__error {
    color: var(--danger) !important;
    font-weight: bold;
    float: right;
  }
}

.voc-setting-field-select__control {
  border-radius: 4px !important;
  border: 1px solid var(--gray) !important;
  position: relative !important;
  min-height: 33px !important;
  margin-bottom: 15px;
}

.voc-setting-field {
  border-radius: 4px;
  border: 1px solid var(--gray);
  position: relative;
  min-height: 33px;
  display: flex;

  ~ .app-form-control__error,
  .app-form-control__error {
    position: absolute;
    color: var(--danger) !important;
    font-weight: bold;
    line-height: normal;
    padding: 0;
    margin: 0;
    font-size: 11px;
    right: 0;
    bottom: -17px;
  }

  &.changed {
    border: 1px solid var(--warning);

    input:focus {
      box-shadow: none;
    }

    textarea:focus {
      box-shadow: none;
    }

    .vochub-select-control__control {
      box-shadow: none;
    }

    .voc-settings-checkbox__container {
      border-right: 1px solid var(--warning);
    }
  }

  &.greyed {
    opacity: 0.5;

    .vochub-select-control__control--is-disabled {
      background-color: transparent !important;
    }

    .voc-settings-checkbox__container {

      .voc-settings-checkbox__checkmark {
        border-color: var(--gray);
      }
    }
  }

  & > .row {
    margin: 0;
    flex: 0 100%;

    .col-md-12 {
      padding: 0;

      .voc-setting-field__select {
        margin-bottom: 0;

        .vochub-select-control__control {
          border: none;
          width: 100%;
        }
      }
    }
  }

  .voc-settings-checkbox__container {
    flex: 1 0;
    padding: 10px 18px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    border-right: 1px solid var(--gray);
    max-width: 52px;

    .voc-checkbox {
      position: relative;
      margin-bottom: 0;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      padding: 0;
      font-size: 14px;
      font-weight: 400;
      text-align: center;
      white-space: nowrap;
      -ms-touch-action: manipulation;
      touch-action: manipulation;
      border-radius: 4px;

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
      }

      &__checkmark {
        position: absolute;
        top: 0;
        left: 0;
      }

      input:checked ~ &__checkmark:after {
        display: block;
      }

      &__checkmark:after {
        content: "";
        position: absolute;
        display: none;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }

    }

    .voc-settings-checkbox {
      position: relative;
      width: 16px;
      height: 16px;
      margin-bottom: 0;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      padding: 0;
      font-size: 14px;
      font-weight: 400;
      text-align: center;
      white-space: nowrap;
      -ms-touch-action: manipulation;
      touch-action: manipulation;
      border-radius: 4px;

      &__checkmark {
        width: 16px;
        height: 16px;
        border: 1px solid var(--primary);
        position: absolute;
        border-radius: 1px;
        top: 0;
        left: 0;

        &:after {
          content: "";
          position: absolute;
          display: none;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
          left: 5px;
          top: 1px;
          width: 5px;
          height: 10px;
          border: 1px solid var(--primary);
          border-width: 0 2px 2px 0;
        }
      }

      input {
        opacity: 0;
        cursor: pointer;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 0;
        left: 0;
      }


      input:checked {

        + .voc-settings-checkbox__checkmark {
          background-color: var(--primary);
          border: 1px solid var(--primary);

          &:after {
            display: block;
            border: 1px solid white;
            border-width: 0 2px 2px 0;
          }
        }
      }
    }

  }

  .voc-setting-field__input {

    &:disabled {
      background-color: transparent !important;
      cursor: not-allowed;
    }

    display: inline-block;
    width: 100%;
    padding: 10px 15px;
    border: none;
    height: 100%;
    border-top-right-radius-radius: 5px;
    border-top-left-radius-radius: 5px;
  }

  .vochub-select-control__value-container {
    padding-left: 15px;
  }

}

.statistic_result__info-block {

  &.visible {
    display: block;
  }

  margin-bottom: 25px;

  p {
    color: #000000;
    font-size: 14px;
    line-height: 24px;
  }

  .statistic_result__info-title {
    font-weight: bolder;
  }
}

.virtual-source_reorder_body-item {
  z-index: 9999;
  max-width: 430px !important;
  box-shadow: none !important;

  &--even {
    background: rgba(0, 0, 0, 0.055) !important;
  }

  &--name_container {
    display: flex;
  }

  &--name {
    max-width: 270px;
    font-weight: 600;
    word-break: break-all;
    display: flex;
    align-items: center;
  }

  &--index {
    min-width: 30px;
    padding-right: 10px;
    display: flex;
    align-items: center;
  }

  &--type {
    display: flex;
    align-items: center;
  }

  &--body {
    min-height: 39px;
    display: flex;
    justify-content: space-between;
  }
}

.modalWrapper {
  margin: 0;

  :global(.row) {
    margin: 0;
    label {
      display: flex;
      flex-direction: column;
    }
  }

  :global(.col-md-12) {
    padding: 0;
  }

  .fieldTitle {
    margin-bottom: 3px;
  }

  .fieldLabel {
    width: 100%;
  }

  .fieldWrapper {
    margin-bottom: 10px;
  }
}

.vochub-select-control {
  margin-bottom: 15px;
}

.voc-select-control {
  &-wrap {
    position: relative;

    &__placeholder {
      position: absolute;
      top: 4px;
      right: 35px;
      bottom: 5px;
      left: 11px;
      display: flex;
      align-items: center;
      padding-top: 3px;
      color: #bcbcbc;
      pointer-events: none;
    }

    &_mine {
      &.isMultiOutside {
        .vochub-select-control__multi-value {
          display: none;
        }

        .vochub-select-control__clear-indicator {
          display: none;
        }

        .vochub-select-control__placeholder {
          display: none;
        }
      }
    }
  }
}

.voc-select-control-wrap_mine {
  width: 70%;
}

.voc-select-control-wrap__btn {
  width: 25%;
  margin-left: 5%;
}

.voc-select-control-wrap_mine,
.voc-select-control-wrap__btn {
  display: inline-block;
}


.vochub-select-control.is-focused:not(.is-open) > .Select-control {
  box-shadow: none;
  border: 1px solid #ccc;
}

.Select-option {
  padding: 4px 10px;
}

div.Select-menu-outer {
  z-index: 9;
  overflow-wrap: break-word;
}

.col-md-3.select-control-label {
  text-align: right;
  vertical-align: middle;
  padding-left: 10px;
  padding-right: 10px;
  min-height: 34px;
  line-height: 34px;
}

.select-control-label__hint {
  color: #9f9fa3;
}

.col-md-3.select-control-label label {
  line-height: normal;
}

.vochub-select-control {
  position: relative;

  .vochub-select-control__single-value {
    color: #636369;
  }

  .Select-multi-value-wrapper {
    position: relative;
    display: block;
    z-index: 1;

    &::after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      top: 0;
      width: 25px;
      cursor: pointer;
    }
  }
}


.vochub-select-control__control--is-disabled {
  background-color: rgba(0, 0, 0, 0.08) !important;

  .vochub-select-control__single-value {
    color: #495057;
  }

  //.vochub-select-control__indicators {
  //  opacity: 0;
  //}
}

.vochub-select-control.is-open {
  .Select-multi-value-wrapper {

    &::after {
      z-index: -1;
    }
  }
}

.vochub-select-control.multiselect {
  .Select-value {
    color: #333;
    font-size: 14px;
    margin-top: 3px;
    border-color: #ccc;
  }

  .Select-value-icon {
    font-weight: 700;
    padding: 2px 8px;
    font-size: 20px;
    line-height: 18px;
  }

  .Select-value-label {
    background: #fff;
    border-color: #ccc;
    padding: 3px 8px;
  }

  .Select-placeholder {
    margin-top: 2px;
  }
}

.vochub-select-control__menu {
  .vochub-select-control__option {
    overflow-wrap: break-word;
    color: #636369;

    &:hover {
      background: #DEEBFF;
    }

    &--is-focused {
      background: none;
    }

    &--is-selected {
      background-color: #DEEBFF;
    }

    &.Select-menu {
      &__small {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }
}

.vochub-select-control {
  &.no-margin {
    margin: 0;
  }

  .vochub-select-control__indicators {
    svg {
      width: 16px;
      height: 16px;
    }

    .vochub-select-control__clear-indicator {
      padding: 4px;
    }

    .vochub-select-control__dropdown-indicator {
      padding: 9px;
    }
  }

  .vochub-select-control__control {
    background-color: #fff;
    border: 1px solid #dddddd;

    &.vochub-select-control__control--is-focused {
      border-color: #66afe9;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
    }
  }

  .vochub-select-control__multi-value {
    color: #333;
    font-size: 14px;
    margin-top: 3px;
    background-color: rgba(0, 126, 255, 0.08);
    border-radius: 2px;
    border: 1px solid rgba(0, 126, 255, 0.24);

    & .vochub-select-control__multi-value__remove {
      cursor: pointer;
      border-bottom-left-radius: 2px;
      border-top-left-radius: 2px;
      background-color: rgba(0, 126, 255, 0.08);
      font-weight: bold;
      padding: 2px 8px;
      font-size: 20px;
      line-height: 18px;

      &:hover {
        color: var(--red);
        background: #fff;
      }
    }

    & .vochub-select-control__multi-value__label {
      background: #fff;
      border-color: #ccc;
      padding: 3px 8px;
      white-space: normal;
    }
  }
}

.custom-option-dot {
  position: relative;
  padding-left: 16px;

  &:before {
    content: '';
    position: absolute;
    display: block;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    top: 50%;
    left: 7px;
    transform: translate(-7px, -50%);
  }

  &_active:before {
    background: #5cb85c;
  }

  &_paused:before {
    background: #f0ad4e;
  }

  &_stopped:before {
    background: #5bc0de;
  }

  &_deleted:before {
    background: #d9534f;
  }
}

.voc-select-control-labels {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 16px -2px 16px;
  overflow: auto;
  max-height: 95px;

  &__item {
    position: relative;
    height: 20px;
    max-width: calc(100% - 4px);
    padding: 0 16px 0 8px;
    margin: 2px;
    border: 1px solid #E5E5E5;
    border-radius: 4px;
    font-size: 12px;
    line-height: 20px;
    color: #000;
    background: #ECF0F6;

    &.voc-select-control-labels__item--flex {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    .voc-select-control-labels__item-label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex-shrink: 1;
    }

    .voc-select-control-labels__item-additional {
      margin-left: 3px;
      flex-shrink: 0;
    }

    &-text {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    button.voc-select-control-labels__item-delete {
      position: absolute;
      right: 3px;
      top: 0;
      bottom: 0;
      width: 8px;
      height: 8px;
      padding: 0;
      margin: auto;
      border: none;
      background: none;

      &:before,
      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        width: 100%;
        height: 1px;
        background-color: #53565A;
        transform-origin: center;
      }

      &:before {
        transform: rotate(-45deg);
      }

      &:after {
        transform: rotate(45deg);
      }
    }
  }

  .voc-select-control-labels__items-list {
    justify-content: center;
    margin-top: 10px;
    width: 100%;
    background-color: #8080800f;
    border-radius: 5px;
    display: flex;
    font-size: 1rem;
  }
}


.rangeWrapper {
  margin-bottom: 20px;

  .rangeSliderWrapper {
    display: flex;

    span {
      display: flex;
      justify-content: center;
      width: 25px;
      margin-top: -1px;
    }

    :global(.rc-slider) {
      margin: 0 10px;
    }
  }
}

.export-list_old-exporter-icon {
  margin-right: 10px;
  svg {
    color: red;
  }
}

.export-list_old-exporter-name {
  cursor: pointer;
  color: #212529;
}

.voc-datepicker {
  .react-datepicker {
    font-size: 1em;
  }
  .react-datepicker__header {
    padding-top: 0.8em;
  }
  .react-datepicker__month {
    margin: 0.4em 1em;
  }
  .react-datepicker__day-name, .react-datepicker__day {
    width: 1.9em;
    line-height: 1.9em;
    margin: 0.166em;
  }
  .react-datepicker__current-month {
    font-size: 1em;
    line-height: 17px;
  }
  .react-datepicker__navigation {
    top: 0.8em;
  }

  .react-datepicker__navigation.react-datepicker__navigation--years-upcoming,
  .react-datepicker__navigation.react-datepicker__navigation--years-previous {
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
  }

  .react-datepicker__navigation.react-datepicker__navigation--years-upcoming {
    transform: rotate(-135deg);
  }

  .react-datepicker__navigation.react-datepicker__navigation--years-previous {
    transform: rotate(45deg);
  }

  .react-datepicker__year-option:hover {
    .react-datepicker__navigation--years-upcoming, .react-datepicker__navigation--years-previous {
      border-color: #b3b3b3;
    }
  }

  .react-datepicker__navigation-icon--previous::before,
  .react-datepicker__navigation-icon--next::before {
    box-sizing: content-box;
  }

  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    width: 100%;
  }

  //Custom styles
  .react-datepicker__header {
    background-color: white;
    border-bottom: none;
  }

  .react-datepicker__triangle:before {
    border-bottom-color: #dad9d9 !important;
  }

  .react-datepicker__triangle,
  .react-datepicker__triangle:after {
    border-bottom-color: white !important;
  }

  .react-datepicker__day--selected {
    font-weight: normal;
  }

  .react-datepicker {
    box-shadow: 0 6px 12px rgba(0,0,0,.175);
    border-color: #dad9d9;
    border-radius: 5px;
  }

  .react-datepicker__day-name {
    font-weight: bold;
  }

  .col-md-3.datepicker-control-label {
    text-align: right;
    vertical-align: middle;
    padding-left: 10px;
    padding-right: 10px;
    min-height: 34px;
    line-height: 34px;
  }

  .col-md-3.datepicker-control-label label {
    line-height: normal;
  }

  .react-datepicker-popper {
    z-index: 9;
  }

  //fix arrows after update react-datepicker to ^4.x

  .react-datepicker__navigation {
    border: solid #ccc;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    top: 15px;

    &--next {
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      right: 15px;
    }

    &--previous {
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
      left: 15px;
    }

    &:hover {
      border-color: #b3b3b3;
    }
  }

  .react-datepicker__month-read-view--down-arrow, .react-datepicker__year-read-view--down-arrow {
    border: solid #ccc;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 2.5px;
    transform: rotate(45deg);
    width: 0;
    height: 0;
    margin-left: 20px;
    top: 5px;

    &:before {
      display: none;
    }
  }

  .react-datepicker__year-read-view {
    &:hover {
      .react-datepicker__year-read-view--down-arrow {
        border-color: #b3b3b3;
      }
    }
  }

  .react-datepicker__month-read-view {
    &:hover {
      .react-datepicker__month-read-view--down-arrow {
        border-color: #b3b3b3;
      }
    }
  }

  .react-datepicker__header__dropdown {
    display: flex;
    justify-content: center;
  }

  .react-datepicker__month-dropdown-container {
    margin: 0;
  }

  //fix arrows after update react-datepicker to ^4.x

  .react-datepicker__navigation {
    border: solid #ccc;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    top: 15px;
    height: 9px;
    width: 9px;

    &--next {
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      right: 15px;
    }

    &--previous {
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
      left: 15px;
    }

    &:hover {
      border-color: #b3b3b3;
    }
  }

  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__year-read-view--down-arrow {
    border: solid #ccc;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 2.5px;
    transform: rotate(45deg);
    width: 0;
    height: 0;
    top: 5px;

    &:before {
      display: none;
    }
  }

  .react-datepicker__year-read-view {
    &:hover {
      .react-datepicker__year-read-view--down-arrow {
        border-color: #b3b3b3;
      }
    }
  }

  .react-datepicker__month-read-view {
    &:hover {
      .react-datepicker__month-read-view--down-arrow {
        border-color: #b3b3b3;
      }
    }
  }

  .react-datepicker__header__dropdown {
    display: flex;
    justify-content: center;
  }
}

.dictionaries-modal__wrapper {
  height: 100px;

  .no-selected-placeholder {
    justify-content: center;
    margin-top: 10px;
    width: 100%;
    background-color: #8080800f;
    border-radius: 5px;
    display: flex;
    font-size: 1rem;
  }
}

.dictionaries-modal__form {
  .dictionaries-modal__select-label {
    width: 100%;
    height: 80px;

    .dictionaries-modal__select {
      margin-top: 8px;
    }

    .row {
      .select-control-label {
        display: none;
      }

      .col-md-9 {
        flex: 0 0 100%;
        max-width: 100%;

        p {
          margin: 24px !important;
          text-align: center;
        }
      }
    }

  }

  .dictionaries__radio-buttons {
    margin-top: 24px;
    padding-left: 20px;

    label {
      width: unset;

      &:first-child {
        margin-right: 30px;
      }
    }
  }
}

.dictionaries-modal__dictionaries {
  display: flex;
  align-items: baseline;
  max-height: 100px;
  overflow: auto;

  .dictionaries-modal__title {
    min-width: 70px;
  }

  .dictionaries-modal__list {
    padding-left: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;

    .dictionaries-modal__list-item {
      display: flex;
      border: 1px solid #E5E5E5;
      border-radius: 4px;
      background: #ECF0F6;
      font-size: 12px;
      padding: 0 5px;
      margin: 2px;
      align-items: center;

      .dictionaries-modal__cross-icon {
        width: 8px !important;
        height: 8px;
        cursor: pointer;
        margin-left: 2px;

        path {
          fill: var(--secondary);
        }
      }
    }
  }
}

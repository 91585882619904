@import '~/styles/themes/variables';

.pasteWrapper {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  opacity: .4;
  border: 1px dashed $blue;
  flex-direction: column;

  // in case of copy behaviour
  & > svg path {
    transition: opacity .5s;
  }

  &:hover {
    &.isCopy {
      cursor: pointer;
    }

    & > svg path {
      opacity: 0.5;
    }
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.3;
    border-color: #afabab;
  }

  & .textWithSvg,
  & .textWithoutSvg {
    width: 100%;
    height: 50%;
    overflow: hidden;
    margin: 0;
    font-size: 20px;
    text-align: center;
    white-space: normal;
    overflow-wrap: break-word;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    & > svg path,
    & > span {
      transition: opacity .5s;
    }

    &:hover {
      & > svg path,
      & > span {
        opacity: 0.5;
      }
    }

    & * {
      pointer-events: none;
      user-select: none;
    }
  }

  & .textWithoutSvg {
    font-size: 12px;
    padding: 0 10px;
    height: 100%;

    & svg {
      display: none;
    }
  }
}

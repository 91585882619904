.app-form-control__container.with-error {
  position: relative;

  .app-form-control__error {
    position: absolute;
    right: 0;
  }
}

.form-group__textarea > textarea {
  height: auto !important;
}

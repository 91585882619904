@import "../../../../../../styles/base/api";

.question-warning {
  margin-bottom: 14px !important;
}

.questions-util-button-group {
  @extend %clearfix;
  display: inline-block;
  font-size: 0;

  .disabled {
    color: var(--gray) !important;
    opacity: .5;
    pointer-events: none;

    svg {
      color: var(--gray);
    }
  }

  .util-button {
    display: inline-block;
    position: relative;
    padding: 6px 12px;
    color: #000;

    &--graph {
      padding: 10px 0 7px;

      .disabled {
        pointer-events: none;
        opacity: .65;
      }
    }

    &:first-child {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }

    &:last-child {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      margin-left: -1px;
    }

    &__icon {
      font-size: 16px;
      min-width: 16px;
      margin-right: 4px;

      &--lock {
        font-size: 14px;
      }
    }
  }
}

.remove-margin {
  margin: 0;
}

.questions__sortable-wrapper {
  min-height: 100px;
  position: relative;
  display: flex;
}

.survey_questions__wrapper {
  .panel__header--buttons {
    position: sticky;
    top: 52px;
    z-index: 5;
    background: #f7f7f7;
    opacity: 1;
  }
}

.questions__loading-wrapper {
  top: 0;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  z-index: 1;
  opacity: 0.3;

  .questions__loading-wrapper-narrow {
    left: 0;
    right: 50%;
  }

  & > .loader {
    left: 50%;
    top: 50%;
    margin: 0;
    transform: translate(-50%, -50%);
  }
}
.extra_container_height {
  min-height: 850px !important;
}

.loading-placeholder {
  display: block;
  text-align: center;
  width: 100%;
  max-width: none;
  color: #777;
}

.survey-navigation-wrapper {
  position: relative;
  z-index: 1;

  .survey-navigation {
    display: inline-block;
    width: 100%;
    margin-bottom: 20px;
    position: relative;
    z-index: 0;

    .voc-tabs__list-item {
      padding: 10px;
    }
  }

  .survey-navigation__button {
    float: right;
    padding: 9px 12px;
    font-size: 0;
  }

  .survey-navigation__button-text {
    display: inline-block;
    vertical-align: middle;
    font-size: 14px;
    font-weight: 400;
  }

  .survey-navigation__button-icon {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    font-size: 13px;
  }
}

.voc-survey-modal,
.voc-status-modal {
  .loader {
    margin: 0 auto;
  }
}

.survey-common-settings__form {
  position: relative;

  .card-body {
    padding-right: 0;
  }

  .survey-settings__loader {
    position: absolute;
    z-index: 2;
    left: 0;
    right: -10px;
    bottom: -10px;
    top: 0;
    margin: auto;
    height: calc(100% + 10px);
    background: #fff;
  }

  .loader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.question__controls {
  text-align: right;
  margin-bottom: 50px;
}

.question__controls-button {
  margin-right: 10px;

  &.disabled {
    background: var(--gray) !important;
    border-color: var(--gray) !important;
  }

  &:last-child {
    margin-right: 0;
  }
}

.question__controls-icon {
  margin-right: 5px;
}

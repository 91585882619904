.mine-condition-row__box {
  padding-bottom: 26px;

  &:last-child {
    margin-bottom: 0;
  }

  .defaultTheme .voc-checkbox__label {
    display: inline-block;
    margin-left: 40px;
    margin-top: 1px;
    font-weight: 700;
  }
}

.classification-group-btn {
  margin-bottom: 26px;

  .defaultTheme.radio-button .voc-checkbox {
    margin-top: 8px;
  }

  .classification-btn-add {
    text-align: right;
  }

  svg {
    margin-right: 5px;
  }

  .btn-link:hover {
    text-decoration: none;
  }
}

.mine-condition-wrapper {
  max-height: 390px;
  min-height: 105px;
  overflow-x: hidden;
}

.mine-condition__icon {
  margin-top: 25px;
}

.classification-overlay {
  position: absolute;
  background: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
  z-index: 999;
  top: 0;
  left: 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 20px;
  color: #393e41;
}

.col-md-1.mine-condition-row__cross {
  padding-left: 0;
}

.classifier-multi-select__row {
  margin-bottom: 15px;

  .voc-select-control-wrap_mine {
    width: 100%;
  }

  .vochub-select-control {
    margin-bottom: 0;
  }
}

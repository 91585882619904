.channel__label {
  width: 43px;
  display: inline-block;
  text-align: center;
  font-size: 87%;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  font-weight: 700;
  line-height: 1;
  color: #fff;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;
  background-color: #5bc0de;
  padding: .5em .6em .3em;
}

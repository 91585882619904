.header {
  border-bottom: 1px solid #ECF0F6;
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  align-items: center;
  height: 73px;

  &.withOffset {
    margin-bottom: 20px;
  }

  @media print {
    display: none;
  }

  .headerNavigation {
    display: flex;

    .dashboardTypeButton {
      @media (max-width: 600px) {
        span {
          display: none;
        }
      }
    }

    .createDashboardButton {
      margin-left: 10px;
    }

    .arrowsButton {
      margin-right: 10px;

      @media (max-width: 767px) {
        display: none;
      }

    }
  }

  .headerBread {
    display: flex;
    align-items: center;

    .headerBreadIcon {
      margin: 0 15px;

      @media (max-width: 500px) {
        margin: 0 7px;
      }
    }

    .title {
      font-size: 24px;
      margin-bottom: 0;

      @media (max-width: 500px) {
        font-size: 17px;
      }

      a {
        color: inherit;
      }
    }

    .subTitle {
      font-size: 18px;
      line-height: 24px;
      color: #53565A;
      margin: 0;

      @media (max-width: 500px) {
        font-size: 12px;
      }
    }
  }
}

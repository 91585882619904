.voc-toasts__item {
  padding: 10px;
  border: 1px solid;
  color: #FFF;
  cursor: pointer;
  margin-bottom: 4px;
  user-select: none;

  &:last-child {
    margin-bottom: 0;
  }

  &.error {
    //background-color: var(--danger);
    //border-color: var(--danger);
    background-color: #ff8181;
    border: 1px solid #e25353;
    color: #fff;
  }

  &.warning {
    //background-color: var(--warning);
    //border-color: var(--warning);
    background-color: #ffeaa8;
    border: 1px solid #ffc237;
    color: #826200;
  }

  &.success {
    //background-color: var(--success);
    //border-color: var(--success);
    background-color: #bcf5bc;
    border: 1px solid #7cdd77;
    color: #006400;
  }

  &.info {
    //background-color: var(--info);
    //border-color: var(--info);
    background-color: #78c5e7;
    border: 1px solid #3badd6;
    color: #fff;
  }

  &.flip-enter {
    backface-visibility: visible !important;
    animation-name: flipInX;
    animation-fill-mode: both;
    animation-duration: .75s;
  }

  &.flip-exit {
    backface-visibility: visible !important;
    animation-name: flipOutX;
    animation-fill-mode: both;
    animation-duration: .75s;
  }

  &-text {
    width: 95%;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    overflow-wrap: break-word;
    hyphens: auto;
    padding: 0 0 0 10px;
    margin: 0;
    white-space: pre-wrap;
  }

  &-icon {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    padding-left: 5px;
    width: 5%;
  }
}

/*Code that we need*/
@-webkit-keyframes flipInX {
  0% {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transition-timing-function: ease-in;
    opacity: 0;
  }

  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transition-timing-function: ease-in;
  }

  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }

  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }

  100% {
    transform: perspective(400px);
  }
}

@keyframes flipInX {
  0% {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transition-timing-function: ease-in;
    opacity: 0;
  }

  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transition-timing-function: ease-in;
  }

  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }

  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }

  100% {
    transform: perspective(400px);
  }
}

@-webkit-keyframes flipOutX {
  0% {
    transform: perspective(400px);
  }

  30% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }

  100% {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}

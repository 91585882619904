.modalWrapper {
  margin: 0;

  :global(.row) {
    margin: 0;
    label {
      display: flex;
      flex-direction: column;
    }
  }

  :global(.col-md-12) {
    padding: 0;
  }

  .fieldTitle {
    margin-bottom: 3px;
  }

  .select {
    margin-bottom: 0;
  }

  .fieldWrapper {
    margin-bottom: 10px;
    display: block;
  }

  .selectLoader {
    :global(.select-control-label) {
      display: none;
    }

    :global(.col-md-9) {
      padding: 0;
    }

    :global(.form-group) {
      margin-bottom: 11px;
    }
  }
}

.buttonIcon {
  margin-right: 10px;
}

.table_dashboards_wrapper {
  :global(.voc-table__wrapper) {
    overflow: auto;
    min-height: 200px;
  }

  :global(.voc-table_header-container) {
    flex-direction: row-reverse;
    justify-content: end;
  }

  :global(.voc-table_header-search) {
    margin-right: 0;
    margin-left: 15px;

    &:global(.form-control) {
      max-width: 350px;
      width: 100%;
    }
  }

  :global(.voc-table) {
    min-width: 930px;
  }

  :global(.voc-table_header-new-filters) {
    width: 100%;
  }

  .customTableHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .favoritesSwitch {
      margin: 0;
      font-weight: 400;
    }
  }
}

.voc-select-rights__wrapper,
.voc-selected-value-rights__wrapper {
  position: absolute;
  top: 0;

  .vochub-select-control__single-value {
    color: var(--gray);
    cursor: pointer;
  }

  .vochub-select-control__control {
    border: none !important;
    background: none !important;
    box-shadow: none !important;
  }
}

.voc-selected-value-rights__wrapper {
  right: 0;

  .voc-selected-value-rights {
    width: 95px;
  }
}

.voc-select-rights__wrapper {
  right: 36px;

  .voc-select-rights {
    width: 65px;
  }
}

.invite-multi-select__row {
  margin-bottom: 0 !important;

  @media (max-width: 829px) {
    .selctTable {
      margin-top: 40px;
    }
  }

}

.voc-select-value__wrapper {
  overflow-y: auto;
  max-height: 200px;

  .voc-select-value__item {
    position: relative;
    height: 38px;
    display: flex;
    flex-direction: row;

    .voc-select-value__icon {
      margin: 10px 0;
      font-size: 18px;
      width: 22px;
      color: #53565A;
    }

    .voc-select-value__label {
      width: calc(100% - 160px);
      margin: 9px 11px;
      white-space: nowrap;
      overflow: hidden;
      display: inline-block;
      text-overflow: ellipsis;
    }
  }

  .vochub-select-control__indicator-separator {
    display: none;
  }
}

.owner__label {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 38px;
  margin-bottom: 20px;
  justify-content: flex-end;

  svg {
    font-size: 18px;
    color: #53565A;
  }

  .owner-label__icon {
    margin-right: 10px;
  }

  .owner-label__text {
    position: relative;
    width: auto;
    text-align: end;

    span {
      color: var(--gray);
    }
  }
}

.invite-list__placeholder {
  justify-content: center;
  background-color: #8080800f;
  border-radius: 5px;
}
.voc-select-control-wrap_mine {
  width: 100%;
}
.voc-select-control-wrap__btn {
  width: 100%;
}
.voc-select-control-labels {
  margin-top: 0;
}

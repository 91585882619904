#graph {
  width: 100%;
  height: 100%;
  z-index: 2;

  .tooltip {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    background: #53565A;
    color: #fff;
    border-radius: 8px;
    pointer-events: none;
  }

  svg {
    margin-top: 10px;
    height: 100%;
    width: 100%;

    .foreignObject {
      width: 100%;
      height: 100%;

      .empty-data {
        position: absolute;
      }
    }

    #nodes {
      circle {
        fill: var(--blue);
        cursor: pointer;

        &.included {
          fill: var(--green);
        }

        &.excluded {
          fill: var(--red);
        }
      }

      text {
        stroke: #1f1f1f;
        stroke-width: .075;
        font-size: 14px;
      }

      line {
        stroke: #2f2f2f;
      }
    }
  }
}

.queries-table-date {
  width: 240px;

  .popover-body {
    padding: 16px 10px;
  }

  .react-datepicker {
    border: none;
    margin: 2px 0 0 4px;
  }

  .react-datepicker__header {
    background-color: transparent;
    border-bottom: none;
  }
}

.isActiveIconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;

    & svg path {
      fill: orange;
    }
  }

  & svg {
    & path {
      fill: #858484;
    }

    &.activeIcon path {
      fill: orange;
    }
  }
}

.superChartHeader {
  width: 100%;
  border-bottom: 1px solid #E5E5E5;
  display: flex;
  align-items: center;
  padding: 0 16px;
  min-height: 46px;
  justify-content: space-between;
}

.dragCursor {
  cursor: move;
}

.gadgetActionMenu {
  @media print {
    display: none;
  }
}

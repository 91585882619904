.voc-toasts {
  padding: 0;
  margin: 0 auto;
  list-style: none;
  position: fixed;
  top: 6em;
  right: 0;
  left: 0;
  width: 375px;
  z-index: 1051;
}

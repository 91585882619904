.deleted-surveys__button {
  background: #fff;
  border: 1px solid #dddddd;
  color: #000;
  padding: 5px .45rem 5px;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  user-select: none;
  font-weight: 400;
  cursor: pointer;
  white-space: nowrap;
  width: 215px;
  margin-right: 10px;
  box-sizing: border-box;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;

  &:hover {
    color: #333;
    background-color: #e6e6e6;
    .switch__small {
      box-shadow: inset 0 0 0 2px #d8d0d0;
    }
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
  }
}

.deleted-survey__button-switch {
  display: inline-block;
  float: left;
  vertical-align: bottom;
  height: 22px;
}

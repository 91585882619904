.header {
  display: flex;
  height: 52px;
  background: #000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  z-index: 100;
  min-width: 375px;
}


/* */

.hamburger-icon {
  position: relative;
  display: block;
  width: 26px;
  height: 17px;

  .line {
    display: block;
    background: #ecf0f1;
    width: calc(100% - 6px);
    height: 3px;
    position: absolute;
    transition: all 0.4s;
    right: 0;

    &:before {
      content: "";
      display: block;
      width: 3px;
      height: 3px;
      position: absolute;
      background: #fff;
      top: 0;
      left: -6px;
      border-radius: 50%;
      transition: left 0.4s, opacity 0.4s;
    }

    &.line-1 {
      top: 0;
    }

    &.line-2 {
      top: calc(50% - 1.5px);
    }

    &.line-3 {
      top: calc(100% - 3px);
    }
  }
}

.header__menu {
  box-sizing: border-box;
  display: inline-block;
  width: 60px;
  height: 52px;
  padding: 17px 16px;
  cursor: pointer;
  position: fixed;
  z-index: 100;

  &.active {
    .line-1,
    .line-3 {
      width: 100%;

      &:before {
        left: 0;
        opacity: 0;
      }
    }

    .line-1 {
      transform: translateY(7px) translateX(0) rotate(45deg);
    }

    .line-2 {
      opacity: 0;
    }

    .line-3 {
      transform: translateY(-7px) translateX(0) rotate(-45deg);
    }
  }
}

/* */
.header__right-section {
  order: 2;
}

.header__logo {
  display: inline-block;
  padding: 16px 5px;
  box-sizing: border-box;
  margin-left: auto;
  order: 2;

  & > img {
    display: block;
    height: 19px;
  }
}

@media (min-width: 768px) {

  .header {
    justify-content: space-between;
  }

  .header__logo {
    padding: 13px;
    margin-left: 0;

    & > img {
      height: 24px;
    }
  }

  .header__menu {
    display: none;
  }
}

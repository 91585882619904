.statistic-popover {
  min-width: 250px;
  min-height: 364px;

  .popover {
    z-index: 99;
  }
}

.statistic-popover__body {
  padding:0
}

.statistic-popover__item {
  padding-top: 7px;
  padding-bottom: 7px;
}

.statistic-popover__label {
  text-transform: capitalize;
  margin-right: 2px;
}

.about-page {

  #branch {
    display: block;
    margin: auto;
  }

  #info-background {
    position: absolute;
    top: 0;
    z-index: -1;
    left: 0;
  }

  .info {
    margin: 15px auto 0;
    width: 360px;
    height: 148px;
    position: relative;
    z-index: 0;
  }

  #info p {
    font-size: 14px;
    color: #888888;
  }

  #info-hash {
    position: absolute;
    top: 64px;
    left: 36px;
  }

  #info-build-date {
    position: absolute;
    top: 56px;
    right: 22px;
  }

  #info-commit-date {
    position: absolute;
    top: 56px;
    left: 135px;
  }

  .bottom-titles {
    width: 342px;
    margin: auto;
  }

  .bottom-titles .title {
    float: left;
    margin-left: 37px;
  }

  .title {
    font-size: 18px;
    color: #606060;
    text-transform: uppercase;

    &--top {
      margin-top: 60px;
    }

    &--left {
      margin-left: 170px;
    }

    &--subtop {
      margin-top: 30px;
    }

    &.title--right {
      float: right;
      margin-right: 25px;
    }
  }

  .branch-background {
    position: relative;
    width: 335px;
    background: #00864b;
    height: 2px;
    margin: 0 auto 5px;

    &::after,
    &::before {
      content: '';
      display: block;
      position: absolute;
      height: 10px;
      width: 10px;
      border-radius: 50%;
      background: #00864b;
      top: 50%;
      margin-top: -5px;
    }

    &::before {
      left: -5px;
    }

    &::after {
      right: -5px;
    }
  }

  .jwt-place {
    display: flex;
    background-color: #f4f4f4;
    box-shadow: 0 1px 5px 2px rgba(168, 168, 168, 0.5);
    max-width: 100%;
    margin-bottom: 1rem;

    .jwt-box {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 58px;
      padding: 0 10px;
      width: calc(100% - 48px);

      .jwt-info {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        margin-bottom: 0;
        color: #888888;
        font-size: 11px;
      }
    }

    .jwt-tool-bar {
      display: flex;
      z-index: 2;
      align-items: center;
      justify-content: center;

      .jwt-copy-button {
        cursor: pointer;
        border-radius: 50%;
        height: 38px;
        width: 38px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #337AB7;
        color: #FFFFFF;
        transition: .2s opacity linear, .2s visibility linear;
        padding: 12px;
        font-size: 14px;
        opacity: 1;

        &:hover {
          background-color: #1d5d90;
        }
      }
    }

    &:hover {
      .jwt-box {
        .jwt-info {
          color: #505050;
        }
      }
    }

  }

  .info__list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .info__item {
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 130px;
    height: 130px;
    border-radius: 50%;
    box-shadow: inset 0 -7px 5px 5px rgba(168, 168, 168, .75), 0 -1px 5px 2px rgba(168, 168, 168, .75);
    background: #f4f4f4;
    padding: 10px;
    color: #888888;
    font-size: 14px;
    text-align: center;
    line-height: 110px;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      border-radius: 50%;
      border: 10px solid #fff;
      z-index: 1;
    }

    &:first-child {
      margin-right: -25px;
    }

    &:last-child {
      margin-left: -25px;
      line-height: 133px;
    }

    &:nth-child(2) {
      position: relative;
      width: 150px;
      height: 150px;
      z-index: 2;
      line-height: 155px;
    }
  }

  .info__item-text {
    display: inline-block;
    line-height: 20px;
  }

}

.query-details {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;

  &-pagination {
    margin: auto auto 0;

    .pagination {
      justify-content: center;
    }
  }

  &.query-details_Zero-shot {
    width: inherit;
  }

  .query-details-spinner-long-load-message {
    width: 100%;
    height: 100%;
    z-index: 99;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 120px;
    text-align: center;
    left: 0;
  }

  & &__content-wrapper {
    display: flex;
    width: 100%;
    height: 100%;

    // for Text and Bar (Chart) charts
    &.scrollableList {
      overflow-y: auto;
      margin-left: -12px;
      padding-left: 12px;
    }
  }
}

.thin-spinner__query-details {
  width: 100%;
  height: 100%;
  z-index: 99;
  background-color: rgba(254, 254, 254, 0.7);

  &::before {
    border-color: #428bca;
  }
}

.query-details {
  .empty-data {
    padding: 0;
    height: calc(100% - 61px);
  }

  .word-cloud__section--co-occurrence, .word-cloud__section--correlation {
    .empty-data {
      height: 100%;
    }

    .thumb-label {
      top: -61px;
    }

    .thumb-label-refresh {
      top: -58px;
    }
  }

  .query-details-chart__item, .word-cloud__section .button-place {
    .btns-wrapper {
      display: flex;
      justify-content: flex-start;
      min-width: 165px;

      .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 24px;
        padding: 0 5px;
        border: 1px solid #E5E5E5;
        border-radius: 4px;
        font-size: 11px;
        line-height: 16px;
        color: #53565A;
        background: #ECF0F6;
        cursor: pointer;

        &:first-child {
          margin-right: 10px;
        }

        .icon {
          margin-right: 5px;
        }
      }

      .active {
        color: #FFFFFF;
        background: #53565A;
        border-color: #53565A;
      }

      .btn:not(.active) {
        &:hover {
          color: #FFFFFF;
          background: #53565A;
          border-color: #53565A;
        }
      }
    }
  }

  .word-cloud__section {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .button-place {
      min-height: 24px;
    }

    .cloud-place {
      height: calc(100% - 85px);

      .empty-data {
        height: 100%;
        padding: 0;
      }
    }

    .ranger-place {
      position: relative;
      margin-top: auto;
      height: 61px;

      &.ranger-place--vertical {
        margin-top: unset;
        height: 100%;
      }
    }
  }
}

.lineChartWrapper {
  position: relative;
  overflow: hidden;
  display: flex;
  flex: 1;
  flex-direction: column;

  :global(.circle.interactive) {
    cursor: pointer;

    &:focus-visible {
      outline: auto 2px var(--primary-hover) !important;
    }
  }
}

.lineGroup {
  text-anchor: middle;
  pointer-events: auto;
  transition: opacity 0.3s;
}

.circleNumber {
  font-size: 12px;
  pointer-events: none;
}

.transparentCircle {
  fill: rgba(0,0,0,0);
  position: relative;
  z-index: -555;
  display: none;
}

.export-results__checkbox-container {
  display: block;
  position: relative;
}

.export-results__checkbox-container {
  .export-results__checkbox-icon {
    margin-left: -1px;
  }

  .export-results__checkbox {
    position: static;
    margin-top: 0;
    margin-left: 0;
    cursor: pointer;
  }
}


.topic {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid hsl(0,0%,80%);
  cursor: pointer;
  border-radius: 5px;
  margin: 5px;
  position: relative;

  &.active {
    background: #e4fbd4;
    border-color: #c4d9b7;
  }

  &.activeMined {
    background: #e4fbd4;
    border-color: #c4d9b7;
  }

  &.activeSaved {
    background: #dae8fc;
    border-color: #acc2e2;
  }

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 10px;
    z-index: 2;
    user-select: none;
  }

  .topicBackground {
    cursor: pointer;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
  }

  .topicCheckbox {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: -4px;
    z-index: 2;
  }
}

.parser-form__group {
  .form-group {
    margin-bottom: 0 !important;
  }
  .star {
    color: red;
  }
  .parser-form__checkbox {
    margin-top: 35px;
    margin-left: 15px;
    width: 100%;
  }

  .vochub-select-control__placeholder {
    white-space: pre;
  }
}

.parser-form__buttons-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
}

.parser-form__textarea {
  min-height: 100px;
  & > textarea {
    min-height: 100px;
  }
  height: auto !important;
}

.parser_form__container {
  padding: 15px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  background-color: #f7f7f7;
}

.parser-form__cvg_icon {
  margin-right: 5px;
}

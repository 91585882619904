.controlGlobalLangsWrapper {
  padding-left: 0;
  margin-bottom: 20px;
  width: 340px;
  margin-left: -10px;

  :global(.template-header__selector) {
    margin: 0;
  }

  :global(.enable-language__box) {
    padding-left: 0;
  }
}

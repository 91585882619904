/* Helpers */

.quarter-vertical-padding {
  padding: 25% 0;
}

.voc-hub-intrinsic-vertical-padding {
  padding: 20px 0;
}

.btn-fake {
  visibility: hidden;
  width: 34px;
  box-sizing: border-box;
}

.non-resizable {
  resize: none;
}

.nowrap {
  white-space: nowrap;
}

.no-gutter {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.no-gutter-left {
  padding-left: 0 !important;
}

.no-gutter-right {
  padding-right: 0 !important;
}

.table-hover > tbody > tr.no-hover:hover {
  background-color: transparent !important;
}

.table-hover > tbody > tr.no-hover.bg-success:hover {
  background-color: #dff0d8 !important;
}

.feature-unavailable {
  font-size: 22px;
  font-weight: bold;
  line-height: 110px;
  width: 100%;
  height: 110px;
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: center;
  color: slategray;
  background-color: lightgray;
}

.favorite-field input {
  background: #d9edf7;
}

.favorite-field .remove-synonym {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.3;
  box-shadow: none;
}

.selectize-control.single .item {
  width: 96%;
}

.font-bold {
  font-weight: bold;
}

.disable-text-selection {
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
}

/* Ladda */

.ladda-button:focus .ladda-spinner,
.ladda-button:focus .ladda-progress {
  display: none;
}

.ladda-button[data-loading]:focus .ladda-spinner,
.ladda-button[data-loading]:focus .ladda-progress {
  display: block;
}

.ladda-button {
  overflow: visible !important;
}

.ladda-button .ladda-spinner {
  margin-top: 0 !important;
  margin-left: 0 !important;
}






//Todo rewrite styles
/* Spinner */
.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
}

.loader:before,
.loader:after {
  position: absolute;
  content: "";
}

.loader:before {
  top: -0.1em;
  left: -0.1em;
  width: 5.2em;
  height: 10.2em;
  transform-origin: 5.2em 5.1em;
  animation: load2 2s infinite ease 1.5s;
  border-radius: 10.2em 0 0 10.2em;
  background: white;
}

.loader {
  font-size: 11px;
  position: relative;
  width: 10em;
  height: 10em;
  margin: 5em auto;
  text-indent: -99999em;
  box-shadow: inset 0 0 0 1em #428bca;

  &:active {
    box-shadow: inset 0 0 0 1em #428bca;
  }
}

.loader:after {
  top: -0.1em;
  left: 5.1em;
  width: 5.2em;
  height: 10.2em;
  transform-origin: 0 5.1em;
  animation: load2 2s infinite ease;
  border-radius: 0 10.2em 10.2em 0;
  background: white;
}

@-webkit-keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


/* Selectize */

.selectize-dropdown {
  z-index: 1060 !important;
}

/* Fixes */

.panel-collapse .table > thead > tr > th:nth-child(1),
.panel-collapse .table > tbody > tr > td:nth-child(1) {
  padding-left: 15px;
}

table .progress {
  height: 10px;
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}

.nav-pills li.active span.glyphicons:before {
  color: #ffffff;
}

.nav li.disabled span.glyphicons:before {
  color: #999999;
}

.popover.fade {
  z-index: 999;
}

.popover.fade.in {
  z-index: 1030;
}

.nowrap {
  white-space: nowrap;
}

:focus {
  outline: none !important;
  box-shadow: none;
}

:active {
  outline: none !important;
  box-shadow: none;
}

::-moz-focus-inner {
  border: 0 !important;
}

.btn .badge {
  vertical-align: baseline;
}

.badge-label {
  text-transform: capitalize;
}

.nav-pills li span.glyphicons:before {
  color: #428bca;
}

.navbar .btn-link:hover {
  color: #777777;
}

/* Dropdown submenu */
.dropdown-submenu {
  position: relative;
}

.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
  -webkit-border-radius: 0 6px 6px 6px;
  -moz-border-radius: 0 6px 6px 6px;
  border-radius: 0 6px 6px 6px;
}

.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}

.dropdown-submenu > a:after {
  display: block;
  float: right;
  width: 0;
  height: 0;
  margin-top: 5px;
  margin-right: -10px;
  content: " ";
  border-width: 5px 0 5px 5px;
  border-style: solid;
  border-color: transparent;
  border-left-color: #cccccc;
}

.dropdown-submenu:hover > a:after {
  border-left-color: #ffffff;
}

.dropdown-submenu.pull-left {
  float: none;
}

.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
  -webkit-border-radius: 6px 0 6px 6px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px;
}

*[disabled],
*:disabled,
*.disabled {
  pointer-events: none;
}

/* nouislider */
.noUi-horizontal .noUi-handle {
  top: -13px !important;
  left: -15px !important;
  width: 28px !important;
  height: 28px !important;
  border: none !important;
  border-radius: 50% !important;
  background: #337ab7;
  box-shadow: none;
}

.noUi-horizontal .noUi-handle:before,
.noUi-horizontal .noUi-handle:after {
  background: none !important;
}

.noUi-background {
  background: #dddddd !important;
  box-shadow: none !important;
}

.noUi-horizontal {
  height: 5px !important;
  margin-top: 15px !important;
  margin-right: 10px !important;
  margin-bottom: 15px !important;
  margin-left: 16px !important;
  border: none !important;
  background: #dddddd !important;
  box-shadow: none !important;
}

.noUi-pips {
  margin-top: 5px !important;
}

.noUi-value {
  font-size: 10px !important;
  color: black !important;
}

.noUi-connect {
  background: #d9edf7 !important;
  box-shadow: none !important;
}

.unselectable {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.hub-page-head {
  padding: 0;
  margin-bottom: 20px;
}

.hub-page-head .nav-pills {
  border: 0;
}

.hidden-tab {
  display: none;
}

.new-query-button {
  margin: 4px 0;
}

.gadget-control-item {
  .glyphicon {
    color: #979797;
    font-size: 15px;
  }
}

//.red-glyphicon {
//  color: #f74559;
//}
//
//.blue-glyphicon {
//  color: #468ee0;
//}
//
//.cyan-glyphicon {
//  color: #34d8fa;
//}
//
//.green-glyphicon {
//  color: #5cb85c;
//}

/*Place for all selectizes of the application*/
.selectize-control.multi .selectize-input > div,
[data-break-text],
.modal-title,
#attribute-filter-form .selectize-input .item,
#attribute-filter-form .selectize-dropdown-content [data-selectable] {
  word-break: break-all;
  hyphens: auto;
}

#attribute-filter-form .selectize-input .item {
  padding-right: 5px;
}

/*List uploads progressBar markup fixes for big count*/
.list-uploads-bar-section {
  width: calc(100% - 34px);
}

.list-uploads-bar-section .count {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: top;
}

.list-uploads-bar-section-cancelled .count {
  width: calc(100% - 63px);
}

.list-uploads-bar-section-pending .count {
  width: calc(100% - 55px);
}

.list-uploads-bar-section-processed .count {
  width: calc(100% - 64px);
}

.list-uploads-bar-section-failed .count {
  width: calc(100% - 43px);
}

.upload-table-actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

#query-edit .selectize-input .item,
#query-edit .selectize-dropdown-content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.selectize-dropdown-content .optgroup .optgroup-header {
  font-weight: bold;
}

.selectize-dropdown-content .optgroup .option {
  padding-left: 20px;
}

.vochub-app-preloader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.vochub-app-preloader__box {
  background: #fff;
  width: 100%;
  z-index: 9999;
  height: 100%;
  position: fixed;
}

@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.pulse {
  animation-name: pulse;
}

[role='tooltip'] {
  word-break: break-all;
  hyphens: auto;
}

.tooltip-inner {
  word-break: break-word;
}

body #nprogress .bar,
body .ngProgressLite .ngProgressLiteBar {
  position: absolute;
  top: 70px;
  z-index: -1;
}

body #nprogress .spinner {
  position: absolute;
  top: 90px;
  right: 1.6%;
}

.panel.panel-with-buttons {
  .panel-heading {
    padding: 0;

    .panel-title {
      display: flex;
      justify-content: space-between;
      width: 100%;

      h5 {
        font-weight: 700;
        padding-left: 15px;
        margin-top: 10px;
      }

      .btn {
        width: 180px;
        max-width: 180px;
        padding: 8px 12px;
        border: 0;
        border-left: 1px solid #dddddd;
        border-radius: 0;
      }
    }
  }
}

well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
  cursor: pointer;
}

.button-icon {
  margin-right: 5px;
}

.address-list-item_part {
  display: inline-block;
  max-width: 135px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  .badge {
    font-size: 73%;
    margin-right: 5px;
  }
}

.address-list_phones {

  .col-12 {
    display: flex;
  }

  .address-list-item_part {
    order: 0;

    .badge {
      margin-right: 5px;

      &:first-child {
        margin-left: 5px;
      }
    }

    span {
      margin-top: 1px;
      display: inline-block;
    }
  }

  .address-list-item_part:first-child {
    order: 1;
  }

}
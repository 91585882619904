.sourceFilter {
  position: relative;

  .filterButton {
    position: absolute;
    border: none;
    background: #dddddd;
    padding: 0;
    height: 30px;
    width: 30px;
    top: 4px;
    left: 4px;
    border-radius: 4px;

    &.filterButtonActive {
      background: #c9c7c7;
    }
  }

  :global(.vochub-select-control__single-value) {
    padding-left: 25px !important;
  }

  :global(.vochub-select-control__placeholder) {
    padding-left: 30px !important;
  }
}

.case_alert-form_modal-body {

  .case_alert_emails__box {
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid #dadada;
  }

  .case_alert_email__icon {
    text-align: center;
    display: block;
    padding: 7px 10px 5px 0;
    cursor: pointer;

    svg {
      color: #979797;
    }
  }
}
.query-details-chart__item {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  background-color: #F2F6FA;
  width: 100%;

  &-progress {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 32px;
    width: 100%;
    padding-left: 16px;
    margin-right: 20px;

    &__title {
      position: relative;
      z-index: 2;
      margin-right: 8px;
      font-size: 14px;
      line-height: 24px;
      color: #000;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 400px;
      white-space: nowrap;
    }

    &__value {
      position: relative;
      z-index: 2;
      font-size: 12px;
      line-height: 16px;
      color: #53565A;
    }

    &__bar {
      position: absolute;
      top: 0;
      left: 0;
      width: auto;
      height: 100%;

      &__percent {
        width: 100%;
        height: 100%;
        background-color: #99D2FD;
        animation-duration: 1s;
        animation-name: animate_progress;
      }
    }
  }
}

@keyframes animate_progress {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

.react-datepicker__header__dropdown {
  margin: 8px 10px 5px 0;
}

.react-datepicker__month-option,
.react-datepicker__year-option {
  border-radius: 0 !important;

  &:hover {
    background-color: #f0f0f0;
  }
}

.react-datepicker__month-dropdown,
.react-datepicker__year-dropdown {
  background: #fff;
  border: 1px solid #dad9d9;
  top: 35px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, .18);

  &:active {
    box-shadow: 0 6px 12px rgba(0, 0, 0, .18);
  }
}

.react-datepicker__month-dropdown {
  padding: 5px 0;
}

.react-datepicker__year-dropdown {
  padding: 3px 0;
}

.react-datepicker__month-option--selected,
.react-datepicker__year-option--selected{
  display: none;
}

.react-datepicker__month-option--selected_month,
.react-datepicker__year-option--selected_year {
  color: #fff;
  background: #2a87d0;

  &:hover {
    background: #1d5d90;
  }
}

.react-datepicker__year-option {
  user-select: none;

  &:first-of-type,
  &:last-of-type {
    &:hover {
      background: transparent !important;
    }
  }

  &:first-of-type {
    padding-top: 2px;

    &:hover .react-datepicker__navigation--years-upcoming {
      border-bottom-color: #b3b3b3 !important;
    }
  }

  &:last-of-type {
    padding-bottom: 2px;

    &:hover .react-datepicker__navigation--years-previous {
      border-top-color: #b3b3b3 !important;
    }
  }
}

.popperClassName {
  z-index: 9999;
}

.exporter-form {
  .star {
    color: red;

  }

  .remove_delimiter_from_value_container {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin-top: 16px;

    .exporter-switch-control__top-Label {
      margin-right: 20px;
    }
  }
}

.export_form-checkbox_group {
  font-size: 1rem;
  font-weight: 800;
  line-height: 1.5;
  color: #212529;
  text-align: left;
}

.exporter_form-question_column_header {
  & > small {
    top: 36px;
  }
}

.exporter_form-middle_block {
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.export_form-select_error {
  margin-bottom: 0;
}

.exporter-switch-control__top-Label {
  margin-top: 3px;
}

.file-upload {
  cursor: pointer;
  background: #fff;
  border: 1px solid #dddddd;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0 .25rem .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  position: relative;

  &.uploaded {
    background-color: #e6e6e6;
  }

  span {
    margin-left: 10px;
  }
}

.audio__section-error {
  color: #9a3b38;
  margin-bottom: 15px;
}

.question__section {

  &--disabled {
    user-select: none;
    .text-field-control-label,
    .box-audio-files {
      filter: blur(2px);
    }

    .card-header,
    .card-body {
      position: relative;
    }

    .col-text-notice {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      margin: auto;
      background: rgba(247, 247, 247, 0.66);

      p {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #6c6c6c;
      }
    }

    .card-body {

      .row:after {
        content: '';
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,.03);
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}
.question_audio_label--error {
  margin-top: 15px;
}


.tabsWrapper {
  width: 100%;

  .tabsSelectorWrapper {
    border-bottom: 1px solid #dee2e6;
    margin-bottom: 24px;

    .tabButton {
      background: transparent;
      border: none;
      padding: 8px 14px;

      font-size: 14px;
      line-height: 24px;
      cursor: pointer;
      color: #A0A0A2;
      border-bottom: 1px solid transparent;
      margin-bottom: -1px;

      @media (max-width: 400px) {
        font-size: 12px;
      }

      &:disabled {
        color: var(--new-gray);
      }

      &:hover, &.tabButtonActive {
        color: #212529;
        border-bottom: 1px solid #212529;
      }
    }
  }
}

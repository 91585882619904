.actionWrapper {
  display: flex;
  flex-direction: row;

  & .actionButton {
    height: 32px;
    width: 32px;
    margin-left: 8px;
    padding: 2px 5px;

    & svg {
      pointer-events: none;
    }
  }
}

.popoverWrapper {
  :global(.popover) {
    & .cutGadget, .copyGadget, .moveToSave {
      @media (max-width: 1069px) {
        display: none;
      }
    }
  }
}

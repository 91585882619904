.collapse-section__info-section {

  .unClickable {
    pointer-events: none;
  }

  .visible {
    display: inline;
  }

  .hidden {
    display: none;
  }

  &.card {

    border-radius: unset;
    border: none;


    .card-header {
      border-bottom: none;
      border-radius: 4px 4px 0 0;
      background-color: #f5f5f5;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      user-select: none;
      padding: 0.75rem 1.25rem 0.40rem;

      &.no-collapse {
        user-select: initial;
        cursor: default;
      }

      .loader-wrapper {
        transform: translateY(-20px);
        width: 16px;
      }

      h5 {
        display: inline-block;
        position: relative;
        margin-bottom: 0;
        color: #000000;
        font-size: 16px;
        line-height: 18px;
        font-weight: bold;


        img {
          position: absolute;
          width: 11px;
          top: 6px;
          right: -22px;
          transition: 0.2ms;
        }

        &.open {
          img {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}

.collapse__header {
  max-width: calc(100% - 185px);
}

.collapse__header-title {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;

  span {
    display: inline-block;
  }
}

.collapse__header-title-addon {
  color: var(--gray);
  margin-right: 5px;
}

.collapse__header-title-text.fullwidth {
  width: 100%;
}

.social-source-modal__source-title {
  user-select: text;
}

.social-source-modal__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  .social-source-modal__section {
    width: 100%;
    display: flex;

    .social-source-modal__left-section-part {
      .social-source-modal__input-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        .form-check-input {
          cursor: pointer;
          position: unset;
          margin: 0;
        }

        label {
          cursor: pointer;
          padding-left: 10px;
          margin-bottom: 0;
        }
      }

      .social-source-modal__sync-btn {
        margin-top: 20px;
      }
    }

    .social-source-modal__right-section-part {
      margin-left: 60px;

      .social-source-modal__right-section-content {
        &:last-child {
          margin-top: 25px;
        }

        label {
          margin-bottom: 0;
        }

        span {
          margin-left: 5px;

          &.social-source-modal__right-section-content-date {
            user-select: text;
          }
        }
      }
    }
  }

  .social-source-modal__collapse {
    margin-top: 25px;
    width: 100%;
    position: relative;

    .card-header {
      position: relative;

      .collapse__header {
        position: unset;
        font-size: 14px;

        .loader-wrapper {
          top: 50%;
          transform: translateY(-50%);
          position: absolute;
          right: 1rem;
        }

        img {
          top: 50%;
          right: 1.25rem;
          transform: translateY(-50%);
        }

        &.open {
          img {
            transform: translateY(-50%) rotate(180deg);
          }
        }
      }
    }

    .card-body {
      padding: 0 1.25rem;
      margin-top: 1.8rem;
      height: 300px;
      overflow-y: auto;

      &:hover .social-source-modal__copy-button {
        pointer-events: unset;
        opacity: .7;
        visibility: visible;
      }

      .social-source-modal__copy-button {
        background-color: #337AB7;
        color: #FFFFFF;
        padding: 10px 14px;
        border-radius: 50%;
        position: absolute;
        bottom: 10px;
        right: 25px;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        transition: opacity .5s ease-in-out, visibility .5s ease-in-out;

        &:hover {
          opacity: 1;
        }
      }


      .social-source-modal__info-list {
        list-style: none;
        padding: 0;

        .social-source-modal__info-item {
          display: flex;
          flex-direction: column;

          &:not(:first-child) {
            margin-top: 10px;
          }

          label {
            margin-bottom: 0;
          }

          span {
            margin-top: 5px;
            white-space: pre-wrap;
            margin-bottom: 0;
            user-select: text;
          }
        }
      }
    }
  }
}

.voc-form-dropzone {
  &__input {
    float: left;
    min-height: auto;
    max-width: 550px;
    overflow: hidden;
    text-overflow: ellipsis;

    p {
      margin: 0;
    }
  }

  &__uploaded {
    float: right;
    text-align: center;
    margin-right: 15px;

    & > audio {
      height: 33.5px;
    }

    .dropzone__uploaded__icon {
      position: absolute;
      top: 8px;
      right: 4px;
      cursor: pointer;
    }

  }

  &__text {
    float: left;
    max-width: 550px;
    width: 100%;
  }

  &__name {
    max-width: 485px;
    display: inline-block;
    vertical-align: bottom;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
  }
}

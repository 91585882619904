.vocSelectFiltersIcon {
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 5px;
  height: 26px;
  margin-top: -13px;
  line-height: 27px;
  width: 27px;
  font-size: 15px;
  z-index: 1;
  color: #404040;
  border: 1px solid #c6c6c6;
  border-radius: 5px;
  background: #e9e9e9;
  box-shadow: inset 0 0 1px 0 #e5e5e5;
  text-align: center;

  svg {
    pointer-events: none;
  }

  &.active {
    border-color: #8b8b8b;
    background: #cdcdcd;
    box-shadow: inset 0 0 1px 0 #bcbcbc;
  }
}

.remoteSelectWrapper {
  position: relative;
}

.vocSelectFilters {
  position: relative;
}

.source-select {
   > div:first-of-type {
    padding-left: 27px;
  }
}

.sourceSelect {
  > div:first-of-type {
    padding-left: 35px;
  }
}

// --- VARS ---
$defaultColor: #ced4da;
// FOR Internet Explorer
$primary: #337ab7;
// Track
$trackHeight: 2px;
$trackBorder: 0px;

@mixin trackMain {
  height: $trackHeight;
  cursor: pointer;
  border-radius: 1px;
}

@mixin trackProgress {
  @include trackMain;
  box-shadow: 0 0 0 $primary;
  background: $primary;
  border: $trackBorder solid #000000;
}

@mixin trackTrack {
  @include trackMain;
  background: $defaultColor;
  border: $trackBorder solid #000000;
  box-shadow: 0 0 0 $primary;
}

// Thumb
$thumbSize: 12px;
$thumbBorder: 1px;
$thumbMarginTop: calc((#{$trackHeight} + #{$trackBorder} - #{$thumbSize} - #{$thumbBorder}) / 2);
@mixin thumbMain {
  height: $thumbSize;
  width: $thumbSize;
  cursor: pointer;
  border: $thumbBorder solid $primary;
  border-radius: 50%;
  background: $primary;
  box-shadow: 0 0 0 $primary;
}

// scale
$scaleItemWidth: 2px;

// --- CSS ---
.inputRange {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  height: 85px;
}

.inputRange-title {
  align-self: flex-start;
  font-size: 14px;
  color: #53565A;
  margin-bottom: 8px;

  & span {
    color: $primary;
  }
}

.inputRange-scalePoint {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0 calc(#{$thumbSize} / 2 - #{$scaleItemWidth} / 2);

  &-Item {
    width: $scaleItemWidth;
    position: relative;

    & .value {
      position: absolute;
      bottom: -43px;
      font-size: 13px;
      min-width: 25px;
      right: -12px;
      text-align-last: center;
    }
  }

  &-active {
    background: $primary;
    color: $primary;
  }

  &-default {
    background: $defaultColor;
    color: $defaultColor;
  }

}

.inputRange-input {
  height: 25px;
  -webkit-appearance: none;
  margin: 0;
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 0;
  background-color: transparent;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  // Track
  &::-moz-focus-outer {
    border: 0 solid $primary;
  }

  &::-webkit-slider-runnable-track {
    @include trackTrack;
    background: -webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(var(--rangePosition), var(--primary)), color-stop(var(--rangePosition), $defaultColor));
  }

  &::-moz-range-track {
    @include trackTrack;
  }

  &::-moz-range-progress {
    @include trackProgress;
  }

  &::-ms-track {
    @include trackMain;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  &::-ms-fill-upper {
    @include trackTrack;
  }

  &::-ms-fill-lower {
    @include trackProgress;
  }

  // Thumb
  &::-webkit-slider-thumb {
    @include thumbMain;
    margin-top: $thumbMarginTop;
    -webkit-appearance: none;
  }

  &::-moz-range-thumb {
    @include thumbMain;
  }

  &::-ms-thumb {
    @include thumbMain;
    margin-top: 1px;
  }
}

.questions__sortable-wrapper {
  .question__form {
    width: 0;
    overflow: hidden;
    // "max-height" needed for animation
    // Without this SortableTree becomes very tall,
    // cause with 0% width, form has large height
    max-height: 0;
    left: 100%;
    opacity: 0;
    transition: width 0.1s ease-in, opacity 0.1s ease-in, max-height 0.1s linear; // for fast hide
  }
}

.questions__sortable-tree {
  position: relative !important;
  width: 100%;
  transition: width 0.2s ease-in;

  &.questions__sortable-tree--width {
    width: 52%;
    transition-timing-function: ease-out;

    & + .question__form {
      width: 48%;
      overflow: unset;
      // animated with duration to avoid breaking SortableTree height
      max-height: 10000px;
      left: 52%;
      opacity: 1;
      // show fast, but opacity has additional 0.3s delay for await width animation
      transition: width 0.3s ease-out, opacity 0.3s linear 0.3s, max-height 0.3s linear;
    }
  }

  .rst__lineChildren::after {
    background: #ccc;
    bottom: -5px;
  }

  .rst__rowWrapper {
    padding: 5px 10px 5px 0;

    &--stripped .rst__rowContents {
      background: rgba(0, 0, 0, 0.055);
    }
  }

  .rst__lineHalfHorizontalRight::before,
  .rst__lineFullVertical::after,
  .rst__lineHalfVerticalTop::after,
  .rst__lineHalfVerticalBottom::after {
    background: #ccc;
  }

  .rst__collapseButton,
  .rst__expandButton {
    box-shadow: 0 0 0 1px #ccc;
  }

  .rst__collapseButton:focus,
  .rst__expandButton:focus {
    box-shadow: 0 0 0 1px #ccc, 0 0 1px 3px #83bef9;
  }

  .rst__node {
    display: flex;
    overflow: hidden;

    .rst__lineBlock {
      flex: 0 0 auto;
    }

    .rst__nodeContent {
      flex: 0 1 auto;
      left: auto !important;
      position: relative !important;
      top: auto !important;
      min-width: 100%;

      .rst__rowWrapper:first-child {
        padding-top: 0;
      }
    }
  }

  .rst__lineBlock {
    & + .rst__lineBlock.rst__lineHalfVerticalTop.rst__lineHalfHorizontalRight,
    & + .rst__lineBlock.rst__lineHalfHorizontalRight.rst__lineFullVertical {
      display: block;
    }
  }

  .rst__rowContents {
    border-color: #ccc;
    box-shadow: none;
    width: calc(100% - 48px);

    &.rst__rowContents--highlighted {
      background: #17a2b82b;
    }
  }

  .rst__rowTitle {
    color: var(--blue);
    cursor: pointer;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;

    .rst__rowTitleId {
      color: black;
      font-weight: 400;
    }
  }

  .rst__rowLabel {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-top: 2px;
    cursor: pointer;
  }

  .rst__moveHandle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    background: #fff;
    border-color: #ccc;
    box-shadow: none;
    z-index: unset;
  }

  .rst_question__label {
    display: inline-block;
    background: #fff;
    color: #666;
    border: 1px solid #e4e8ed;
    text-transform: uppercase;
    text-shadow: none;
    font-weight: bold;
    font-size: 13px;
    padding: .2em .6em .1em;
  }

  .question__tree__placeholder {
    display: block;
    text-align: center;
    width: 100%;
    max-width: none;
    color: #777;
  }

  .rst__rowToolbar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: 12px;
    min-width: 120px;

    &.matrixQuestionExist {
      min-width: 145px;
    }
  }

  .rst__rowTitleWithSubtitle {
    font-size: 16px;
    line-height: 20px;
    height: auto;

    & > div {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .rst__rowSubtitle {
    display: block;
    font-size: 16px;
    line-height: 16px;

    span {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-bottom: 3px;
    }
  }

  .rst__rowTitleDefaultValue {
    color: #a94442;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ReactVirtualized__Grid__innerScrollContainer {
    overflow: hidden !important;
  }
}

.flow__drag {
  margin-right: 10px;
}

.flow__list {
  color: #337ab7;
}

.flow__list-wrap-icon {
  margin-left: 5px;
  margin-right: 5px;
  display: flex !important;
  padding: 0 !important;
}

.flow__list-icon {
  cursor: pointer;

  &.warning {
    color: var(--warning);
  }
}

.popover-inner {
  max-width: 420px;
}

.flow__list-item {
  display: flex !important;
  color: #337ab7;
  font-size: 0.88rem;
  letter-spacing: normal;
}

.flow__list-wrap-condition {
  padding-right: 10px;
  flex-grow: 1;
}

.flow__list-action {
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto
}

.flow__list-condition {
  display: block;
  margin: 0 5px;
  max-width: 250px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.questions__sortable-container {
  .flow-preloader-box {
    overflow: hidden;
  }

  .flow-preloader__min {

    .loader {
      font-size: 7px;
      width: 8em;
      height: 8em;
    }

    .loader:after {
      left: 4.1em;
    }

    .loader:before {
      left: -0.6em;
      top: -0.5em;
    }
  }

  .popover {
    max-width: none;
    z-index: 1000 !important;
    max-height: 600px;
    overflow-y: auto;
  }
}

.rst__node-unconditional-skip {
  opacity: .2;
}

.template-header__go-back {
  border-bottom: 1px solid #E5E5E5;;
  width: 100%;
  padding: 13px 0;
  margin-bottom: 16px;
}

.template-header__language {

  .dropdown-wrapper .form-group {
    margin-bottom: 0;
  }

  .template-header__selector {
    width: 112px;
    margin-left: 10px;
    margin-bottom: 0;

    .dropdown-indicator {
      width: 10px;
      height: 4px;
    }

    .vochub-select-control__placeholder ~ div,
    .vochub-select-control__single-value ~ div {
      height: 19px;
    }

    .vochub-select-control__input {

      input {
        position: absolute;
        left: -5px;
      }
    }
  }

  .enable-language__box {
    padding-top: 8px;
  }
}

.template-name_div {
  border: 1px solid transparent;
  padding: 10px 15px;
  display: flex;
  align-items: center;
}

.template-name_span {
  height: 16px;
  line-height: 1.2;
}

//#action_type {
//  &.voc-setting-field__input--themeTab:disabled {
//    background: #e6e6e6 !important;
//  }
//}

#action_type.voc-setting-field__input--themeTab:disabled {
  background: #f2f2f2 !important;
}

.voc-setting-field_theme_name {
  margin-bottom: 15px;
}

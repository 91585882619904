.customers_list-toolbar-select {

  .vochub-select-control__control {
    min-height: 34px;
  }
}

.search-toolbar__label {
  padding-top: 8px;

  + .row {
    margin-left: 3px;

  }
}

.search-type-select {
  width: 110px;
}

.search-type-column {
  display: flex;
  justify-content: flex-end;
}

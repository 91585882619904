.splitPaneSash {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;


  &:after {
    content: '';
    background: #E5E5E5;
    position: absolute;
  }

  &.splitPaneSashvertical {
    padding: 0 5px;
    margin-left: -5px;

    &:after {
      width: 1px;
      height: 100%;
      top: 0;
    }

    svg {
      transform: rotate(90deg) translate(0px, -4.4px);

      path {
        fill: #E5E5E5
      }
    }
  }

  &.splitPaneSashhorizontal {
    padding: 5px 0;
    margin-top: -5px;

    &:after {
      height: 1px;
      width: 100%;
      left: 0;
    }

    svg {
      transform: translate(0px, -4.3px);

      path {
        fill: #E5E5E5
      }
    }
  }
}

.customers-toolbar__label {
  display: inline-block !important;

  + .row {
    display: inline-block !important;
    width: 181px;
    margin-left: 3px;
  }
}

.export-results__toolbar_filters {
  display: flex;

  .export-results__toolbar_filters_select_wrapper {
    display: flex;
    margin-right: 20px;
  }
}

.table-action__container.table-action__query-list {
  display: flex;

  &.center {
    justify-content: center;
  }

  > .table-action__query-list-icon {
    margin-left: 8px;
    margin-right: 8px;

    &.disabled {
      color: var(--gray);
      opacity: .5;
      pointer-events: none;
    }
  }
}

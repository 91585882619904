.columnTypesButtonGroup {
  margin-bottom: 20px;
}

.metadata-columns-block {
  padding: 0;
  border: none !important;
  box-shadow: none;
}

.metadata-columns-block .card-body {
  padding-top: 0 !important;
}


.metadata-columns-block .panel-body {
  padding-left: 0;
}

.metadata-button {
  margin-top: 10px;
  margin-bottom: 10px;
}

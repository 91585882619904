.global-settings__lang,
.global-settings__unit,
.global-settings__time-slots {

  .vochub-select-control {
    height: 29px;
    margin-left: 10px;
    margin-bottom: 3px;

    > div {
      min-height: 29px;
      height: 29px;
    }

    .dropdown-indicator {
      width: 10px;
      height: 4px;
    }

    .vochub-select-control__placeholder ~ div,
    .vochub-select-control__single-value ~ div {
      height: 19px;
    }

    .vochub-select-control__input {

      input {
        position: absolute;
        left: -5px;
      }
    }
  }
}

.global-settings__time-slots {
  margin-left: auto;
}

.global-settings__lang {
  .form-group {
    margin-bottom: 0;
  }
}

.global-settings__config {
  margin-top: 20px;
  margin-bottom: 20px;

  .vochub-select-control.selector-status-survey {
    float: right;
  }

  .enable-language__box {
    margin-top: 5px;

    &.disabled {
      opacity: .5;
    }
  }
}


.dataSettingsContainer {
  position: relative;

  & .fieldWrapper {
    margin-bottom: 10px;
    display: block;

    :global(.outside-alerter) {
      margin-left: 0 !important;
    }
  }

  & .selectMenu {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 12px;
  }

  & .menuButton {
    margin-right: 10px;
  }

  & .loader {
    width: 100%;
    height: 100%;
    background-color: var(--white);
    opacity: 0.7;
  }
}

:global(.Select-options--sources) {
  max-width: 100% !important;
}

.btn.channels-controls {
  font-size: 12px;
  min-width: 111px;
  padding: 0.322rem 0 !important;

  svg {
    margin-right: 5px;
  }

  &:disabled {
    background: #e6e6e6;
    opacity: 1 !important;
  }

  &.active {
    background: var(--blue);
    color: #fff;
    border-top-color: var(--blue);
    border-bottom-color: var(--blue);
  }
}

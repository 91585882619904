.popover_chart_tooltip {
  position: fixed;
  z-index: 10000;
  visibility: hidden;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  color: #fff;
  padding: 5px 10px;
  text-align: center;
  word-break: break-all;
  max-width: 400px;

  & > span {
    word-break: break-word;
  }
}

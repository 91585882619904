.gadgetWrapper {
  display: flex;
  justify-content: space-between;
  padding: 10px 10px 20px;
}

.gridItemWrapper {
  transition: none;
}

.virtualizedList {
  overflow: visible !important;
  pointer-events: all;
}

.pasteContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 80px;

  &.smallContainer {
    svg {
      height: 60%;
    }
  }

  svg {
    fill: #a8c3dd;
  }

  & > span {
    display: none !important;
    cursor: auto !important;
    pointer-events: none !important;
  }
}

.pageContainer {
  @media (max-width: 4000px) {
    transition: all 300ms ease-in-out;
  }

  @media (max-width: 1069px) {
    width: inherit !important;
  }

  &.disableSelect {
    user-select: none;
  }
}

.droppableListWrapper {
  min-width: 75px;
  max-width: 380px;
  height: 100vh;
  position: absolute;
  bottom: 0;
  top: 0;
  right: -49px; // PAGE_CONTROLS width
  background-color: #ECF0F6;
  border-left: 2px solid #fff;
  border-radius: 5px 0 0 5px;
  pointer-events: all;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 12px 6px;
  transition: width 300ms ease-in-out, max-height 200ms ease-in-out, transform 500ms ease-in-out;
  // less than modal
  z-index: 1040;

  & .droppableList {
    width: inherit;
    max-width: 100%;
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
    margin: 24px 0;
  }

  & .toggleBtn {
    width: 100%;
    max-width: 200px;
  }

  &.inactive {
    pointer-events: none;
  }
}

:global {
  .droppable-element > p {
    margin: 0;
  }
}

.droppableItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto 10px;
  padding: 8px;
  height: 46px;
  border: 1px solid #E5E5E5;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
  transition: all 300ms ease-in-out;

  &:hover {
    border-color: var(--primary);
  }

  &:last-child {
    margin-bottom: 0;
  }

  & p {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.controls {
  margin-left: 10px;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  max-width: 1px;
  transition: max-width 300ms ease-in-out 300ms, opacity 300ms ease-in-out;

  &.visibleControls {
    opacity: 1;
    pointer-events: all;
    visibility: visible;
    max-width: 72px;
    transition: max-width 300ms ease-in-out, opacity 300ms ease-in-out 300ms;
  }

  .controlsButton {
    background: none;
    height: 26px;
    width: 26px;
    border: none;
    cursor: pointer;
    transition: .3s;
    opacity: .7;

    &:hover {
      opacity: 1;
      transition: .3s;
    }

    &.activeListItem {
      color: #337ab7;

      & svg path{
        fill: #337ab7;
      }
    }
  }
}

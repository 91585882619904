.absolutePeriodWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  .selectsWrapper {
    display: flex;
    justify-content: space-between;

    .selectWrapper {
      width: 45%;
    }

    @media (max-width: 500px) {
      flex-direction: column;

      .selectWrapper {
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }
}

.topicWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;

  .categoriesList {
    overflow: auto;

    :global(.collapse__header-title-text), :global(.collapse__header) {
      font-size: 15px;
    }

    :global(.collapse__header) {
      max-width: calc(100% - 75px);
    }

    :global(.collapse__header-title) {
      display: flex;
    }

    :global(.card-header) {
      min-height: 38px;
      padding: 7px 14px;
    }

    :global(.collapse__header-title-text) {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .noTopicsLabel {
      margin: 0;
      text-align: center;
    }
  }

  .categoriesActions {
    margin-right: -5px;
    display: flex;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      border: none;
      background: none;
      height: 24px;
      width: 24px;
    }
  }

  .topicList {
    flex-wrap: wrap;
    width: auto;
    overflow: auto;
    justify-content: left;
    margin-bottom: 15px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    grid-gap: 5px;
    padding-left: 10px;
    padding-right: 14px;

    &.topicsInCategories {
      padding-right: 0;
      padding-left: 0;
    }

    .topic {
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid hsl(0,0%,80%);
      cursor: pointer;
      border-radius: 5px;

      &.activeSaved {
        background: #dae8fc;
        border-color: #acc2e2;
      }

      &.active {
        background: #e4fbd4;
        border-color: #c4d9b7;
      }

      &.activeMined {
        background: #f3ffeb;
        border-color: #c4d9b7;
      }

      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0 10px;
      }
    }
  }

  &:hover {
    .addTopic {
      transition: opacity 0.3s;
      opacity: 0.5;
    }
  }

  .addTopic {
    position: absolute;
    bottom: 10px;
    right: 10px;
    height: 36px;
    width: 36px;
    opacity: 0;
    transition: opacity 0.3s;
    border-radius: 50%;
    border: none;
    background-color: #337AB7;
    z-index: 3;

    .actionButton {
      background: none;
      border: none;
      font-weight: bold;
      color: white;
      padding: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &:hover {
      opacity: 1;
    }

    .menuList {
      position: absolute;
      bottom: 36px;
      right: 0;
      background-color: #337AB7;
      border-radius: 5px 5px 0% 5px;
      overflow: hidden;
      pointer-events: none;
      opacity: 0;
      width: 36px;

      button {
        background: none;
        border: none;
        white-space: nowrap;
        padding: 6px 12px;
        color: white;

        &:hover {
          color: rgba(255, 255, 255, 0.7);
        }
      }
    }

    &:hover {
      border-radius: 0 0 50% 50%;
      opacity: 1;

      .menuList {
        pointer-events: all;
        opacity: 1;
        width: fit-content;
      }
    }
  }
}

.navigation {
  position: fixed;
  left: -65px;
  top: 52px;
  bottom: 0;
  width: 60px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.5);
  background-color: #f4f4f4;
  overflow: hidden;
  transition: width .2s ease-out, left .2s;
  z-index: 1010;

  &.active {
    .navigation__list {
      overflow-y: auto;
    }
  }

  &.active,
  &.active-mob {
    width: 185px;
    left: 0;
  }
}

.navigation__list {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
  height: 100%;
}

.navigation__list-item {
  width: 185px;
  transition: background-color .1s linear;

  &:hover {
    .menu-header-item {
      background-color: #dedede;
    }
  }
}

.menu-header-item {
  transition: background-color .1s linear;
}

.navigation__item-content {
  padding-top: 8px;
}

.navigation-item {
  &-beta,
  &-pro {
    position: relative;

    &::before {
      position: absolute;
      border-radius: 8px;
      padding: 2px 6px;
      font-size: 10px;
      font-weight: 500;
      color: white;
      top: -6px;
      left: calc(100% + 3px);
    }
  }

  &-beta::before {
    content: 'BETA';
    background: orange;
  }

  &-pro::before {
    content: 'PRO';
    background: #bf2025;
  }
}

.menu-header-item.active {
  background-color: #231f20 !important;

  .app-name, .app-description {
    color: #fff;
  }
}

.navigation__arrow {
  position: relative;
  transition: transform .2s linear;
  width: 6px;
  height: 14px;
  align-self: center;

  &.active {
    transform: rotate(90deg);
  }
}

@media (min-width: 768px) {
  .navigation {
    left: 0;

    &.active-mob {
      width: 60px;
    }

    &.active {
      width: 185px;
      left: 0;
    }
  }
}

.setting-control-group {
  position: relative;

  &_error {
    position: absolute;
    color: red;
    font-size: 11px;
    bottom: -15px;
    left: 0;
  }

  .settings-control-group__script_col {
    display: flex;
    flex-wrap: wrap;

    .settings-control-group__script_input {
      flex: 1;
    }
  }
}

.statistic_result__card {

  h5 {
    margin-bottom: 0;
    color: #000000;
    font-size: 16px;
    font-weight: bold;
  }

  .card-header {
    background-color: #f5f5f5;
    border-bottom: none;
  }

  &.card {
    border: none;
    border-radius: unset;
  }

  .card-body {
    padding: 1.25em 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }

}

.statistic_result__filter label.statistic_result__filter-label {
  color: #000000;
  font-size: 14px;
  font-weight: normal;
}

.statistic_result__info-wrapper {
  margin-top: 25px;
}

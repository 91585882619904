.download_link-tooltip {
  display: flex;
  flex-direction: column;
}

.download_link {
  display: inline-block;
  color: #337ab7;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: #1f4a70;
  }

  &.red {
    color: #b7141b;

    &:hover {
      color: #7e0d12;
    }
  }

  &.green {
    color: #28a745;

    &:hover {
      color: #1a732f;
    }
  }

  &.disabled {
    pointer-events: none;
    color: #6c757d;
    opacity: .5;
  }

  &:hover {
    text-decoration: none;
  }
}

.button-dropdown__wrapper {

}

.button-dropdown__toggle {

}

.button-dropdown__menu {
  .button-dropdown__menu-item {
    font-size: 13px !important;
    padding: 8px 24px !important;
    line-height: 16px;
  }

  .dropdown-header {
    color: var(--gray);
    font-size: 12px !important;
    text-transform: uppercase;
    padding: 8px 16px !important;
  }

}


.btn-group {

  &.button-dropdown__wrapper {
    button.button-dropdown__toggle {
      border-radius: 3px !important;

      &:focus {
        box-shadow: none;
      }
    }
  }

  &.mine-button-dropdown__wrapper {

    button.mine-button-dropdown__toggle {
      border-radius: 3px !important;

      padding-left: 16px;
      padding-right: 16px;

      &:focus {
        box-shadow: none;
      }
    }

    .mine-button-dropdown__menu.dropdown-menu {
      .mine-button-dropdown__menu-item {
        height: 36px;
      }
    }
  }

  .dropdown-item:hover,
  .dropdown-item:focus {
    background-color: #EAF1F7;
  }

  .dropdown-item.active,
  .dropdown-item:active {
    color: #16181b;
  }
}

.settingsContainer {
  font-size: 11px;
  table-layout: fixed;

  & .titleRow {
    caption-side: top;
    display: table-caption;
    text-align: center;
    padding: 0 6px;
  }

  & .settingsRow {
    text-align: left;
    border-bottom: 1px dotted #ECF0F6;

    & td {
      padding: 6px;

      &:nth-child(1) {
        min-width: 125px;
      }
    }

    &:last-child {
      border-bottom: none;
    }
  }
}

.pagination-form .form-control.is-invalid {
  background-image: none;
  padding: .375rem .75rem;
  border: none;
}

.pagination-form .input-group-addon {
  background: #fff;
  color: #666;
  padding: 10px 10px;
  border: 1px solid #ced4da;
  border-right: none;
  border-radius: 3px;
}

.pagination-form .input-group {
  width: 228px;
}

.form-go-page .form-group {
  margin-bottom: 0;
}

.form-go-page .input-group-addon {
  border: none;
}

.pagination-form .input-group-append {
  position: relative;
  right: 0;
  top: 0;
  bottom: 0;
  height: auto;
}

.pagination-form .input-group-append > .form-control {
  height: 100%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

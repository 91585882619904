.Select-value__option_container {
  display: flex;
  flex-direction: row;
  align-items: center;

  &.space-between {
    justify-content: space-between;
  }

  .Select-value__option_container_icon {
    font-size: 18px;
    margin-right: 10px;
    width: 25px;
  }

  .Select-value__option--title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-shrink: 1;
  }

  .Select-value__option--owner {
    font-weight: 400;
    flex-shrink: 0;
  }

  .Select-value__option_container_text {
    font-size: 13px;
  }
}



.modal {
  user-select: none;
}

.voc-dialog .modal-body {
  max-height: calc(100vh - 200px);
  word-break: break-word;
  hyphens: auto;
  padding: 1.8rem;

  .star {
    color: #dc0000;
  }

  .disable-select {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

.voc-dialog.modal-scrollable .modal-body {
  overflow-y: auto;
}

.voc-dialog {
  .modal-footer .btn + .btn {
    margin-left: 5px;
  }
}

.voc-dialog-mobile {
  .modal-dialog {
    margin-top: 0;
    margin-bottom: 0;
    display: flex;
    max-height: 100%;
    padding: 7px 0px;
  }
}

.layout-modal .modal-dialog {
  width: calc(100vw - 100px);
  max-width: none;
}

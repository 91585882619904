.gridLine {
  stroke: rgb(211, 211, 211);
}

.scoreNumber,
.scoreNpsNumber {
  font-size: 12px;
  text-anchor: middle;
  fill: black;
  pointer-events: none;
}

.scoreNpsNumber {
  font-size: 14px;
  font-weight: bold;
  stroke-width: 0.5px;
}

:global(.npsLineGroup) {
  transition: opacity 0.3s;
}

.npsLine,
.npsDot {
  // from .virtualizedList inherited as 'pointer-events: all',
  // which blocks the entire area under nps 'path'
  pointer-events: auto;
}

.npsDot {
  cursor: pointer;
}

.transparentAxisLine > path,
.transparentAxis {
  visibility: hidden;
}

.transparentAxisLine > g > line {
  stroke: rgb(211, 211, 211);
}

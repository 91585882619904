.dimensionsWrapper {
  display: flex;
  align-items: center;
  margin-left: 20px;

  .dimensionInput {
    width: 110px;

    :global(.input-group-text) {
      border-right: none !important;
      border-radius: 0.25rem 0 0 0.25rem !important;
    }
  }

  .label {
    display: none;
    margin-right: 10px;

    @media screen and (min-width: 1440px) {
      display: initial;
    }
  }

  .multiply {
    font-size: 20px;
    padding: 0 5px;
  }
}

$baseURL: "/images/vocNotifications";

$downloadIcon: "#{$baseURL}/download_new.svg";
$activeIcon: "#{$baseURL}/active_new.svg";
$passiveIcon: "#{$baseURL}/passive_new.svg";
$warningIcon: "#{$baseURL}/warning_new.svg";

.bell__container {
  position: relative;
  display: inline-block;
  cursor: pointer;

  & > img.animateBellIcon {
    animation: 3s ease-in-out bounce;
  }

  @keyframes bounce {
    0%, 10%, 90% { transform: scale(1); }
    20% { transform: scale(1.2); }
    30%, 50%, 70% { transform: rotate(-20deg) scale(1.2); }
    40%, 60% { transform: rotate(20deg) scale(1.2); }
    80% { transform: rotate(0deg) scale(1.2); }
  }
}

.bell__counter {
  display: block;
  text-align: center;
  position: absolute;
  top: -2px;
  right: -4px;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  background-color: #d91219;
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  font-size: 10px;
  font-weight: 500;
}

#voc-notifications-container {
  .icon {
    display: block;
    float: left;
    margin-right: 6px;
    padding: 0;
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    background-position: center;

    &.active {
      background-image: url($activeIcon);
      animation: 5s ease-in-out infinite spinWithPause;
    }

    &.download {
      background-image: url($downloadIcon);
    }

    &.passive {
      background-image: url($passiveIcon);
    }

    &.warning {
      background-image: url($warningIcon);
    }

    @keyframes spinWithPause {
      0% {
        transform: rotateZ(0);
      }
      10%, 50% {
        transform: rotateZ(180deg);
      }
      60%, 100% {
        transform: rotateZ(360deg);
      }
    }
  }

  .close {
    font-size: 17px;
  }

  .popover-content {
    padding-top: 9px;
  }

  a[href="#"] {
    color: #8f8f8f;
    text-decoration: none;
    cursor: default;
  }

}

#voc-notifications-container {
  font-family: "Helvetica Neue", sans-serif;
  font-size: 14px;

  .popover-body {
    width: 320px;
    max-height: 400px;
    padding-top: 15px;
    overflow-y: auto;
  }
}

.notifications__counter {
  color: #ff3b30;
}

.popover {
  max-width: none !important;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.33);
}

.notification__widget-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  border-bottom: none;
  padding: 18px 23px 12px;
  border-bottom: 1px solid #e9ecef;
}

.notification__widget__clear-btn {
  background-color: #d91219;
  border: none;
  color: #fff;
  padding: 3px 5px;
  font-size: 12px;
  border-radius: 6px;
}

.has-no-notifications {
  margin: 20px 0;
  text-align: center;

  .notification__text {
    display: block;
    width: 100%;
    font-size: 14px;
    text-align: center;
    margin: 15px 0 0;
  }
}

@import 'react-datepicker/dist/react-datepicker.css';
.range-datepicker__label {
  line-height: 34px;
}

.datepicker-control__spacer {
  margin-bottom: 15px;
  clear: both;
  width: 100%;
}

.range-datepicker__horizontal.form-group {
  margin-bottom: 0;

  .range-datepicker__label {
    margin-bottom: 0;
    line-height: 30px;
  }

  .datepicker-control-label {
    padding-left: 15px;
    float: left;
    flex-grow: initial;
  }

  .datepicker-control-wrapper {
    padding-right: 15px;
    padding-left: 15px;
    float: left;
    width: 160px;
  }

  .col-md-2 {
    height: 30px;
  }

  .form-control {
    height: 30px;
  }
}

.queries-table-date {
  .react-datepicker__navigation {
    top: 7px !important;
  }
}

.case_alert-form_modal-body {

  .app-form-control__container {
    position: relative;
  }

  .app-form-control__error {
    position: absolute;
    bottom: -3px;
    right: 0;
  }

  .text-field__case-alert {
    height: 50px;
  }
}

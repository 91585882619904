.tr {
  width: 100% !important;
  display: flex;

  &.trEven .td{
    background: #f3f3f3;
  }

  &:last-child {
    .td {
      border-bottom: 0;
    }
  }
}

.td {
  margin: 0;
  padding: 0.6rem;
  flex-shrink: 0;
  min-width: 100px;

  &:last-child {
    width: unset !important;
    flex: 1;
  }

  .innerTd span{
    height: 100%;
    display: block;
    word-break: break-word;
  }

  .innerTdNoWrap span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.select-change-query.vochub-select-control {
  margin: 0;
}

.voc-mine-query__header {
  display: flex;
  justify-content: flex-start;
  padding: 24px 0;
  border-bottom: 1px solid #E5E5E5;

  & .header__item {
    min-width: 150px;

    &.selectWrapper {
      max-width: 320px;
      flex: 1;
    }
  }

  &.mine-query-header__disable {
    pointer-events: none;

    .link_go_back {
      opacity: .5;
    }
  }
}

.button_link-back_grey {
  height: 38px;
  color: #a0a0a2 !important;
  text-decoration: none !important;
  &:hover {
    color: #22527b !important;
  }
}

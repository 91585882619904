.titleWrap {
  display: flex;

  .textWrap {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;
  }

  .defaultLabel {
    color: white;
    width: fit-content;
    min-width: fit-content;
    height: 20px;
    padding: 0px 5px;
    display: flex;
    background-color: #009FFA;
    align-items: center;
    border-radius: 5px;
    margin-left: 5px;

    span {
      font-size: 12px;
    }
  }
}

.popover.question-value-popover {
  min-width: 179px;
  overflow-y: initial;
  max-height: initial;
}

.question-value-popover {
  .popover {
    z-index: 1;
  }
}

#configurable-popover {
  .popover-body {
    height: 300px;
    min-width: 230px;
    max-height: 300px;
    overflow-y: auto;
  }
}

.popover-answers__label {
  margin-bottom: 5px;
  padding: 5px 0 5px 5px;
  background: #f7f7f7;
  text-transform: uppercase;
}

.popover-answers__item {
  padding-left: 15px;
  position: relative;
  min-height: 18px;

  &:before {
    content: '';
    position: absolute;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: #000;
    top: 8px;
    left: 5px;
  }
}

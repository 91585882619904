// ###
// ### Surveys-List styles
// ###
@mixin width-nth-child-2($property) {
  #{$property}: 150px;

  @media screen and (min-width: 1070px) {
    #{$property}: 300px;
  }

  @media screen and (min-width: 1250px) {
    #{$property}: 380px;
  }
}

@mixin width-and-offset-nth-child-3 {
  width: 115px;

  & .channels {
    & span.label {
      margin-bottom: 5px;
      margin-right: 0;

      &:last-child {
        margin-bottom: 0;
      }

      @media screen and (min-width: 1070px) {
        &:nth-child(3), &:nth-child(4) {
          margin-bottom: 0;
        }

        &:nth-child(1), &:nth-child(3) {
          margin-right: 5px;
        }
      }
    }
  }

  @media screen and (min-width: 1250px) {
    width: 215px;

    & .channels span.label {
      margin-bottom: 0;
      margin-right: 5px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

@mixin wrap-styles {
  @include width-nth-child-2(max-width);
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: middle;
}

@mixin id-wrapper {
  display: inline-block;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1;
  margin-bottom: -2px;
  vertical-align: text-top;

  &.isAllowDrag {
    max-width: 43px;
  }
}

@mixin nth-child-1 {
  width: 100px;
  max-width: 100px;
  min-width: 100px;
  padding-left: 25px;
  white-space: nowrap;
}

.surveys-list-modern {

  td {
    line-height: 1.5;
  }

  position: relative;

  &-panel {
    div.panel-heading {
      padding: 0;
      h5 {
        font-weight: bold;
        padding-left: 15px;
        margin-top: 10px;
      }
      .btn.pull-right {
        width: 180px;
        max-width: 180px;
        padding: 8px 12px;
        border: 0;
        border-left: 1px solid #ddd;
        border-radius: 0;
      }
    }
    .panel-body {
      padding: 0;
    }
  }

  & .alert.no-results {
    margin: 0;
    border-color: transparent;
    background: inherit;
  }

  & .btn-default:focus {
    color: #333;
    background-color: #fff;
    border-color: #ccc;
  }

  &-filters {
    color: #999;
    margin-bottom: 20px;
    border-radius: 6px;
    text-align: center;

    .filter-search {
      .form-control {
        width: 380px;
      }
    }
    .surveys-list-modern-filters__page-options {
      display: flex;
    }

    .filter-hideDeletedSurveys {
      margin-right: 5px;
    }

    .stub {
      width: 555px;
      background: rgba(0, 0, 0, 0.04);
      border-radius: 4px;
      border: 1px dashed #ccc;
      text-align: left;
      padding: 6px 40px;
      display: inline-block;
    }

    .pull-left {
      text-align: left;

      .btn-group {
        width: 100%;

        button.dropdown-toggle {
          width: 100%;
        }
      }

    }

    .dropdown-toggle {
      width: 134px;
      position: relative;
      text-align: left;
      text-overflow: ellipsis;
      overflow: hidden;

      &:focus {
        color: #333;
      }

      .caret {
        position: absolute;
        z-index: 2;
        top: 15px;
        right: 8px;
      }
    }

    .dropdown-menu {
      .active a {
        background: #e4e4e4;
        color: #333;

        &:hover {
          background: #e4e4e4;
          color: #333;
        }
      }
    }
  }

  &-list {

    .label {
      display: inline;
      padding: .5em .6em .5em;
      font-size: 75%;
      font-weight: 700;
      line-height: 1;
      color: #fff;
      text-align: center;
      white-space: nowrap;
      vertical-align: baseline;
      border-radius: .25em;
      &.label-default {
        background-color: #777;
      }

      &.label-success {
        background-color: #5cb85c;
      }

      &.label-warning {
        background-color: #f0ad4e;
      }

      &.label-danger {
        background-color: #d9534f;
      }

      &.label-info {
        background-color: #5bc0de;
      }
    }


    .table {
      opacity: 1;
      transition: opacity .6s ease-out;
      table-layout: auto;
      margin-bottom: 0;

      // Begin of "Common place for change columns-width"
      &.table-header th,
      .surveys-list-sticky-header th,
      tbody td,
      thead th {
        // @note: Sizes only
        // @note: table must contain 5 columns
        &:nth-child(1) { width: 100px; }
        &:nth-child(2) { @include width-nth-child-2(width); }
        &:nth-child(3) { @include width-and-offset-nth-child-3 }
        &:nth-child(4) { width: 253px; }
        &:nth-child(5) { width: 171px; }
      }
      // End

      &.table-header {
        & {
          border: 1px solid #ddd;
          border-top: 0;
          border-bottom: 0;
        }
      }

      thead {
        th {
          &:nth-child(2),
          &:nth-child(4) {
            text-align: center;
          }

          &, & a {
            color: #666;
          }
          a {
            cursor: pointer;
            text-decoration: underline;
            &.sortField {
              padding: 10px 3px;
              span.title {
                text-decoration: underline;
              }
              .glyphicon {
                margin-left: 5px;
                font-size: 11px;
              }
            }
          }
          font-weight: normal;
          padding: 16px 5px 14px 10px;
          border-bottom: none;
        }
      }

      tbody {
        .survey_row-action__toolbar {
          display: flex;
          justify-content: space-between;

          .survey_row-action__action-button {
            width: 35px;
            height: 30px;
            border-radius: 3px;
            display: inline-block;
            background: white;
            border: 1px solid #cccccc;
            padding: 5px 10px;
            color: #333;
            cursor: pointer;

            &:hover {
              color: #333;
              background-color: #e6e6e6;
            }
          }

          .btn-group {
            &.disabled {
              color: var(--gray);
              opacity: .5;
              pointer-events: none;

              svg {
                color: var(--gray);
              }
            }
          }
        }

        td {
          padding-top: 6px;
          padding-bottom: 6px;

          .disabled {
            opacity: 0.7;
          }

          &:nth-child(1) {
            @include nth-child-1;

            & .id-wrapper {
              @include id-wrapper;
            }

            div {
              display: inline-block;
            }

            svg {
              margin-right: 10px;
            }
            .disabled {
              opacity: 0.3;
            }
          }

          &:nth-child(2) {
            padding-left: 0;
            padding-right: 0;

            svg {
              color: #777;
              margin-right: 5px;
            }

            .wrap {
              @include wrap-styles;
              display: flex;
              align-items: center;

              .surveyNameWrapper {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                vertical-align: middle;
              }
            }
          }

          &:nth-child(3) {
            .channels {
              span.label {
                width: 43px;
                display: inline-block;
                text-align: center;
                font-size: 87%;
                text-shadow: 1px 1px 1px rgba(0,0,0,0.3);

                &.label-default {
                  opacity: 0.2;
                }
              }
            }
          }

          &:nth-child(4) {
            padding-left: 0;
            padding-right: 0;

            .rate-wrapper {
              .rate-cell {
                margin-bottom: 5px;
                .progress {
                  display: inline-flex;
                  vertical-align: middle;
                  width: 95px;
                  height: 14px;
                  margin: 0;
                }
                span {
                  display: inline-block;
                  vertical-align: middle;
                  color: #666;
                  font-size: 13px;
                  &.info-title {
                    width: 75px;
                    text-align: right;
                    margin-right: 8px;
                  }
                  &.info-status {
                    width: 53px;
                    text-align: left;
                    margin-left: 8px;
                  }
                }
              }
            }

            .disabled {
              opacity: 0.5;
              .progress-bar-success {
                background: #ccc;
              }
            }
          }

        }
      }

      &.table-loading {
        opacity: 0.3;
        background: rgba(0, 0, 0, 0.05);
        td {
          &, a {
            color: #666;
          }
          .progress-bar,
          .label {
            background: #b1b1b1;
          }
        }
      }

      &.table-gray {
        opacity: 0.8;
        background: rgba(0, 0, 0, 0.01);
        td {
          border: 1px solid #fff;
          &, a {
            color: #666;
          }
          .progress-bar,
          .label {
            background: #b1b1b1;
          }
        }
      }
    }
  }

  &-pagination {
    margin-top: 40px;
    text-align: center;

    .pagination {
      margin: 0 auto;
      li a {
        opacity: 1;
        transition: opacity .6s ease-out;
        min-width: 41px;
        text-align: center;
      }
    }

    .pagination-form {
      display: inline-block;
      float: right;
      .input-group {
        width: 228px;
      }
      .input-group-addon {
        background: #fff;
        color: #666;
        padding: 8px 10px;
        border: 1px solid #ced4da;
        border-right: none;
        line-height: 14px;
      }
      .form-control {
        width: 66px;
        z-index: 1;
      }
      .btn {
        width: 40px;
        z-index: 2;
        margin-left: -1px;
      }
    }

    .info {
      display: block;
      margin-top: 5px;
      color: #777;
    }

    &-loading {
      .pagination {
        li {
          .page-link {
            opacity: 0.4;
            background: rgba(0, 0, 0, 0.1);
            color: #666;
          }
          &.active {
            .page-link {
              background: #3f9bb7;
              opacity: 1;
              color: #fff;
            }
          }
        }
      }
      .pagination-form {
        opacity: 0.3;
        .input-group-addon {
          background: #ccc;
        }
        input.form-control {
          outline: none;
          border: 1px solid #ccc;
          &.error {
            border-color: red;
          }
        }
        .btn {
          border: none;
          background: #666;
        }
      }
    }
  }
}

.surveys-list-modern-sortable-helper {
  display: table;
  background: rgba(93, 186, 255, 0.2);
  outline: 1px dashed rgba(64, 149, 255, 0.6);
  box-shadow: 0 1px 10px rgba(255, 255, 255, 0.9) inset;

  td {
    display: none;

    svg {
      margin-right: 10px;
    }

    &:nth-child(1),
    &:nth-child(2) {
      display: table-cell;
      padding: 6px 0;
      vertical-align: middle;
    }

    &:nth-child(1) {
      @include nth-child-1;

      & .id-wrapper {
        @include id-wrapper;
      }
    }

    &:nth-child(2) {
      padding-left: 0;
      padding-right: 0;
      @include width-nth-child-2(max-width);

      svg {
        margin-right: 5px;

        & path {
          fill: #777;
        }
      }

      .wrap {
        @include wrap-styles;
      }
    }
  }
}

.surveys-list-pagination {
  display: flex;
}
.modal-note {
  color: #444;
  text-align: center;
  border-top: 1px dashed #cccc;
  padding: 20px 0 0;
}

.deleted-filter__icon {
  font-size: 19px;
  margin-right: 7px;
  vertical-align: -3px;
}

// Small spinner (default styles)
.fa.fa-circle-o-notch.fa-lg {
  color: #666;
  margin-right: 10px;
}

// ###
// ###  Preloader: Progressbar
// ###

.preloader-progressbar {
  $size: 4px;
  position: relative;
  overflow: hidden;
  height: $size;
  margin-bottom: -$size;
  background: rgba(0, 0, 0, 0.1);
  &:before {
    $bg-color: #999;
    $bg-opacity: 0.2;
    $color-1: color-stop(0%, rgba(0, 0, 0, $bg-opacity));
    $color-2: color-stop(50%, $bg-color);
    $color-3: color-stop(100%, rgba(0, 0, 0, $bg-opacity));
    display: block;
    position: absolute;
    content: "";
    left: -200px;
    width: 200px;
    height: 4px;
    background: -webkit-gradient(linear, left top, right top, $color-1, $color-2, $color-3);
    animation: loading 1.3s infinite linear;
  }
}

.surveys-list__progress {
  background-color: #e4e4e4;
}

.filters-right__wrapper {
  padding-right: 0;
}

@keyframes loading {
  from {left: -200px; width: 30%;}
  50% {width: 30%;}
  70% {width: 70%;}
  80% {left: 50%;}
  95% {left: 80%;}
  to {left: 100%;}
}
// --------------------------------------------------------------------------------------------------------------------

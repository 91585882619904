.attach-survey__select.attach-survey__select {
  & .Select-value {
    color: #333;
    font-size: 14px;
    margin-top: 3px;
    border-color: #ccc;
  }

  & .Select-value-icon {
    font-weight: bold;
    padding: 2px 8px;
    font-size: 20px;
    line-height: 18px;

    &:hover {
      color: #ad0000;
      background: #fff;
    }
  }

  & .Select-value-label {
    background: #fff;
    border-color: #ccc;
    padding: 3px 8px;
  }
}

.attach-survey-modal {
  .modal-dialog {
    width: 600px;
    max-width: 600px;
  }

  .modal-body {
    overflow-y: auto;
  }

  .vochub-select-control {
    max-height: 400px;
    overflow-y: auto;
  }
}


.table-hover {
  border-spacing: 0;
  tbody {
    tr {
      &.row-blacklisted {
        border-left: 4px solid #d9534f;

        &:hover {
          background-color: #fff1f2;
        }
      }
    }
  }
}

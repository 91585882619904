.virtual-source-action-buttons {
  text-align: right;

  .btn:first-child {
    margin-right: 10px;
  }
}

.virtual-source__go-back {
  width: 100%;
  padding: 13px 0;

  .btn {
    padding-left: 0;
  }
}

.virtual-source__header-title {
  padding-bottom: 15px;
}

.virtual-source__label-sync {
  display: flex;
  align-items: flex-end;
  padding-bottom: 10px;
  justify-content: center;
  width: 39px;
}

.virtual-source__label-reorder{
  border: none !important;
  padding: 0 !important;

  &:hover {
    background-color: transparent !important;
  }

  &:focus {
    box-shadow: none !important;
  }

  svg {
    height: 18px;
    width: 18px !important;
  }
}
.virtual-source__input-wrapper {
  display: flex;
  align-items: flex-end;
  font-weight: 400;

  & > input {
   margin-bottom: 8px;
  }
}

.virtual-source_input-label {
  font-weight: 500;
  padding-left: 5px;
}

.virtual-source_input-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.classifierVisualSection {
  background: #f4f4f4;
  padding: 0 24px;
  padding-bottom: 30px;
  flex: 1;

  :global(.selector-view-buttons-panel) {
    padding-top: 14px;
    margin-top: 0;
  }
}

.preloaderGraphs {
  display: flex;
  flex: 1;
  align-items: center;
}

.preloaderGraphs {
  height: 100%;

  :global(.loader) {
    &:before, &:after {
      background: #f4f4f4;
    }
  }
}

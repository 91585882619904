.settings-navigation-wrapper {
  position: relative;
  z-index: 1;
}

.enable-language__box {
  padding-left: 5px;

  .form-check-input {
    margin-left: 0;

    ~ label {
      padding-left: 20px;
    }
  }
}

.language-list__box,
.language-list__box--blue {
  width: 100%;
  border: 1px solid;
  display: block;
  padding: 15px;
  text-align: center;
  border-radius: 4px;
}

.language-list__box {
  border-color: #ebccd1;
  color: #a94442;
}

.language-list__box--blue {
  color: var(--secondary);
  border-color: var(--primary);
  margin-bottom: 20px;

  a:link, a:active, a:visited {
    color: var(--primary);
  }
}

.dateOptions {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;

  p {
    padding: 5px 7px;
    margin: 0;
    color: #337ab7;
    cursor: pointer;

    &.active, &:hover {
      opacity: .6;
    }
    &.active {
      pointer-events: none;
    }
  }
}

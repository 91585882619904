.input-min-max-number {
  display: flex;
  flex-direction: column;
  align-items: self-start;
  align-content: flex-start;
  width: min-content;

  & .title {
    font-weight: 700;

    & .star {
      margin-left: 3px;
      color: #FF0000;
    }
  }

  & .form {
    display: flex;
    flex-direction: row;

    & .field-group {
      display: block;

      & .input-place {
        position: relative;

        & .label {
          color: #A0A0A2;
          position: absolute;
          left: 5px;
          top: 0;
          bottom: 0;
          margin: auto;
          width: 30px;
          height: 21px;
          display: inline-block;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        & .input {
          color: #000000;
          margin-right: 8px;
          width: 96px;
          padding-left: 35px;
          padding-right: 0;
          background-image: none;
          z-index: -1;
          -moz-appearance: textfield;

          &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }
      }

      & .error {
        margin: 0;
        color: var(--red) !important;
        font-size: 80%;
        font-weight: 400;
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 96px;
      }
    }
  }
}

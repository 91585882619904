.survey-channel {
  width: 43px;
  display: inline-block;
  text-align: center;
  font-size: 87%;
  margin-right: 5px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  font-weight: 700;
  line-height: 1;
  color: #fff;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;
  padding: .5em .6em .3em;

  &.inactive {
    background-color: #777;
    opacity: .2;
  }

  &.active {
    background-color: #5cb85c;
  }

  &.stopped {
    background-color: #ffcc00;;
  }

  &.paused {
    background-color: #f0ad4e;
  }

  &.deleted {
    background-color: #d9534f;
  }
}
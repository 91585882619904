.insightBlockContainer {
  display: flex;
  flex-direction: column;
  width: 100%;

  .insightControls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    overflow: hidden;
    padding: 18px 12px;
    border-top: 1px solid #dee2e6;

    .grayHint {
      color: var(--gray);
      font-size: 12px;
    }

    :global(.btn) {
      margin-left: 12px;

      &:focus {
        box-shadow: none;
      }
    }
  }
}

.dictionaries__header-wrapper {
  margin-top: 1.5rem;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--new-gray);

  .dictionaries__backBtn {
    margin-left: .25rem;
    cursor: pointer;
    width: fit-content;
    padding: .5rem .25em;
    font-size: 1.2rem;
    color: var(--gray);
    display: flex;
    align-items: center;

    .leftArrow-icon {
      margin-right: .5rem;
    }

    &:hover {
      opacity: .8;
    }
  }

  .dictionaries__header {
    margin-top: 1.5rem;
    display: flex;
    justify-content: space-between;

    .dictionaries__header-controls {
      display: flex;

      .dictionaries__header-btn {
        height: 32px;
        background: var(--primary);
        border: 1px solid #c2c8cc;


        svg {
          margin-right: .25rem;
        }

        &:active {
          outline: none !important;
          box-shadow: 0 0 0 0.2rem rgba(51, 122, 183, 0.25) !important;
          background: var(--primary) !important;
          color: var(--white) !important;
        }

        &:hover {
          background-color: var(--primary) !important;
        }

        &:focus {
          border-color: #255985 !important;
          background: #285f8f !important;
          box-shadow: 0 0 0 0.2rem rgba(51, 122, 183, 0.25);
        }
      }

      .form-control {
        height: 32px;
        width: 240px;
        margin-right: 1rem;
      }
    }
  }

  .search-icon {
    background-image: url('../../../../../../components/Icons/svg/search.svg');
    background-repeat: no-repeat;
    background-position: .5rem center;
    padding-left: 2.75rem;
  }
}

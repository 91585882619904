.quarantine-flow-popover-inner {

  .flow-preloader-box {
    position: absolute;
    z-index: 99999;
    width: 100%;
    height: 100%;
    background: #fff;
  }

  .popover {
    padding: 10px;
    max-width: 400px;
  }
}

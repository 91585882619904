.checkboxesWrapper {
  display: flex;
  flex-direction: row;

  & .radio_label {
    padding-left: 18px;

    &:not(:first-child) {
      margin-left: 18px;
    }
  }
}

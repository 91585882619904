.multi-value-label_container {
  display: flex;
}

.multi-value-label_title {
  display: inline-block;
  float: left;
}

.multi-value-label_badge {
  display: inline-flex;
  justify-content: flex-end;
  padding-left: 12px;
}

.empty-data {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 168px;

  .query-details-chart__item-btns {
    margin-right: auto;
  }

  &__img {
    margin-bottom: 24px;

    img,
    svg {
      display: block;
      max-width: 100%;
    }
  }

  &__title {
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #53565A;

    &-error {
      color: #d9534f;
    }
  }

  &__subtitle {
    font-size: 14px;
    line-height: 24px;
    color: #A0A0A2;

    &-error {
      color: #d9534f;
    }
  }

  .ranger-place {
    width: 100%;
  }
}

.error-exclamation-circle {
  width: 216px !important;
  height: 216px !important;
}

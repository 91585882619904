.queryFilter-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 24px 0;

  & .filter-button-group {
    padding-bottom: 25px;

    .mine-button-dropdown__wrapper button {
      padding: 5px 16px;
      min-width: 115px;
    }
  }

  & .button-reset__filters {
    float: right;
    color: var(--primary);

    &.btn-link:hover {
      text-decoration: none;
      color: var(--primary);
    }

    svg {
      margin-right: 10px;
    }
  }
}

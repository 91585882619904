.customTabsSelectorWrapper {
  margin-left: -25px;
  margin-right: -25px;
  margin-top: -25px;
}
.modalInner {
  min-height: 421px;
  display: flex;
  align-items: center;
}

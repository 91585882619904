.navigationWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 5px;

  .fieldsInNavigationWrapper {
    display: flex;
    justify-content: end;
    flex: 1;
  }

  .classifierField {
    flex: 1;
    margin-left: 10px;
  }

  .classifierFieldLanguage {
    max-width: 165px;

    :global(.vochub-select-control) {
      margin-bottom: 0;
    }
  }

  .classifierFieldClassifier {
    max-width: 250px;
    input {
      height: 38px;
    }
  }

  .backButton {
    height: 38px;
    color: #a0a0a2 !important;
    text-decoration: none !important;
    display: flex;
    align-items: center;
    margin-right: 20px;

    svg {
      margin-right: 5px;
    }

    &:hover {
      color: #22527b !important;
    }
  }
}

.classifierFieldLanguageOption {
  position: relative;
  padding-left: 16px;
  display: flex;
  align-items: center;

  .classifierFieldLanguageExist {
    position: absolute;
    left: 1px;

    &:before {
      content: '';
      display: block;

      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: #2fb947;
    }
  }

  .classifierFieldLanguageActive {
    position: absolute;
    width: 9px;
    height: 8px;
    left: 1px;

    &:before {
      content: '';
      display: block;
      border: solid #007aff;
      border-width: 2px 2px 0 0;
      transform: rotate(137deg);
      width: 8px;
      height: 6px;
    }
  }
}

.relativePeriodWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  .timeString {
    margin-top: 10px;
    margin-bottom: 0;
    text-align: center;
  }

  .selectsWrapper {
    display: flex;
    justify-content: space-between;

    .selectWrapper {
      width: 30%;

      .fromToInput {
        height: 38px;
      }
    }

    .selectWrapperDate {
      width: 35%;
    }

    @media (max-width: 500px) {
      flex-direction: column;

      .selectWrapperDate, .selectWrapper {
        width: 100%;
        margin-bottom: 10px;
      }

      .selectWrapperDate {
        margin-bottom: 0;
      }
    }
  }
}

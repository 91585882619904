.virtual-source-scroll-buttons__wrapper {
  position: absolute;
  top: 0;
  right: 0;
  width: fit-content;
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  user-select: none;

  .virtual-source-scroll-buttons__button {
    cursor: pointer;
    height: 30px;

    &:last-child {
      margin-left: 25px;
    }

    &:hover {
      svg {
        color: var(--primary-hover);
      }
    }

    svg {
      color: var(--blue);
      width: 30px;
      height: 30px;
    }


    &.virtual-source-scroll-buttons__button--disabled{
      pointer-events: none;

      svg {
        color: var(--gray);
      }
    }
  }
}

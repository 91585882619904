.additionalFilters {
  margin-bottom: 10px;
  margin-left: -25px;

  .additionalFiltersSurvey {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 10px;

    .stopped {
      background: #ffcc00;
      color: #212529;
    }
  }

  &.relative {
    margin-bottom: 0;
    position: absolute;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    bottom: 0;
    right: 0;
    left: 0;
    padding: 0 5px 5px;
    border: 1px solid #ddd;
    background: #fff;
    box-sizing: border-box;
    box-shadow: 1px 1px 5px -3px black;
    z-index: 2;
  }

  .additionalFiltersInput {
    display: inline-block;
    vertical-align: middle;
    margin: 0 5px 0 0;
  }

  .additionalFiltersLabel {
    display: inline-block;
    vertical-align: middle;
    margin: 0 10px 0 0;

    &--uppercase {
      text-transform: uppercase;
    }
  }

  .additionalFiltersLabelSwitch {
    vertical-align: top;
  }

  .additionalFiltersSurveyLabel {
    margin-bottom: 0;
  }

  .additionalFiltersForm {
    margin: 8px 0;
  }

  .showHiddenWrapper {
    min-width: 270px;
  }
}

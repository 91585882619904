.voc-table {
  border-bottom: 2px solid #ddd;

  &__empty {
    td {
      text-align: center;
    }
  }

  &__total-row {
    margin-bottom: 0;
    line-height: 37px;
  }

  &__wrapper {
    position: relative;

    &-loading {
      opacity: .4;
      pointer-events: none;
    }
  }

  &__header {
    background: #fff;

    &--sticky {
      display: table;
      width: 100%;
      table-layout: fixed;
      position: relative;
      z-index: 1;
    }
  }

  &__header--sticky + .voc-table__body {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  & .voc-table_th-cell {
    word-break: normal;
    overflow-wrap: break-word;
    border-top: none;
    user-select: none;

    svg:not(:root).svg-inline--fa {
      margin-left: 5px;
    }
  }

  &-header__checkbox {
    position: initial;
    margin: 0;
  }

  .th-filter-header-cell {
    width: 23px;

    &.filter-active {
      color: var(--primary)
    }
  }

  .th-filter-header-cell,
  .th-sort-header-cell {
    display: inline-block;
    cursor: pointer;
  }

  & .th-contentWrapper {
    display: flex;
    flex-direction: row;

    & .th-sort-header-cell {
      flex: 1;
    }
  }
}

//Todo rewrite this
.table-actions {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .table-action-btn-wrapper:not(:last-child) {
    text-align: center;
    border: none;
    margin-right: 8px;
  }

  .button:not(:disabled) {
    color: #337ab7;
    cursor: pointer;
  }

  .table-action__glyph:hover {
    color: #23527c;
  }

  .table-action-link--disabled {
    cursor: default;
    opacity: 0.65;
    text-decoration: none;
    pointer-events: none;
  }
}

.table-action__container {

  span {
    display: inline-block;
    cursor: pointer;
    margin-right: 15px;
    color: var(--primary);

    &.disabled {
      pointer-events: none;
      opacity: .7;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.table-tag__label {
  background: #FDD37D;
  color: #212529;
  padding: 4px 8px;
  border-radius: 2px;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  max-width: 100%;
  vertical-align: middle;
}

.table-coll__icon-primary {
  color: var(--primary);
}

.table-coll__icon-disabled {
  color: var(--gray);
  opacity: .5;
  pointer-events: none;

  svg {
    color: var(--gray);
  }
}

.table-action__btn-share {
  margin-left: 8px;
  margin-right: 8px;
  color: var(--primary);

  &.disabled {
    color: var(--gray);
    opacity: .5;
    pointer-events: none;
  }
}

tr.expandable > td {
  box-shadow: inset 0 3px 6px -3px rgba(0, 0, 0, .2);
  padding: 0;
}

tr.expandable > td > .inner {
  margin: 15px;
  overflow: hidden;
}

.fieldWrapper {
  margin-bottom: 14px;
}

.multiTopicWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .multiTopicLabel {
    margin-right: 10px;
    width: 72px;
  }
}

.selectOption {
  :global(.Select-options--sources) {
    max-width: 100% !important;
  }
}

.selector-view-buttons-panel {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));

  @media screen and ('max-width: 1199px') {
    flex-wrap: wrap;
  }

  &__button {
    &.btn {
      width: 100%;
      min-width: 110px;
      max-width: 135px;
      border-radius: 16px;
      padding: 16px 0 8px;
      display: flex;
      flex-direction: column;
      align-content: center;
      align-items: center;
      margin-right: 13px;
      //ToDo: Test Andrey Sluzhenko (VOC-8249
      background-color: transparent;
      border: 1px solid transparent;

      &:active, &:focus {
        outline: none;
        box-shadow: none;
      }

      &::-moz-focus-inner {
        border: 0;
      }

      &:hover {
        background-color: var(--new-gray);
      }

      .icon {
        height: 24px;
        width: 24px;
      }
    }

    &.co-occurrence {
      padding: 16px 21px 8px;
    }

    &.active {
      background-color: var(--new-gray);
    }
  }
}

.progressbar-values {
  &__list {
    list-style: none;
    margin: 3px 0 0;
    padding: 0;
  }

  &__list-item {
    overflow: hidden;
    display: flex;
  }

  &__title {
    width: calc(100% - 40px);
    color: #777;
    white-space: normal;
    font-size: 80%;

    span {
      white-space: nowrap;
    }
  }

  &__percent {
    width: 40px;
    text-align: right;
    color: #31708f;
  }
}

.bubbleChartWrapper {
  position: relative;
  overflow: hidden;
  display: flex;
  flex: 1 1;
  flex-direction: column;
}

.bubble {
  opacity: 1;
  transition: 300ms;
  cursor: pointer;

  &.hovered {
    opacity: 0.6;
  }
}

.circleNumber {
  font-size: 12px;
  transform: rotate(-65deg);
  cursor: pointer;
}

.user__dropdown {
  display: inline-block;
  cursor: pointer;
}

.user__dropdown-menu {
  width: 185px;
  //margin-top: 35px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.33);

  .dropdown-divider {
    margin-top: 0;
  }
}

.user__dropdown-menu .dropdown-header {
  color: #000;
  font-size: 14px;
}

.user__dropdown-arrow {
  top: -25px;
  left: 80%;
  width: 0;
  height: 0;
  position: relative;

  &::after,
  &::before{
    content: "";
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-width: 7px 8px;
    border-style: solid;
    border-color: transparent;
    z-index: 1001;
  }

  &::before {
    bottom: -17px;
    right: -8px;
    border-bottom-color: rgba(0,0,0,.15);
  }

  &::after {
    bottom: -18px;
    right: -8px;
    border-bottom-color: #fff;
  }
}

.tox-silver-sink {
  z-index: 1040;

  &.aux-modal-mediaBlock {
    z-index: 1;
  }
}

.tox-dialog-wrap {
  z-index: 1040;
}

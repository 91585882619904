.actionMenuWrapper {
  display: flex;
  justify-content: center;

  .actionButton {
    margin-left: 10px;

    &:focus {
      box-shadow: none;
    }

    svg {
      pointer-events: none;
    }
  }
}

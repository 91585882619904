.footerInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 10px 5px;
  font-size: 12px;

  &.left {
    justify-content: flex-start;
  }

  &.right {
    justify-content: flex-end;
  }

  & .recordsLabel {
    white-space: nowrap;
    padding-right: 6px;
  }

  & .lastHealthCheckTime {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: white;

    @media print {
      display: none;
    }
  }
}

.configurable-answers-item {
  height: 60px;

  &__drag-icon {
    text-align: center;
    line-height: 34px;
  }

  &__drag-icon-narrow {
    max-width: 5% !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .question__answer-icon {
    pointer-events: none;
  }

  .configurable-answers-item_unsubscribe_checkbox {
    margin-left: 12px;

    .voc-checkbox {
      margin-top: 6px;
    }
  }
}


.answer-name-input-language {
  position: absolute;
  top: 6px;
  right: 25px;
}

.question__answer-input {
  .app-form-control__error {
    color: #d9534f !important;
    font-size: 13px;
    width: 450px;
    bottom: -8px !important;
    left: 0;
    font-weight: bold;
  }
}

.question__answer-checkbox {
  .voc-checkbox__label {
    font-weight: 400;
  }
}

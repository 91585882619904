.btn-group-vertical > .btn.active,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:hover,
.btn-group > .btn.active,
.btn-group > .btn:active,
.btn-group > .btn:focus,
.btn-group > .btn:hover {
  z-index: 0;
}

.btn-default.focus,
.btn-default:focus {
  border-color: #ccc;
  color: inherit;
  background-color: inherit;
}

.btn-primary.focus,
.btn-primary:focus {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
}

html {
  .form-control:focus {
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
  }

  .form-control::placeholder {
    color: #bcbcbc;
  }

  .vochub-select-control__placeholder {
    color: #bcbcbc;
  }

}

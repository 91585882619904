.thin-spinner__loading {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 50;

  //todo rewrite this
  &.react-bs-table {
    opacity: .4;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 555;
    margin-top: -50px;
    margin-left: -50px;
    animation: spinner 1s linear infinite;
    border: 3px solid #1c75f3;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border-left: none;
    border-top: none;
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg)
  }
  50% {
    transform: rotate(180deg)
  }
  100% {
    transform: rotate(360deg)
  }
}

.optionsWrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-left: 20px;

  margin-bottom: 10px;
}

.surveyStatusWrapper {
  .surveyStatusList {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-left: 20px;

    .surveyStatusListTitle {
      font-size: 12px;
      color: white;
      padding: 2px 6px;
      border-radius: 4px;
    }

  }
}

.uploadsWrapper {
  .uploadsSwitchWrapper {
    display: flex;
    align-items: center;

    :global(.switch-wrap){
      display: flex;
    }
  }

  .uploadsLabel {
    margin-right: 15px;
  }
}

.sourceFilter {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  padding-bottom: 10px;

  &.openSourceFilter {
    opacity: 1;
    pointer-events: all;
    position: initial;
  }
}

@import '~/styles/themes/variables';

.gridPageWrapper {
  position: relative;
  background: #e1e3e7;
  border-bottom: 2px solid #fff;
}

:global(.react-grid-item.react-grid-placeholder) {
  background-color: $gray-600;
}

:global(.fleshPage) {
  animation: fleshPage 500ms 2 linear 1s;
}

@keyframes fleshPage {
  from {
    box-shadow: inset 0 0 10px 1px #ECF0F6;
  }

  50% {
    box-shadow: inset 0 0 10px 1px var(--primary);
  }

  100% {
    box-shadow: inset 0 0 10px 1px #ECF0F6;
  }
}

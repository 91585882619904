.setting-html__container {
  width: 100%;

  .ace_scrollbar {
    z-index: 0;
  }

  .ace_gutter, .ace_content {
    z-index: 0;
  }

  .ace_editor {
    border-right: none !important;
    border-bottom: none !important;
    border-left: none !important;
  }

  .preview-iframe {
    min-height: 294px;
  }
}

.setting-html-preview__container {
  min-height: 337px;
  padding: 18px 10px;
  width: 100%;
}
.setting-expand__button {
  width: 30px;
  padding-right: 0 !important;
  padding-left: 0 !important;

  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

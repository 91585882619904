.insightPromptModal {
  .infoBtn {
    color: var(--primary);
    margin-left: 6px;
    cursor: pointer;
    line-height: 1;
    vertical-align: text-bottom;
  }

  .queryContextFieldWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    background-color: #F4F4F4;
    border-radius: 5px;
    margin-bottom: 12px;

    .queryContextField {
      flex: 1;
      margin-right: 12px;
      // fixed for https://sandsiv.atlassian.net/browse/VOC-13515
      max-height: 40vh;
      overflow-y: auto;

      :global(#queryContext) {
        max-height: inherit;
      }

      > span {
        padding: 0.375rem 0.75rem;
      }

      .warningBlock {
        margin-bottom: 0;
      }
    }

    :global(.btn:focus) {
      box-shadow: none;
    }
  }

  .languageOutWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    > label {
      padding-right: 12px;
      margin-bottom: 0;
    }

    :global(.select-control-wrapper) {
      flex: 1;
    }

    .languageOutField {
      margin-bottom: 0;
    }
  }

  .grayHint {
    color: var(--gray);
    margin-top: 10px;
    display: block;
    font-size: 12px;
  }

  .preconfiguredQuestionsWrapper {
    display: flex;
    flex-direction: column;
    padding: 12px;
    background-color: #F4F4F4;
    border-radius: 5px;

    .preconfiguredQuestionLabel {
      padding-left: 1.75rem;
      font-weight: 500;

      &.minusMark {
        input[type="checkbox"] {
          &:after {
            content: '-';
            position: absolute;
            font-size: inherit;
            text-align: center;
            width: 100%;
            height: 100%;
            line-height: calc(100% - 1px);
            background-color: inherit;
            pointer-events: none;
          }
        }
      }

      .loading {
        display: inline-flex;
        width: 18px;
        height: 18px;
        margin-left: 6px;
        border: 2px solid var(--new-gray);
        border-top-color: var(--primary);
        border-radius: 50%;
        background-color: inherit;
        pointer-events: none;
        vertical-align: text-top;
        animation: spinner .6s linear infinite;
      }

      @keyframes spinner {
        to {transform: rotate(360deg);}
      }
    }

    .existed {
      display: inline-flex;
      color: var(--success);
      margin-left: 6px;
      vertical-align: text-bottom;

      svg {
        font-size: 14px;
      }
    }
  }

  :global(.btn) {
    &.active {
      background-color: var(--yellow);
      border-color: var(--yellow);
      color: var(--secondary);
    }
  }
}

// example class="style_questionInfoTooltip__a3_5a tooltip-inner"
:local(.questionInfoTooltip):global(.tooltip-inner) {
  max-width: 420px;
  padding: 1rem;

  .popupWithList {
    ul {
      text-align: left;
      margin-top: 0.5rem;
      margin-bottom: 0;
      padding-inline-start: 20px;
    }
  }

  p {
    margin-bottom: 0;
  }
}

.timeDateGrid {
  .timeDateGrid__cell {
    background: var(--blue);
  }

  .timeDateGrid__cell:hover {
    opacity: .75;
  }

  .red--cell {
    background: var(--red);
  }

  .forbidden--cell {
    cursor: not-allowed !important;
  }

  .forbidden--cell:hover {
    opacity: unset;
  }
}

.timeDateGrid__no-data-message {
  text-align: center;
}

.remove-data {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  margin: 0;

  span:first-child {
    padding: 2px 4px 0 0;
    cursor: pointer;
  }

  path {
    fill: var(--red);
  }
}

.timeDateGrid {
  &.start-selected-red {
    .ds-hover {
      background: var(--blue);
    }
  }

  &.start-selected-blue {
    .ds-hover {
      background: var(--red);
    }
  }
}


.insightSideBlock {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  pointer-events: none;

  &.show {
    pointer-events: auto;

    & .backdrop {
      opacity: 1;
      cursor: pointer;
    }

    & .insightSideBlockSplit {
      transform: translateX(0);

      & .controls {
        right: -1000%; // show from right side when content block collapsed
      }
    }
  }

  & .backdrop {
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(0, 0, 0, .45);
    opacity: 0;
    transition: opacity .5s ease-in-out;
  }

  & .insightSideBlockSplit {
    display: flex;
    justify-content: right;
    align-items: center;
    transform: translateX(calc(100% - 24px));
    transition: transform 0.3s ease-in-out;
    pointer-events: none;

    & .controls {
      top: 30%;
      right: 0;
      transform: translate(0, -50%);
      transition: all 0.3s;
      pointer-events: auto;

      & :global(.btn) {
        width: 24px;
        height: 64px;
        padding: 0.2rem;

        &:first-child {
          border-top-right-radius: 0;
        }

        &.toggleContentBtn {
          border-bottom-right-radius: 0;
        }

        &.withError {
          background-color: var(--danger);
          border-color: var(--danger);
        }

        &.active {
          background-color: var(--green);
          border-color: var(--green);

          & > svg path {
            fill: var(--white);
          }
        }
      }
    }

    & .insightContentPane {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      background: #F4F4F4;
      pointer-events: auto;

      & .insightHeader {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;
        border-bottom: 1px solid #dee2e6;
        margin-bottom: 12px;

        & > h5 {
          margin-bottom: 0;
        }

        & .close {
          background-color: transparent;
          border: 0;
        }
      }
    }

    :global(.react-split__sash) {
      pointer-events: auto !important;
    }
  }
}

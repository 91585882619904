.controlledTooltipWrapper {
  :global(.tooltip.show) {
    pointer-events: all !important;
    opacity: 1;

    &:global(.bs-tooltip-auto[x-placement^=top] .arrow::before) {
      border-top-color: white;
    }

    &:global(.bs-tooltip-auto[x-placement^=bottom] .arrow::before) {
      border-bottom-color: white;
    }

    &:global(.bs-tooltip-auto[x-placement^=left] .arrow::before) {
      border-left-color: white;
    }

    &:global(.bs-tooltip-auto[x-placement^=right] .arrow::before) {
      border-right-color: white;
    }

    :global(.tooltip-inner) {
      max-width: 100% !important;
      background-color: white;
      box-shadow: rgb(0 0 0 / 15%) 0 8px 16px;
    }
  }
}

/* Range Slider styles */
.rangeWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 24px;
  width: 100%;
  height: 36px; // height of labels container + recordsLabel
  background-color: white;

  & .slider {
    & .mark {
      color: #337ab7;
      font-size: 14px;
    }
  }
}

.popperInnerClass {
  max-width: 100% !important;
}

.table-action__container.table-action__exporters-list {
  .table-action__exporters-list-icon {

    &:last-child {
      margin-right: 0;
    }
  }
}

.question-channels-panel__wrap {
  margin-bottom: 20px;

  .survey-navigation__button.btn-success {
    padding: 0.215rem 0.625rem;
    margin-bottom: 4px;

    .survey-navigation__button-text {
      padding-left: 10px;
    }
  }
}

.question-channels-panel__info {
  > div {
    display: inline-block;
    padding: 0 5px;
  }

  b {
    padding-left: 10px;
  }
}

.question-channels-panel {
  display: flex;
  align-items: center;
  animation: fade-in 1s 0s linear forwards;
  height: 44px;
  width: 100%;

  .btn-group {
    margin-bottom: 4px;
  }
}

.survey-title {
  display: inline-block;
  margin: 0;
  max-width: 400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 18px;

  span {
    display: inline-block;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.qustion-actions {
  display: flex;
  align-items: center;
}

.question-status-marker {
  text-transform: uppercase;
  padding: 5px .6em 3px;
  border-radius: .25em;
  line-height: 1;
  font-size: 12px;
  font-weight: 700;
  color: #fff;

  &.success {
    background-color: #5cb85c;
  }

  &.warning {
    background-color: #f0ad4e;
  }

  &.danger {
    background-color: #d9534f;
  }

  &.info {
    background: #5bc0de;
  }
}

.btn.question-icon-control {
  padding-left: 7px;
  padding-right: 7px;
}

.btn.channels {
  &-controls {
    padding: 0.215rem 0.75rem;

    .glyphicon {
      margin-right: 5px;

      &-remove {
        vertical-align: text-top;
      }
    }
  }
}

.question-channels-loading {
  height: 34px;
  text-align: center;
}


@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.selector-status-survey,
.selector-manage-languages,
.selector-type-slots {
  width: 112px;
  height: 29px;
  margin-left: 10px;
  margin-bottom: 3px;

  > div {
    min-height: 29px;
    height: 29px;
  }

  .dropdown-indicator {
    width: 10px;
    height: 4px;
  }

  .vochub-select-control__placeholder ~ div,
  .vochub-select-control__single-value ~ div {
    height: 19px;
  }

  .vochub-select-control__input {
    input {
      position: absolute;
      left: -5px;
    }
  }
}

.selector-type-slots {
  width: 100%;
}

.channels-panel__right {
  display: flex;
  justify-content: space-between;
}

.question-action__status {
  .form-group {
    margin: 0;
  }

  p {
    width: 106px;
  }

  .select-control-label {
    display: none;
  }
}

.channels-panel__right .survey-navigation__button {
  margin-left: auto;
}

.tooltip_word-wrap
.tooltip
.tooltip-inner {
  white-space: pre-line;
}

.setting-css__container {
  width: 100%;

  .ace_scrollbar {
    z-index: 0;
  }

  .ace_gutter, .ace_content {
    z-index: 0;
  }

  .ace_editor {
    border-right: none !important;
    border-bottom: none !important;
    border-left: none !important;
  }

  .preview-iframe {
    min-height: 294px;
  }
}

.editor {
  height: 300px;
}

.editor.collapsed {
  height: 300px;
  overflow: hidden;
}

.setting-css__button-expand {
  position: absolute;
  right: 0;
  top: 0;
  width: 52px;
}

.setting-css__button {
  width: 30px;
  padding-right: 0 !important;
  padding-left: 0 !important;

  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

.button__file-manager {
  position: absolute;
  right: 45px;
  top: 0;
}

.button__expand {
  position: absolute;
  right: 15px;
  top: 0;
}

.setting-css__button-icon {
  color: var(--blue);
  width: 38px;
}

.modalWrapper {
  :global(.row) {
    margin: 0;
  }

  .fieldTitle {
    margin-bottom: 3px;
  }

  .fieldLabel {
    width: 100%;
  }

  .fieldWrapper {
    margin-bottom: 10px;
  }
}

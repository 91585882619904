.setting-control-group__container {
  padding: 18px 10px;
  flex: 100%;

  .setting-control-group__emails-title {
    color: #5c5c61;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0;
  }

  input {
    border-radius: 4px;
    border: 1px solid #8e8e93;
    background-color: #ffffff;
    height: 36px;
    width: 321px;
    padding: 0 10px;
  }

  .setting-control-group_button {
    height: 36px;
    border-radius: 4px;
    border: 1px solid #007aff;
    background-color: #ffffff;
    padding: 7px 27px 5px 43px;
    position: relative;
    margin-left: 17px;
    cursor: pointer;

    img {
      position: absolute;
      width: 13px;
      top: 10px;
      left: 20px;
    }
  }

}

.setting-control-group__emails-container {
  margin-top: 20px;
}

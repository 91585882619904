.word-cloud__wrapper {
  width: 100%;
  height: 100%;
  margin: auto;

  & svg {
    height: 100%;
    width: 100%;

    & text:hover {
      cursor: pointer;
    }
  }
}

.svg-text__filtered {
  fill: none;
  stroke-width: 1;

  &.svg-text__include {
    stroke: var(--green)
  }

  &.svg-text__exclude {
    stroke: var(--red)
  }
}

.svg-text__filtered.svg-text__selected,
.svg-text__selected {
  fill: var(--red);
  stroke: currentcolor;
  stroke-width: 1;
}

@import "base/index";
@import "themes/index";

%hyphenText {
  word-break: break-all;
  hyphens: auto;
}

a:hover {
  text-decoration: none;
}

.dander-text {
  color: var(--danger);
}

@media print {
  #body-container {
    header.header,
    aside {
      display: none;
    }
  }
}

//.react-grid-layout {
//  background-color: rgba(255, 0, 0, 0.2);
//  background-image: repeating-linear-gradient(#ccc 0 1px, transparent 1px 100%),
//  repeating-linear-gradient(90deg, #ccc 0 1px, transparent 1px 100%);
//  background-size: 93px 42px;
//}

.header__right-section {
  padding: 14px 9px 14px;
  box-sizing: border-box;
}

.headset__link {
  display: inline-block;
  cursor: pointer;
}

.headset__icon {
  width: 30px;
}

.help__icon {
  display: inline-block;
  cursor: pointer;
  margin-left: 14px;
}

.report__icon.btn {
  display: inline-block;
  background: transparent;
  border: none;
  margin-left: 17px;
  padding: 0 !important;
  width: 20px;
  height: 25px;

  &:focus,
  &:hover {
    box-shadow: none;
  }

  svg {
    width: 100%;
    height: 100%;
  }

  svg path {
    fill: #ffffff;
  }
}

.bell__container {
  margin: 0 14px;
}

@media (min-width: 768px) {
  .header__right-section {
    padding: 14px 33px 14px;
  }

  .help__icon {
    margin-left: 21px;
  }

  .bell__container {
    margin: 0 21px;
  }
}

.tags___block {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .tag-block_item {
    display: flex;
    flex-direction: row;
    border: 1px solid #E5E5E5;
    border-radius: 4px;
    background: #ECF0F6;
    font-size: 12px;
    padding: 0 5px;
    margin: 2px;
    align-items: center;

    span {
      margin-right: 4px;

    }

    .tag-block-item__delete-button {
      cursor: pointer;
      height: 6px;
      width: 6px;
    }
  }
}

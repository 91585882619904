.td-case-alerts_name {
  cursor: pointer;
  color: var(--primary);

  &:hover {
    text-decoration: underline;
    color: var(--primary-hover);
  }
}

.theme-list__item {
  &.disabled {
    color: var(--gray);
    opacity: .5 !important;
    pointer-events: none;

    svg {
      color: var(--gray);
    }
  }
}

.theme-page-wrapper {
  .voc-table_header-container {
    flex-direction: row-reverse;
    justify-content: space-between;

    .voc-table_header-search {
      margin-right: 0;
    }
  }
}

.fixed-table {
  &__col--sidebar {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
  }

  &__col--sidebar__autofill {
    background-color: #E5E5E5 !important;
  }

  &__col-icon {
    margin-top: 5px;
    align-items: center;
    justify-content: center;
  }

}

.virtual-source__modal_autofill_confirm {
  margin: 0 !important;
  position: absolute !important;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
}

.question__responses {
  opacity: 0.5;
  color: #000000;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
}

.questions-statistic__item {
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }

  .card-body {
    padding-top: 0;
  }
}

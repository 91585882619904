.voc-table__footer {
  display: flex;
  justify-content: space-between;
  //align-items: center;
}

.voc-table__pagination-box {
  position: relative;

  > nav, > .btn {
    display: inline-block;
  }

  > .btn {
    border: 1px solid #dee2e6;
    box-sizing: border-box;
    line-height: 1.5;
    vertical-align: top;
    margin-left: 10px;
    color: #337ab7;
    padding: .37rem .5rem;

    svg {
      vertical-align: middle;
    }

    &:hover {
      color: #337ab7;
      text-decoration: none;
      background-color: #e9ecef;
      border-color: #dee2e6;
    }
  }
}

.popoverBody__pagination {
  padding: 0;
  line-height: normal;

  .form-control {
    border-top: none;
    border-bottom: none;
  }
}

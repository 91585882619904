.zeroShotMineWrapper {
  display: flex;
  height: 100%;
  width: 100%;

  .zeroShotSplitPane {
    .topicsPane {
      padding-left: 10px;
      margin-left: -10px;
    }
    .visualPane {
      overflow: auto;
    }

    @media (max-width: 1140px) {
      display: flex;
      flex-direction: column;

      :global(.react-split__pane) {
        position: relative;
        width: 100% !important;
        height: auto;
        left: 0 !important;
        right: 0 !important;
      }

      :global(.react-split__sash) {
        display: none;
      }

      .topicsPane {
        padding-right: 0px;
      }
    }
  }
}

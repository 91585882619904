.export-navigation {
  margin-bottom: 20px;
}

.exporters-panel {
  div.panel-heading {
    padding: 0;

    h5 {
      font-weight: 700;
      padding-left: 15px;
      margin-top: 10px;
    }

    .btn.pull-right {
      width: 180px;
      max-width: 180px;
      padding: 8px 12px;
      border: 0;
      border-left: 1px solid #ddd;
      border-radius: 0;
    }
  }
}
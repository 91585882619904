.listWrapper {
  display: flex;

  .listLabel {
    margin-right: 7px;
    flex-shrink: 0;
  }

  .list {
    max-height: 100px;
    overflow: auto;

    p {
      margin-bottom: 5px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

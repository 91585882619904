.voc-iframe__wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.voc-iframe {
  margin-left: 60px;
  margin-top: 52px;
  width: calc(100% - 60px);
  height: calc(100% - 52px);
}

.voc-iframe__no-config-message {
  margin-top: 72px;
  padding: 0 20px;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .voc-iframe {
    margin-left: 0;
    width: 100%;
  }
}

.barChartWrapper {
  position: relative;
  overflow: hidden;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: inherit;

  :global(.bar.interactive) {
    cursor: pointer;

    &:focus-visible {
      outline: auto 2px var(--primary-hover) !important;
    }
  }

  :global(.itemHovered) {
    opacity: .7;
  }
}

.barGroup {
  text-anchor: middle;
}

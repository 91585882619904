.flow-popover-inner {

  .flow-preloader-box {
    position: absolute;
    z-index: 99999;
    width: 100%;
    height: 100%;
    background: #fff;
  }

  .loader {
    position: absolute;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%;
    margin: auto;
  }
}

.import-download-column {

  span {
    padding: 0 7px
  }
}

.import-history-row {
  td:first-child {
    padding: 0 0 0 10px;

    svg {
      pointer-events: none;
    }
  }

  &.row-control {
    td:first-child {
      cursor: pointer;
    }
  }
}

.import-additional-row {
  text-align: center;
}

.table-striped tbody tr:nth-of-type(odd):is(.import-additional-row) {
  background-color: rgba(0, 0, 0, 0.02) !important;
}

.chevron-down__blue {
  color: #337ab7;
}

.imports-table-wrapper {
  .voc-table_header-container {
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }

  .hideEmptyImportsLabel {
    margin: 0;
    display: flex;
    align-items: center;
  }

  .switch-wrap {
    display: flex;
    margin-right: 7px;
  }
}

.voc-tabs__list {
  display: block;
  padding: 0;
  margin: 0;
  list-style: none;
  font-size: 0;
  border-bottom: 1px solid var(--secondary);
}

.voc-tabs__list-item {
  display: inline-block;
  background: var(--white);
  color: var(--secondary);
  transition: background-color .1s linear;
  font-size: 14px;
  position: relative;
  padding: 8px 15px 7px 15px;

  &:hover {
    background-color: var(--light);
    color: var(--secondary);
    text-decoration: none;
  }

  &.active {
    border: 1px solid var(--secondary);
    border-bottom: none;
    color: var(--primary);
    font-weight: 600;

    &.disabled {
      pointer-events: none;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: -2px;
      left: 0;
      right: 0;
      height: 2px;
      background: var(--white);
    }

    &:hover {
      background-color: var(--white);
    }
  }
}

.dropdown {
  a {
    background: transparent;
  }
}

.voc-tabs__list-text {
  display: inline-block;
}

.voc-tabs__list-dropdown {
  display: inline-block;
  vertical-align: top;
  box-sizing: border-box;



  .dropdown-menu > .voc-tabs__list-item {
    font-weight: 400;
    color: var(--secondary);

    &:hover {
      background-color: var(--light);
      color: var(--secondary);
    }
  }


  .navigation__dropdown-btn {
    color: var(--secondary);
  }

  .navigation__dropdown-btn {
    background: var(--white);
    color: var(--secondary);
    transition: background-color .1s linear;
    font-size: 14px;
    position: relative;
    padding: 0 15px 0 15px;
    border-bottom: none;

    &:hover {
      background-color: var(--light);
      color: var(--secondary);

      .voc-tabs__list-item {
        background-color: var(--light);
      }
    }

    &:focus {
      box-shadow: none;
    }

    &.focus {
      box-shadow: none;
    }

    & > a {
      padding-top: 8px !important;
    }
  }

  &.active {
    border: 1px solid var(--secondary);
    border-bottom: none;

    .navigation__dropdown-btn {
      color: var(--primary);
      font-weight: 600;

      &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: -2px;
        left: 0;
        right: 0;
        height: 2px;
        background: var(--white);
      }
    }

    &:hover {
      background-color: var(--light);
    }
  }

  .dropdown-menu {
    width: 175px;
    padding: 0;
  }

  .voc-tabs__list-item {
    width: 100%;
    border: none;
  }
}


.voc-tabs__list-dropdown:not(.active) {
  a {
    padding-top: 11px !important;
  }
}

.pieChartWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  :global(.react-split__sash) {
    @media (max-width: 1069px) {
      display: none;
    }
  }
}

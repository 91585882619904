.checkbox-channel {
  display: inline-block;

  &:first-child {
    margin-right: 11px;
  }
}

.modal-customers__creation {
  color: #8e8e93;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}

.checkbox-block-all {
  display: inline-block;

  .voc-checkbox {
    width: 100%;
    height: 100%;
    margin: 0;

    &__label {
      margin: 0;
      display: block;
      text-align: left;
    }

    &__checkmark {
      width: 16px;
      height: 16px;
      border: 1px solid #979797;
      left: 180px;
      top: 1px;

      &:after {
        left: 5px;
        top: 1px;
        width: 5px;
        height: 10px;
        border: 1px solid #8e8e93;
        border-width: 0 2px 2px 0;
      }
    }

    input:checked {

      + .voc-checkbox__checkmark {
        background-color: #ff3b30;
        border: 1px solid #ff3b30;

        &:after {
          border: 1px solid white;
          border-width: 0 2px 2px 0;
        }
      }
    }
  }
}

.modal-customers-subscribe__row {
  margin-top: 12px;
}

.modal-customers-subscribe__title {
  font-size: 14px;
  font-weight: 700;
}

.customers-subscribe__list {

  .customers-subscribe__item {
    padding: 10px 0;
    margin: 0;
    border-top: 1px solid #dadada;

    .disabled {
      opacity: .5;
    }

    &:last-child {
      border-bottom: 1px solid #dadada;
    }
  }
}

.customers-subscribe__field {
  text-align: right;

  .checkbox-channel {
    vertical-align: middle;
  }
}

.add-subscribe__control {
  padding-top: 7px;
  margin-bottom: 26px;

  .btn {
    padding: .375rem .7rem;
  }
}

.add-subscribe__control {

  .add-subscribe__input {
    position: relative;

  }

  .add-subscribe__error {
    position: absolute;
    top: -22px;
    left: 15px;
    color: #ff3b30 !important;
    font-size: 12px;
    font-weight: 400;
  }

}

.title {
  font-weight: 500;
  font-size: 16px;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex: 1;

  & span {
    display: inline-block;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;
  }

  &.editable {
    &:hover {
      color: var(--blue);
    }
  }
}

.drillDownIconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 6px;
  min-width: 24px;
  width: 24px;
  height: auto;

  &.showTooltip img {
    pointer-events: visible;
  }
}

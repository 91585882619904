.pagination {
  display: flex;
  border-top: 1px solid #E5E5E5;
  flex-wrap: wrap;
  justify-content: center;

  .pageSelector {
    display: flex;
    margin: 7px;

    @media print {
      opacity: 0;
    }

    button {
      background: #f3f3f3;
      border: 1px solid #E5E5E5;
      border-radius: 4px;
      min-width: 33.5px;
      margin-right: 5px;

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        opacity: 0.7;
      }
    }

    span {
      padding: 0 5px;
    }
    .pageEnter {
      display: flex;
      align-items: center;

      input {
        max-width: 60px;
        border: 1px solid #E5E5E5;
      }

      span {
        flex-shrink: 0;
      }
    }
  }

  .rightPart {
    display: flex;
    justify-content: space-between;
    flex: 1;
    margin: 7px 0px;
    margin-left: 7px;

    div span {
      font-size: 12px;
    }
  }
  .pageSize {
    display: flex;
    align-items: center;

    @media print {
      opacity: 0;
    }

    span {
      white-space: nowrap;
    }

    :global(.dropdown) {
      margin-right: 5px;
      button {
        border-color: #E5E5E5;
      }
    }
  }

  .pageInfo {
    display: flex;
    align-items: center;
    margin-right: 10px;

    span {
      margin-top: 2px;
      white-space: nowrap;
    }
  }
}

.text-group-field__wrapper {
  //margin-top: 26px;
}

.text-group-control-group {

  .app-form-control,
  .text-group-control-group_button {
    display: inline-block;
  }

  .app-form-control {
    width: 80%;
  }

  .text-group-control-group_button {
    width: 15%;
    margin-right: auto;
    vertical-align: top;
    margin-left: 10px;
  }
}

.text-group-field__value-container {
  display: flex;
  max-height: 200px;
  overflow: hidden;
  overflow-y: auto;
  margin-top: 15px;
}

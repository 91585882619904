.row-wrapper__mine {
  margin-right: -15px;

  .graph-box {
    background: #F4F4F4;
    width: 100%;
    height: 100%;
    display: flex;
  }
}

.thin-spinner__query {
  width: 100%;
  height: 100%;
  z-index: 99;
  background-color: rgba(254, 254, 254, 0.7);

  &::before {
    border-color: #428bca;
  }
}

.querySplitPane {
  margin-left: -15px;
  width: auto !important;

  & .querySection {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 24px;
    padding-right: 15px;
    overflow: hidden;
  }

  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;
    height: unset !important;

    .react-split__pane {
      position: relative;
      width: 100% !important;
      height: auto;
      left: 0 !important;
      right: 0 !important;
    }

    .react-split__sash {
      display: none;
    }
  }
}

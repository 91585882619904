@import '../../../node_modules/bootstrap/scss/bootstrap.scss';
//todo write snipper about css menu
//todo rewrite this stuff
/*Table of concepts
- Carousel
- Popovers
- Badges
- Override Labels todo rewrite
*/

.table .progress {
  margin-bottom: 0;
}

.table {
  table-layout: fixed;
  word-break: break-all;
}

.table-full {
  width: 100%;
}

.table-bootstrap {
  table-layout: auto;
  word-break: normal;
}

.table thead > tr > th,
.table tbody > tr > th,
.table tfoot > tr > th,
.table thead > tr > td,
.table tbody > tr > td,
.table tfoot > tr > td {
  vertical-align: middle;
}

/* Carousel */

.carousel-control {
  opacity: 0.3;
}

/* Popovers */
.popover.fade {
  z-index: 999;
}

.popover.fade.in {
  z-index: 1030;
}

/* Badges */
.panel-default > .panel-heading .badge {
  background-color: #ffffff;
  color: #337ab7;
}
.panel-default > .panel-heading h5 {
  margin: 0;
}

/* Override Labels */

#column-types-input .control-label {
  margin-bottom: 5px;
  word-break: break-all;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

/* Inline Buttons */
.btn-exquisite {
  padding: 6px 10px;
}

html {
  a {
    color: #337ab7;
    text-decoration: none;
  }

  .dropdown-item.active, .dropdown-item:active {
    background: #337ab7;
  }

  .btn.btn-outline-primary {
    border-color: #337ab7;
    color: #337ab7;

    &:hover {
      color: #fff;
      background-color: #337ab7;
      border-color: #337ab7;
    }
  }

  .btn.btn-primary {
    background-color: #337ab7;
    border-color: #337ab7;
  }

  .page-item.active .page-link {
    background-color: #337ab7;
    border-color: #337ab7;
  }

  .page-item .page-link {
    color: #337ab7;
  }

  .badge-default {
    background-color: #777;
  }

  .badge-success {
    background-color: #5cb85c;
  }

  .badge-warning {
    background-color: #f0ad4e;
  }

  .badge-danger {
    background-color: #d9534f;
  }

  .badge-info {
    background-color: #5bc0de;
  }

  .btn-link {
    color: #337ab7;
  }

  .panel__header {
    display: flex;
    padding: 0;
    font-size: 14px;

    &-right-block {
      display: flex;
    }

    .panel__header-title {
      padding: 10px 15px 7px 15px;
      font-weight: 700;
      max-width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &.panel__header--buttons {
      display: flex;
      justify-content: space-between;

      .btn {
        border-radius: 0;
        position: relative;

        &:focus {
          z-index: 1;
        }
      }

      .panel__header-button.btn-outline-white {
        min-width: 180px;
        border: none;
        border-left: 1px solid #dddddd;
        height: 100%;
      }
    }
  }

  .btn-group > .btn:focus {
    z-index: 1;
  }

  .btn-outline-white {
    background: #fff;
    border: 1px solid #dddddd;
    position: relative;

    &:focus {
      z-index: 1;
    }

    &:hover {
      color: #333;
      background-color: #e6e6e6;
    }
  }
}


@media (min-width: 992px) {
  html {
    .modal-lg {
      max-width: 900px;
    }

    .modal-md {
      max-width: 600px;
    }
  }
}

.dropdown-toggle {
  padding-right: 25px;

  &::after {
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -3px;
  }
}

.tooltip {
  z-index: 100000;
}

.table-hover tbody tr:hover {
  background-color: rgba(0,0,0,.055);
}

.title {
  font-weight: 500;
  font-size: 18px;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;

  @media print {
    width: calc(100% - 150px);
  }

  & span {
    display: inline-block;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0;

  @media print {
    padding: 0 0 0 10px;
  }

  .headerActionBar {
    display: flex;
    flex-shrink: 0;
    margin-left: 12px;

    @media print {
      display: none;
    }

    .createButton {
      @media (max-width: 1069px) {
        display: none;
      }
    }

    .actionButton {
      margin-left: 10px;

      @media (max-width: 500px) {
        span {
          display: none;
        }
      }
    }
  }
}

.stuckControls {
  border-style: solid;
  border-color: #E5E5E5;
  border-width: 0 1px 1px 1px;
  border-radius: 0 0 5px 5px;
  padding: 0 24px;
  z-index: 2 !important;
}

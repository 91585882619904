.voc-checkbox {
  position: relative;
  margin-bottom: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  border-radius: 4px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  &__checkmark {
    position: absolute;
    top: 0;
    left: 0;
  }

  input:checked ~ &__checkmark:after {
    display: block;
  }

  &__checkmark:after {
    content: "";
    position: absolute;
    display: none;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  &_disabled {
    background-color: #e9ecef;
    pointer-events: none;
  }
}

.app-form-control__container .voc-checkbox {
  margin-top: 5px;
}

.defaultTheme {

  .voc-checkbox {
    vertical-align: middle;
    height: 25px;
    width: 25px;
    line-height: 1.42857143;

    &__checkmark {
      width: 100%;
      height: 100%;
      border: 1px solid #cdd4da;
      border-radius: .25rem;
    }

    &__checkmark:after {
      left: 9px;
      top: 5px;
      width: 5px;
      height: 10px;
      border: 1px solid black;
      border-width: 0 3px 3px 0;
    }

    input:checked ~ &__checkmark {
      background-color: white;
    }

    &__label {
      display: inline-block;
      margin-left: 40px;
      margin-top: 4px;
      font-weight: 700;
    }
  }

  &.radio-button {
    display: inline-block;
    width: 65px;

    input:checked ~ .voc-checkbox__checkmark:after {
      display: block;
    }

    .voc-checkbox {
      width: 18px;
      height: 18px;
      margin-top: 15px;
    }

    .voc-checkbox__label {
      display: inline-block;
      margin-left: 23px;
      margin-top: 0;
      font-weight: 500;
      line-height: 1.1;
    }

    .voc-checkbox__checkmark {
      border-radius: 50%;
      border-color: #53565A;
    }

    .voc-checkbox__checkmark:after {
      content: "";
      position: absolute;
      display: none;
      transform: none;
      left: 5px;
      top: 5px;
      width: 6px;
      height: 6px;
      border: none;
      background: #53565A;
      border-radius: 50%;
    }
  }
}

.checkboxChannelTheme {
  width: 64px;
  height: 16px;

  .voc-checkbox {
    width: 100%;
    height: 100%;
    margin: 0;
    vertical-align: top;
    line-height: initial;

    &__checkmark {
      width: 16px;
      height: 16px;
      border-radius: 2px 0 0 2px;
      border-right: 1px solid #8e8e93;

    }

    //&__checkmark:after {
    //  left: 6px;
    //  top: 2px;
    //  width: 5px;
    //  height: 10px;
    //  border: 1px solid #8e8e93;
    //  border-width: 0 2px 2px 0;
    //  display: block;
    //}

    &__label {
      width: 100%;
      height: 100%;
      padding-left: 15px;
      margin: 0;
      font-size: 12px;
      font-weight: 400;
      text-transform: uppercase;
      vertical-align: top;
      border: 1px solid #8e8e93;
      display: block;
      border-radius: 2px;
    }

    &__activate {
      width: 100%;
      height: 100%;
      text-align: center;
      margin: 0;
      font-size: 12px;
      font-weight: 400;
      text-transform: uppercase;
      vertical-align: top;
      border: 1px solid #8e8e93;
      display: block;
      border-radius: 2px;
      line-height: initial;
      background: #dfdfdf;
      cursor: pointer;

      &:hover {
        background: #f2f2f2;
      }

      &:active {
        border: 1px solid #b5b5ba;
        background: #f9f9f9;
      }
    }

    input:checked {

      + .voc-checkbox__checkmark {
        background-color: #ff3b30;
        border-right: 0;

        &:after {
          left: 6px;
          top: 2px;
          width: 5px;
          height: 10px;
          display: block;

          border: 1px solid white;
          border-width: 0 2px 2px 0;
        }
      }

      ~ .voc-checkbox__label {
        border: 1px solid #ff3b30;
      }
    }
  }
}

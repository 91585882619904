.query-details-chart {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  width: 100%;

  .empty-data {
    padding-bottom: 168px;
  }

  &-header {
    display: flex;
    margin-bottom: 16px;
    font-size: 14px;
    line-height: 24px;
    color: #A0A0A2;

    &__right {
      padding: 0 8px;
      width: 165px;
      margin-left: auto;
      margin-right: 0;
    }
  }

  &-list {
    width: 100%;
  }

  .voc-table__footer {
    margin-top: auto;
  }
}

.upload-list__switch-group {
  padding-left: 15px;
}

.upload-list-panel table th:last-of-type {
  text-align: right !important;
}

.uploads-list {
  .table-action__btn-share {
    margin-left: 0;
    margin-right: 4px;
  }

  .table-action-btn-wrapper:not(:last-child) {
    margin-right: 4px;
  }
}

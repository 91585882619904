.settings {

  &-spacing {
    margin-bottom: 20px;
  }

  &__wrapper {
    clear: both;
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;

    .settings__panel.card {
      margin: 0 20px 0 0;
    }
  }

  &__input {
    display: inline-block;
    width: 40%;
    vertical-align: middle;
  }

  &__input-label {
    width: 60%;
    display: inline-block;
    vertical-align: middle;
  }

  &__button {
    float: right;
    width: 115px;
    height: 34px;
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 1.42857143;
  }

  &__panel {
    width: 32%;
  }

  &__label {
    display: block;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__label-text {
    display: inline-block;
    vertical-align: middle;
    width: 75%;
    margin-right: 15px;
  }
}

.component-option-label_container {
  & > div  {
    display: flex !important;
    width: 100% !important;
    justify-content: space-between !important;
  }
}

.component-option_label {
  display: inline-block;
  float: left;
  width: 100%;
  overflow: hidden;

  div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.component_option_badge {
  justify-content: flex-end;

  & > span {
    font-size: 70%;
  }
}

.combined-condition__item {
  color: #3a7db2;
  word-break: break-word;
}

.condition__operation {
  text-transform: uppercase;
}

.condition__red {
  color: #ff3b30;
  word-break: break-word;
}

.combined-condition__padding {
  padding: 0 4px;
  display: inline-block;
  margin: 0;
}

.flow__list-accent {
  margin-right: 5px;
  word-break: break-all;
}

.flow__list-item {
  z-index: 9999;
}

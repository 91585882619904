.chartWrapper {
  display: flex;
  flex-direction: column;
  height: inherit;
  position: relative;
  background-color: white;
  border-radius: inherit;

  :global(.transparent) {
    opacity: 0.5;
  }

  :global(rect) {
    transition: opacity 0.3s;
  }

  :global(.react-split__sash) {
    @media (max-width: 1069px) {
      display: none;
    }
  }
}

.graphSplitPanePadding {
  padding-bottom: 15px;
  display: flex;

  @media (max-width: 1069px) {
    position: relative !important;
    height: initial !important;
    min-height: 65%;
    padding-bottom: 0;
  }
}

.labelsSplitPanePadding {
  @media (max-width: 1069px) {
    position: relative !important;
    height: 72px !important;
    top: initial !important;
  }
}

.sketchPicker {
  box-shadow: none !important;
  padding: 4px 0 !important;
}

.labelsContainer {
  display: flex;
  justify-content: space-between;
  align-items: start;
  height: 100%;
  margin-top: 5px;
  padding: 12px 18px;
  overflow: hidden;

  & > button {
    pointer-events: all;
    background: none;
    border: none;
    transition: .3s;
    opacity: .7;
    padding: 0;
    height: 36px;
    width: 36px;

    @media print {
      display: none;
    }

    &:hover {
      opacity: 1;
      transition: .3s;
    }
  }
}

.filter-card__wrapper {
  border-radius: 8px;
  background-color: #f5f5f5;
  padding: 13px 20px 30px 20px;

  .filter-card__header {
    color: #000000;
    font-size: 16px;
    font-weight: bold;
    border-bottom: 1px solid #dddddd;
    padding-bottom: 2px;
  }

  .filter-card__body {
    padding: 20px 0;

    .select-control-label label {
      font-weight: 400;
    }
  }

  .filter-card__footer {
    display: flex;
    justify-content: flex-end;

    .filter-card__footer-button {
      background-color: #327ab7;
      min-width: 130px;
      text-align: center;
      position: relative;
      color: #ffffff;
      font-size: 16px;
      img {
        position: absolute;
        width: 12px;
        top: 12px;
        left: 24px;
      }
    }
  }
}

.filterButtons {
  display: flex;
  height: 100%;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: start;
  align-content: flex-start;
  overflow: auto;

  & .filterButtonWrapper {
    width: fit-content;
  }
}

:global(.filterLabelButton) {
  margin: 2px;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 1px 5px;
  background-color: #b7d7ed;
  color: white;
  border-radius: 4px !important;
  height: fit-content;

  span {
    display: block;
    font-size: 12px;
    word-break: break-all;
  }
}

:global(.trimLabel) {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 110px;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

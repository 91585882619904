.quote {
  &--bottom {
    position: fixed;
    bottom: 10%;
    background: rgba(199,199,204,0.70);
    width: 100vw;
  }

  &__container {
    margin: 0;
    padding: 10px 20px;
    font-size: 18px;
    border-left: 5px solid #eee;
  }

  &__text {
    color: #ffffff;
    text-shadow: 0 2px 4px rgba(0,0,0,0.50);
  }

  &__author {
    color: #ffffff;
    text-shadow: 0 2px 4px rgba(0,0,0,0.50);
    font-size: 80%;

    &:before {
      content: "\2014 \A0";
    }
  }
}
.delete-content {
  display: flex;
  align-items: center;
}

.appliance-segment-header__button {
  width: 37px;
  height: 33px;
}

.appliance-attribute-select {
  margin: 0;

  .vochub-select-control__control {
    width: 440px;
  }

  &.appliance-attribute-select--invalid {
    .vochub-select-control__control {
      border: 1px solid #dc3545;
    }
  }
}

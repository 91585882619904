.embed-snippet__container {
  display: flex;
  justify-content: space-between;
}

.embed-snippet__value {
  border-radius: 4px;
  border: 1px solid var(--gray);
  position: relative;
  min-height: 33px;
  line-height: 33px;
  padding: 0 16px;
  box-sizing: border-box;
  width: 88%;
  font-weight: 600;
}

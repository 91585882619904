.preloader {
  display: flex;
  flex: 1;
  align-items: center;
}

.topicsBlock {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: inherit;
  margin-left: -10px;

  @media (max-width: 992px) {
    padding-right: 0px;
  }

  .treshholdBlock {
    padding-left: 10px;
    padding-right: 14px;
  }

  .thresholdSliderWrapper {
    display: flex;

    span {
      margin-left: 10px;
      display: flex;
      justify-content: center;
      width: 25px;
      margin-top: -1px;
    }
  }

  .topicsButtons {
    margin-top: 25px;
    margin-bottom: 20px;
    display: flex;
    justify-content: end;
    padding-left: 10px;
    padding-right: 14px;

    button {
      margin-left: 10px;
    }
  }
}

.graphIcons {
  margin-bottom: 20px;

  svg {
    width: calc((100% / 8) - 10px);
    height: auto;
    margin-right: 10px;
    cursor: pointer;
    transition: all .5s;

    &:hover {
      opacity: .6;
    }

    @media (max-width: 992px) {
      width: 50px;
    }

    &.active {
      path {
        fill: #28a745;
      }

      &:hover {
        opacity: 1;
      }
    }
  }
}

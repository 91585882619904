.voc-table__body {
  .dictionary-lemmata {
    &.card {
      background: inherit;
    }

    .lemmata-formatter {
      word-break: break-word;
      margin-bottom: 0;
    }

    .card-header {
      padding: 0;
      background: inherit;

      &--no-events {
        pointer-events: none;

        h5 img {
          display: none;
        }
      }

      .collapse__header {
        display: flex;
        max-width: calc(100% - 22px);
        height: 21px;
        position: unset;

        &.open {
          .collapse__header-title:before {
            content: '...';
            font-weight: 400;
          }

          .collapse__header-title-text {
            display: none;
          }
        }

        img {
          right: 0;
        }

        .collapse__header-title {
          overflow: unset;

          &--overflow {
            overflow: hidden;
          }
        }

        .collapse__header-title-text {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          display: block;
          width: inherit;
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.5;
        }

        img {
          top: 8px;
        }
      }
    }

    .card-body {
      padding: 0;
    }
  }
}

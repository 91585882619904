.general-section__input {
  height: 36px;
}

.general-section__label {
  margin: 0;
  line-height: 16px;

  .star {
    color: red;
  }
}

.general-section__field_container {
  padding-bottom: 10px;
}

.textarea-size {
  position: relative;

  & > textarea {
    min-height: 105px;
    max-height: 405px;
  }
}

.question__id--header {
  text-align: right;
}

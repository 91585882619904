.settingsButtons {
  display: flex;
  margin-top: 10px;
  margin-bottom: 20px;
  justify-content: end;
  flex-wrap: wrap;

  button {
    margin-left: 10px;
    margin-bottom: 10px;
  }
}

.sourceFieldWrapper {
  margin-bottom: 15px;
}

.selectOption {
  :global(.Select-options--sources) {
    max-width: 100% !important;
  }
}

.select-async__value {
  width: calc(100% - 80px) !important;

  .Select-value__labels {
    width: 100px !important;
    white-space: nowrap;
  }

  .Select-value__title > div {
    padding: 2px 8px !important;
    max-width: 360px !important;
    width: 360px !important;
  }

  .Select-value__title_multi {
    padding-left: 0 !important;
  }

  .Select-value__title_multi > div {
    max-width: 100% !important;
    width: 100% !important;
  }

  .Select-value__labels .badge {
    padding: 0.29em .6em .3em;
  }
}

.Select-value_multi {
  width: calc(100% - 60px) !important;
}

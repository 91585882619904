.queryFooter {
  display: flex;
  justify-content: flex-end;
  padding: 18px 0;
  position: relative;
  border-top: 1px solid #E5E5E5;

  & .runButton {
    padding-right: 38px;
    padding-left: 38px;

    & > svg {
      margin-right: 6px;
    }
  }

  :global(.queryFooterDropdown__wrapper) {
    margin: 0 16px;
  }

  :global(.queryFooterDropdown__toggle) {
    background: transparent;
    border: none;
    color: var(--primary);
    box-shadow: none;
    transition: all .5s;

    &:active,
    &:visited,
    &:focus,
    &:hover,
    &:disabled {
      background: transparent !important;
      border: none !important;
      color: var(--primary) !important;
      opacity: .8 !important;
      box-shadow: none !important;
    }

    &:disabled {
      color: var(--secondary) !important;
    }
  }
}

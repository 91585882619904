.chromeSearchBar {
  display: none;
}

.device {
  position: relative;
  transform: scale(1);
  z-index: 1;

  .deviceScreen {
    border: 1px solid;
    background-size: cover;
    object-fit: cover;
    position: relative;
    overflow: hidden;
    background: white;
  }

  &.iphoneX {
    height: 868px;
    width: 428px;

    .deviceStripe {

      &:before, &:after {
        border: solid rgba(1,1,1,.25);
        border-width: 0 7px;
        content: '';
        height: 7px;
        left: 0;
        position: absolute;
        width: 100%;
        z-index: 9;
      }

      &:before {
        bottom: 85px;
      }

      &:after {
        top: 85px;
      }
    }

    .deviceFrame {
      background: #222;
      border-radius: 68px;
      box-shadow: inset 0 0 2px 2px #c8cacb, inset 0 0 0 7px #e2e3e4;
      height: 868px;
      padding: 28px;
      width: 428px;

      .deviceScreen {
        border-radius: 40px;
        height: 812px;
        width: 375px;
        padding-top: 90px;
        position: relative;

        .chromeSearchBar {
          display: block;
          height: 90px;
          width: 100%;
          background: #33363B;
          position: absolute;
          top: 0;
          padding: 0px 10px;

          .searchBar {
            background: #5F6267;
            margin-top: 44px;
            height: 35px;
            border-radius: 30px;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
          }
        }
      }
    }

    .deviceHeader {
      background: #222;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      height: 30px;
      left: 50%;
      margin-left: -102px;
      position: absolute;
      top: 28px;
      width: 204px;

      &:before, &:after {
        content: '';
        height: 10px;
        position: absolute;
        top: 0;
        width: 10px;
      }

      &:before {
        background: radial-gradient(circle at bottom right,transparent 0,transparent 75%,#222 75%,#222 100%);
        right: -10px;
      }

      &:after {
        background: radial-gradient(circle at bottom left,transparent 0,transparent 75%,#222 75%,#222 100%);
        left: -10px;
      }
    }

    .deviceBtns {
      background: #c8cacb;
      height: 32px;
      left: -3px;
      position: absolute;
      top: 115px;
      width: 3px;

      &:before, &:after {
        background: #c8cacb;
        content: '';
        height: 62px;
        left: 0;
        position: absolute;
        width: 3px;
      }

      &:before {
        top: 140px;
      }

      &:after {
        top: 60px;
      }
    }

    .devicePower {
      background: #c8cacb;
      height: 100px;
      position: absolute;
      right: -3px;
      top: 200px;
      width: 3px;
    }

    .deviceSensors {

      &:before, &:after {
        content: '';
        position: absolute;
      }

      &:before {
        background: radial-gradient(farthest-corner at 20% 20%,#6074bf 0,transparent 40%),radial-gradient(farthest-corner at 80% 80%,#513785 0,#24555e 20%,transparent 50%);
        border-radius: 50%;
        box-shadow: 0 0 1px 1px rgb(255 255 255 / 5%);
        height: 10px;
        left: 50%;
        margin-left: 42px;
        top: 30px;
        width: 10px;
      }

      &:after {
        background: #333;
        border-radius: 3px;
        height: 6px;
        left: 50%;
        margin-left: -25px;
        top: 32px;
        width: 50px;
      }
    }
  }

  &.ipadPro2017 {

    .deviceFrame {
      background: #121515;
      border-radius: 38px;
      box-shadow: inset 0 0 0 2px #3A4245, inset 0 0 0 6px #3A4245;
      height: 1112px;
      padding: 95px 36px 97px 36px;
      width: 834px;
      box-sizing: content-box;

      .deviceScreen {
        border: 2px solid #3A4245;
        border-radius: 2px;
        height: 1112px;
        width: 834px;
        background: white;
      }
    }

    .deviceHome {
      border: 2px solid #3A4245;
      border-radius: 50%;
      bottom: 20px;
      height: 59px;
      left: 50%;
      margin-left: -17px;
      position: absolute;
      width: 59px;
    }

    .deviceSensors {
      background: #3A4245;
      border-radius: 50%;
      height: 13px;
      left: 50%;
      margin-left: -5px;
      margin-top: -5px;
      position: absolute;
      top: 42px;
      width: 13px;
    }
  }
}

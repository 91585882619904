.filter-advanced-modal {

  .modal-dialog {
    width: 1075px;
    max-width: 90%;
    min-width: 400px;
  }

  .modal-content {
    position: relative;
  }

  .modal-body {
    height: 70vh;
    min-height: 400px;
    position: static;
  }
}

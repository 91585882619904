.editor__file-manager .modal-body {
  padding: 50px 15px;
}

.editor__file-manager .modal-footer {
  padding: 0;
}

.editor__file-manager .modal-header {
  position: relative;
}

.file-manager__list {
  padding: 0;
  margin: 0;
  list-style: none;
  overflow: hidden;
}

.file-manager__nav {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -45px;
  z-index: 2;
  border: 1px solid #e7e7e7;
  padding: 5px 0;
  background-color: #f8f8f8;

  .row {
    margin: 0;
  }
}

.file-manager__total {
  margin: 20px 0;
  width: 100%;
}

.file-manager__input {
  visibility: hidden;
  position: absolute;
}

.editor__file-manager {
  .breadcrumbs {
    line-height: 28px;
  }

  .breadcrumbs__list {
    list-style: none;
    margin: 0;
    padding: 2px 0 0 10px;
    font-size: 0;
    background: #fff;
    box-shadow: inset 1px 1px 2px rgba(0,0,0,0.2);
    border-radius: 4px;
    height: 33px;

    &:focus {
      box-shadow: inset 1px 1px 2px rgba(0,0,0,0.2);
    }
  }

  .breadcrumbs__list-item {
    display: inline-block;
    font-size: 14px;

    &:first-child {
      color: var(--primary);
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    &:not(:first-child)::before {
      padding: 0 5px 0 10px;
      color: #ccc;
      content: "/\00a0";
    }
  }
}

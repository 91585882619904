.checkbox {
  margin-left: 5px;
}

.textarea {
  resize: none;
}

.label {
  margin-top: 20px;
}

.inputGroup {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.manage__exporter-label {
  margin-bottom: 0;
}

.manage__exporter-label-container {
  text-align: right;
}

.exporters-list__modal {
  &.voc-dialog .modal-body {
    max-height: calc(100vh - 285px);
  }
}

.preloader__wrapper--export {
  z-index: 10;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
}

.flows-quarantines__modal-body {

  .flow-quarantine-number_field {
    .voc-number-input__button {
      height: 37px;
      width: 37px;
    }
  }

  .flows-quarantines__row {
    margin-bottom: 20px;

    label {
      margin-top: 8px;
    }
  }

  .app-form-control__container {
    position: relative;

    &.with-error {
      height: auto;

      .app-form-control__error {
        bottom: -15px;
      }
    }

    .form-text {
      margin: 0;
      position: absolute;
      bottom: -15px;
      left: 0;
    }
  }

  .vochub-select-control {
    margin-bottom: 0;
  }

  .condition__empty {
    height: 38px;
    margin-bottom: 20px;

    text-align: center;
    padding-top: 15px;
  }

  .button-primary__text {
    color: #000000;
    padding-left: 10px;
  }

  .btn-outline-primary {
    &:hover {

      .button-primary__text {
        color: #fff;
      }
    }
  }

  .conditions__header {
    color: #53565a;
    font-size: 14px;
    font-weight: 500;
    padding: 15px 0 0 0;
    border-top: 1px solid #dadada;

    .row {
      .col-md-4:nth-child(1) {
        flex: 0 0 31%;
        max-width: 31%;
      }

      .col-md-4:nth-child(2) {
        flex: 0 0 30%;
        max-width: 30%;
      }
    }
  }

  .conditions__box {
    max-height: 290px;
    overflow-x: hidden;
  }

  .condition-row__box {
    padding: 10px 0 16px;
  }

  .condition-flow__icon {
    text-align: center;
    display: block;
    padding: 7px 10px 5px 0;
    cursor: pointer;

    svg {
      color: #979797;
    }
  }

  .next-question__margin {
    margin: 15px 0 0;
  }

  .logical-operation__box {
    padding-top: 5px;

    .switch-wrap {
      height: initial;
      margin: 0 10px;
    }

    label {
      margin: 0;
    }
  }

  .conditions__contr {
    margin-bottom: 20px;
  }


  /*==============================*/

  .form-control {
    height: calc(2.5rem + 2px);
  }

  .flows-quarantines-fields {
    margin-bottom: 20px;

    .col-xs-2 {
      padding-right: 0;
      padding-left: 0;
    }
  }

  .flows-quarantines__combined-condition {
    margin: 0;
    font-weight: bold;
    padding-top: 20px;
  }

  .question-flow-required {

    position: relative;

    &::before {
      content: '*';
      position: absolute;
      color: red;
      top: -1px;
      left: -8px;
    }

    .select-control-label {
      position: absolute;
    }
  }

  .question-flow-comparisons {
    margin-top: 5px;
  }
}

.question-flow_footer-buttons {
  .svg-inline--fa {
    margin-right: 5px;
  }
}


.target-question__wrap {
  position: relative;

  &:before {
    content: 'if';
    display: block;
    position: absolute;
    right: -20px;
    top: 7px;
    font-size: 16px;
  }
}

.add-condition__wrap {
  display: flex;
  justify-content: flex-end;

  button {
    flex: 0 1 auto;
    align-self: flex-end;
  }
}

.surveys-list-sticky-header {

  position: fixed;
  width: 1108px;
  z-index: 100;
  background-color: white;
  top: 52px;
  border-bottom: 1px solid rgb(212, 212, 212);
}

.surveys-list__sort-icon {
  font-size: 18px;
  margin-left: 5px;
  vertical-align: middle;
}

.wordCloudChartWrapper {
  overflow: hidden;

  &.rangeVisible {
    border-radius: 0;
  }

  .lemmasExcludeCard {
    :global(.collapse__header) {
      max-width: calc(100% - 55px);
    }

    :global(.card-header) {
      background: none;
      padding: 5px 10px;

      :global(.collapse__header-title-text) {
        font-weight: normal;
      }
    }

    :global(.collapse), :global(.collapsing) {
      padding: 0px 8px;
    }

    :global(.card-body) {
      display: flex;
      overflow: auto;
      flex-wrap: wrap;
      max-height: 66px;
      padding: 2.5px;
      border: 1px solid #dddddd;
      border-radius: 4px;
      min-height: 38px;

      .lemmasExcludeItem {
        white-space: nowrap;
        padding: 2px 6px;
        margin: 2px;
        background-color: rgb(51, 122, 183);
        border-radius: 4px;
        padding-right: 20px;
        position: relative;
        height: 27px;

        span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 15px;
          color: white;
        }

        .closeButton {
          padding: 0;
          margin: 0;
          border: none;
          background: none;
          position: absolute;
          right: 4px;
          top: 4px;
          font-weight: 600;
          transform: rotate(45deg);
          line-height: 100%;
          opacity: 0.5;
          font-size: 17px;
          color: white;

          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }
}

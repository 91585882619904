.statistic__answer-item {
  height: 65px;
  border-bottom: 1px solid #ddd;
  padding: 14px 0;
}

.statistic__answer-title {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  align-self: center;

  span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    display: block;
  }
}

.statistic__answer-progress {
  padding-left: 0;
  text-align: right;
}

.statistic__progress-item {
  height: 16px;
  margin-top: 2px;

  .progress-bar {
    background-color: #327ab7;
  }
}

.questions-statistic__item {
  .collapse__header-title-text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.statistic-answer__responses,
.statistic-answer__ratio {
  font-size: 12px;
}

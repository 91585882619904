.tableChartWrapper {
  margin-bottom: 20px;

  .tableChartLabel {
    margin: 0;
    margin-bottom: 10px;
    font-size: 20px;
  }

  table {
    border: 1px solid #d4d4d4;
    border-left: none;
    border-right: none;
    width: 100%;

    th {
      padding: 5px 10px;
      background: #e8e8e8;
      border-bottom: 1px solid #d4d4d4;
      border-right: 1px solid #d4d4d4;

      &:last-child {
        border-right: none
      }

      &.sortTh {
        text-align: center;
        display: flex;
        white-space: nowrap;
        align-items: center;
        cursor: pointer;
        justify-content: center;
      }
    }

    tr:nth-child(even) {
      background: #e8e8e8;
    }

    td {
      padding: 5px 10px;
      border-right: 1px solid #d4d4d4;

      &:first-child {
        width: 80%;
      }

      &:last-child {
        border: none;
      }
    }
  }

  .sortControls {
    margin-left: 5px;
  }
}

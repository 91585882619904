.spinner {

  fill: none;
  max-width: 100px;
  max-height: 100px;

  &__wrapper {
    z-index: 10;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0.7;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.spinner__grey {
  stroke: #53565A;

  &__wrapper {
    background-color: #FEFEFE;
  }
}
